.section.sect {
    color: white;
    background-color: #333;
    padding: 120px 60px;
}

.instruction11 {
    font-size: 39px;
}

.instruction2.inst {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: unset;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.seach_area input {
    font-size: 24px;
    height: 48px;
    text-align: center;
}

.seach_area .btn {
    font-size: 24px;
}