/*******************************************************************************
 price table
*******************************************************************************/
#underlying .uppertbl {
    width: 100%;
    height: 100%;
    /*    table-layout: fixed;*/
    margin-bottom: 20px;
}

.css-1pahdxg-control {
    border-color: #101010 !important;
    box-shadow: 0 0 0 1px #101010 !important;
}

/* .css-1pahdxg-control:focus {
    border-color: #101010 !important;
    box-shadow: 0 0 0 1px #101010 !important;
} */

#underlying .uppertbl-col-bid {
    vertical-align: middle;
    width: 30%;
}

#underlying .highcharts-tooltip-box {
    background: #fff;
    opacity: .8;
}

#underlying .uppertbl-col-chglbl {
    width: 25%;
}

#underlying .uppertbl-col-chgval {
    text-align: right;
    width: 25%;
}

/*#underlying .uppertbl td{
    white-space: nowrap;
}*/
#underlying .uppertbl-col-pchgval,
#underlying .uppertbl-col-trdvol,
#underlying .uppertbl-col-bidask,
#underlying .uppertbl-col-bidaskvol {
    text-align: right
}

#underlying .uppertbl td {
    padding: 8px 15px;

}

#underlying .bidprice.val {
    font-size: 40px;
    line-height: 40px;
}

#underlying #periodtab {
    margin-bottom: 20px;
}

#underlying #chartbox {
    margin-bottom: 35px;
}

#underlying .ctab {
    width: 100%;
}

#underlying .ctab-item {
    text-align: center;
    width: 25%;
    border: 2px solid #FECC0A !important;
    height: 33px;
    cursor: pointer;
}

#underlying .ctab-item.active {
    background-color: #FECC0A;
}

#underlying .ctab-item-space {
    width: 12px;
}

#underlying .ta_panel {
    margin-top: 20px;
}

/* #underlying .ta_panel .row{
    margin-bottom: 10px;
} */
#dwtable tbody>tr:nth-of-type(even) {
    background-color: #fff;
}

#underlying .MAtab .ctab-item {
    width: 20%;
}

#underlying .typetab .ctab-item {
    width: 34%;
}

#underlying .txtboxs {
    width: 100%;
    text-align: center;
}

#underlying .txtcheckbox {
    width: 100%;
}

#underlying .txtcheckbox .checkbox {
    position: absolute;
    right: 12px;
    top: 16px;
    margin-top: -8px;
}


#underlying .txttbl_lbl {
    white-space: nowrap;
}

#underlying .txttbl_input {
    padding-left: 10px;
    padding-right: 10px;
}

#underlying .txttbl_input.last {
    padding-right: 0px
}

/*******************************************************************************
 underlying chart box
*******************************************************************************/
.ctab-item.active {
    color: #000;
}

.ctab {
    margin-bottom: 10px !important;
}

#chartbox .chart {
    height: 100%;
    position: relative;
}

#underlying .nochart {
    width: 100%;
    height: 100%;
    border: 1px solid #CCC;
    position: relative;
}

#underlying .nochart-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

/*******************************************************************************
 disclaimer
*******************************************************************************/
#underlying #underlying-disclaimer table {
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}

#underlying #underlying-disclaimer table tr>td:first-child {
    white-space: nowrap;
}

#underlying #defination {
    margin-top: 10px;
}

#underlying #defination .panel-heading {
    background-color: #666666;
    padding: 4px 10px;
}

#underlying #defination .panel-title {
    font-size: 14px;
}

#underlying #defination .glyphicon {
    color: white;
}

#underlying #defination #collapse_dwcompare3 {
    font-size: 12px;
    padding: 10px;
}

#underlying #defination .table {
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}

#underlying #defination .table td {
    padding-left: 0;
}

#underlying #defination .table tr td:first-child {
    white-space: nowrap;
}

/*******************************************************************************
 Related warrants table
*******************************************************************************/
#underlying #dwtable .table th {
    background-color: #DFDBCF;
}

#underlying #dwtable .table th,
#underlying #dwtable .table td {
    text-align: center;
    padding: 6px;
}

#underlying #dwtable .table .htxt th {
    vertical-align: middle;
}

#underlying #dwtable .table .sort th {
    background-color: #FFFFFF;
    padding-top: 3px;
    padding-bottom: 3px;
}

#underlying #dwtable .icon-sort {
    font-size: 13px;
    cursor: pointer;
}

#underlying #dwtable .icon-sort.active,
#underlying #dwtable .icon-sort-dspyonly.active {
    color: #FECC0A;
}

#underlying #dwtable .htxt th.active {
    color: #0675C2;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 767px) {
    #underlying h3 {
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    #underlying .uppertbl {
        margin-top: 0;
    }

    #underlying .uppertbl td {
        padding: 4px 10px;
    }
    #ticker{
        line-height: 30px;
    }
    #underlying .uppertbl-col-bid {
        border-right: 0;
        width: 40%;
    }

    #underlying .uppertbl-col-bid .lbl,
    .uppertbl-col-chglbl,
    .uppertbl-col-pchglbl,
    .uppertbl-col-bidasklbl,
    .uppertbl-col-bidaskvollbl {
        font-size: 12px;
    }

    #underlying .uppertbl-col-bid .val {
        font-size: 14px;
        line-height: normal;
    }

    #underlying .uppertbl-col-chglbl {
        border-bottom: 0;
        width: 35%;
    }

    #underlying .uppertbl-col-chgval {
        border-bottom: 0;
        width: 25%;
    }

    #underlying #underlying-disclaimer table {
        font-size: 11px;
    }

    #underlying p.small {
        font-size: 11px;
    }

    #underlying #defination #collapse_dwcompare3 {
        font-size: 11px;
    }

    #underlying .txtcheckbox .checkbox {
        top: 14px;
        margin-top: -7px;
    }

    #underlying #dwtable .table th,
    #underlying #dwtable .table td {
        font-size: 11px;
        padding-left: 2px;
        padding-right: 2px;
    }
}

@media screen and (max-width: 767px) and (orientation:portrait) {

    #underlying #dwtable .table th,
    #underlying #dwtable .table td {
        font-size: 10px;
    }
    #underlying #dwtable .icon-sort{
        font-size: 10px !important;
    }
}

@media screen and (max-width: 991px) {
    #underlying #dwtable .table th {
        background: inherit;
    }
}