#photo-list .photo-holder {
    height: 180px;
}
.photo-holder {
    background: url(../../../../public/img/bullet/loading.gif) center center no-repeat;
    background-color: #000;
}
#photo-list .holder-center img {
    max-width: 100%;
    max-height: 100%;
    flex: 0 0 auto;
    object-fit: scale-down;
    cursor: pointer;
}
#photo-list .holder-center .inner_area_con{
    width: 90%;
    margin: 0 auto;
}
#photo-list .holder-center .inner-area .image {
    width: 100%;
    max-width: 100% !important;
}
.inner_area_btn{
    display: none;
}
@media screen and (min-width: 992px) and (max-height: 700px){
    .inner_area_con .btn-close,
    .inner_area_con .image{
        max-height: 400px !important;
    }
}
@media screen and (max-width: 767px) {
    .inner_area_btn{
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        margin:40px auto 0 auto;
        width: 80%;
        min-height: 30px;
    }
    .inner_area_btn span{
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
