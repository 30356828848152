.section  .seminar_area img{
    width: 100%;
}

.btn-area{
    text-align: left;
}
#bookBtn{
    display: inline-block;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 10px 0 10px 10px;
    cursor: pointer;
}
#shareBtn{
    font-style: italic;
    display: inline-block;
    width: 200px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 10px 0 10px 10px;
    cursor: pointer;
    color: white;
    background-color: #666;
}

#shareBtn>i{
    margin-right: 20px;
}
.more_seminars.row{
    margin-bottom: 30px;
}
.more_seminars .col-sm-4,
.more_seminars .col-xs-12{
    padding-top: 10px;
}
.more_seminars img{
    cursor: pointer;
}

#book_area{
    margin: auto;
    max-width: 700px;
    background-color: black;
    padding: 40px;
}
#book_area2{
    background-color: black;
    padding: 40px;
    margin-top: 20px;
    width: 100%;
}
#book_area .page-header{
    margin-top: 0;
    margin-bottom: 40px;
}
#book_area2 .page-header{
    margin-top: 0;
}
#book_area h1,
#book_area p{
    color: white;
}
#book_area input[type=text]{
    width: 100%;
    padding: 4px;
}
#book_area2 h1,
#book_area2 p{
    color: white;
}
#book_area2 input[type=text]{
    width: 100%;
    padding: 4px;
}
#firstName{
    margin-top: 20px;
}
#book_area .warning{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
#book_area2 .warning{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
#seminar #submitBtn{
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
}
#seminar #submitBtn2{
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
}
#seminar #popup-customized {
    overflow-y: scroll;
    background-color: rgba(0,0,0,0.6);
}

#seminar #popup-customized>div {
    display: block;
    margin: 15vh auto;
    background:none;
}


/*******************************************************************************
Seminar Accodian
*******************************************************************************/
#smr-accordion .smr-row.odd{
    background-color: rgba(245, 246, 247, 1);
}
#smr-accordion .smr-heading {
    display:block;
    color: #333333;
}
#smr-accordion .heading table,
#smr-accordion .smr-heading table{
    table-layout: fixed;
    width: 100%;
}

#smr-accordion th,
#smr-accordion td{
    padding: 10px 2px;
    text-align: center;
}
#smr-accordion .heading th.smr-date,
#smr-accordion td.smr-date{
    width: 15%
}
#smr-accordion .heading th.smr-time,
#smr-accordion td.smr-time{
    width: 15%;
    border-right: 1px solid #CCCCCC;    
}
#smr-accordion .heading th.smr-title,
#smr-accordion td.smr-title{
    width: 42%;
    border-right:1px solid #CCCCCC;  
}
#smr-accordion .heading th.smr-venue,
#smr-accordion td.smr-venue{
    width: 28%;
}
#smr-accordion .smr-body{
    background-color: rgba(245, 246, 247, 1);
    padding:10px;
    border:1px solid rgba(254, 204, 10, 1);
}

@media screen and (max-width: 767px){
    #bookBtn{
        display: block;
        width: 100%;
        margin: 10px 0;
    }
    #shareBtn{
        display: block;
        width: 100%;
        margin: 10px 0;
    }
}


#smr-list .smr-row-v2 {
    background-color: rgb(51, 51, 51);
    padding: 0;
    margin-top: 20px;
    width: 100%;
    /* font-family: 'Roboto', sans-serif, Arial; */
    font-weight: 300;
    color: #FFF;
    line-height: normal;
    overflow: hidden;
}
#smr-list .smr-row-v2  .smr-img{
    width: 100%;
    float: right;
    overflow: hidden;
    border: 1px solid rgb(51,51,51);
    background-color: #FFF;
}
#smr-list .smr-row-v2  .smr-img img{
    position: relative;
    left: 0px;
    width: 100%;
}
#smr-list .smr-row-v2  .smr-body-v2{
    width: 100%;
    padding: 20px 10px;
    position: relative;
    float: left;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-datevenue{
    color: rgb(254, 204, 10);
    font-size: 16px;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-title-v2{
    font-size: 20px;
    margin: 1em 0;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-content{
    font-size: 14px;
    word-wrap: break-word;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-content p{
    color: #FFF;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-content .readmore{
    color: #0675C2;
    cursor: pointer;
}
#smr-list .smr-time{
    color: rgb(254, 204, 10);
    font-size: 16px;
    margin: 1em 0;
}
.readmore{
    color: #0675C2;
    cursor: pointer;
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2{
    position: static;
    width: 100%;
    margin-top: 10px;
}

#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .bookBtn-area .bookBtn-v2{
    display: inline-block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    color: rgb(102, 102, 102);
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .bookBtn-area .bookBtn-v2.disabled{
    cursor: inherit;
    background-color: grey;
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area{
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    height: 35px;
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .shareToLabel{
    font-size: 12px;
    position: absolute;
    left: 0px;
    top: 10px;
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .facebookBtn{
    background-image: url(../../../../public/img/education/icon-facebook.png);
    left: 27%
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .twitterBtn{
    background-image: url(../../../../public/img/education/icon-twitter.png);
    left: 47%
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .instagramBtn{
    background-image: url(../../../../public/img/education/icon-instagram.png);
    left: 67%
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .shareBtn{
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
/*    background-size: 35px 35px;*/
    width: 35px;
    height: 35px;
    background-color: #FFF;
    border-radius: 9px;
    position: absolute;
    top: 0px;
}
#book_area2 .smr-txt-area{
    color: #FFF;
}
#book_area2 .smr-datevenue{
    color: rgb(254, 204, 10);
    font-size: 16px;
}
#book_area2 .smr-title-v2{
    font-size: 20px;
    margin: 1em 0;
}
#book_area2 .smr-content{
    font-size: 14px;
    word-wrap: break-word;
}
#book_area2 .smr-content p{
    color: #FFF;
}
#book_area2 .smr-content .readmore{
    color: #0675C2;
    cursor: pointer;
}
#intro-row{
    position: absolute;
    top: 27px;
    bottom: auto;
}
#intro-row .intro-body{
    background-color: rgba(255, 255, 255, 0.6);
    /* font-family: 'Roboto', sans-serif, Arial; */
    font-weight: 300;
    line-height: normal;
    padding: 16px 20px;
}
#intro-row .intro-body .intro-title{
    font-size: 20px;
}
#intro-row .intro-body .intro-content{
    margin-top: 10px;
    font-size: 10px;
}

body div#seminar #popup-customized.readmore-popup {
    /*position: absolute;*/
}

#readmore_area{
    margin: auto;
    max-width: 1082px;
    background-color: #FFF;
    /* font-family: 'Roboto', sans-serif, Arial; */
    font-weight: 300;
    line-height: normal;
}
#readmore_area .readmore-header{
    background-color: #000;
    padding: 35px 50px 30px 66px;
    color: #FFF;
    overflow: hidden;
}
#readmore_area .readmore-header .smr-datevenue{
    float: left;
    color: rgb(254, 204, 10);
    font-size: 15px;
    font-weight: 600;
}
#readmore_area .readmore-header .smr-title-v2{
    float: left;
    clear: left;
    font-size: 33px;
}
#readmore_area .readmore-header .shareBtn-area{
    float: right;
}
#readmore_area .readmore-header .shareBtn-area .shareToLabel{
    font-size: 14px;
    margin-bottom: 2px;
    margin-left: 10px;
}
#readmore_area .readmore-header .shareBtn-area .facebookBtn{
    background-image: url(../../../../public/img/education/icon-facebook.png);
}
#readmore_area .readmore-header .shareBtn-area .twitterBtn{
    background-image: url(../../../../public/img/education/icon-twitter.png);
}
#readmore_area .readmore-header .shareBtn-area .instagramBtn{
    background-image: url(../../../../public/img/education/icon-instagram.png);
}
#readmore_area .readmore-header .shareBtn-area .shareBtn{
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
/*    background-size: 35px 35px;*/
    width: 35px;
    height: 35px;
    background-color: #FFF;
    border-radius: 9px;
    float: left;
    margin-left: 10px;
}
#readmore_area .readmore-body{
    padding: 46px 49px 60px 70px;
    overflow: hidden;
}
#readmore_area .readmore-body .smr-img{
    float: right;
    width: 46%;
    /* height: 390px; */
    overflow: hidden;
}
#readmore_area .readmore-body .smr-img img{
    position: relative;
    top: 0px;
    left: 0px;
    /* height: 390px; */
}
#readmore_area .readmore-body .content-pane {
    width: 100%;
    padding-right: 0px;
}
#readmore_area .readmore-body .content-pane .smr-content{
    font-size: 15px;
    font-weight: 500;
}
#readmore_area .readmore-body .content-pane .smr-content p{
    color: #000;
}
#readmore_area .readmore-body .content-pane .btn-area-v2{

}
#readmore_area .readmore-body .content-pane .btn-area-v2 .bookBtn-area{
    width: 147px;
    margin-top: 1em;
}
#readmore_area .readmore-body .content-pane .btn-area-v2 .bookBtn-area .bookBtn-v2{
    display: inline-block;
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
    color: #000;
    font-weight: 500;
    font-size: 17px;
}
#smr-list .smr-txt-area{
    /*height: 300px;*/
}
#book_area2 .smr-txt-area{
    height: 150px;
    /*margin-bottom: 20px;*/
}
#nosmr{
    background-color: #E3E3E3;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

#seminar  .overlay-inner {
    margin: 15vh auto;
    width: 80%;
    max-width: 600px;
    height: auto;
    background: rgb(235, 236, 236);
}
#seminar .popupbox-content {
    margin-bottom: 20px;
    margin-left: 25px;
    margin-right: 25px;
    padding: 25px;
    background: #ffffff;
}
#nosmr {
    background-color: #E3E3E3;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (min-width: 768px){
    #smr-list .smr-txt-area{
        /* height: 250px; */
    }
    #smr-list .smr-row-v2  .smr-img{
        width: 50%;
        /* height: 355px; */
    }
    #smr-list .smr-row-v2  .smr-img img{
        top: 0px;
        left: 0px;
        width: auto;
        /* height: 355px; */
    }
    #smr-list .smr-row-v2  .smr-body-v2{
       width: 50%;
       /* height: 395px; */
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2{
        /* position: absolute; */
        height: 60px;
        bottom: 20px;
        left: 0;
        margin-top: 0;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .bookBtn-area{
        width: 120px;
        position: absolute;
        left: 10px;
        bottom: 0;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area{
        position: absolute;
        right: 10px;
        bottom: 0;
        margin-top: 0;
        overflow: visible;
        height: auto;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .shareToLabel{
        float: none;
        margin-bottom: 8px;
        margin-left: 10px;
        position: static;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .shareBtn{
        float: left;
        margin-left: 10px;
        position: static;
    }
    #seminar .stripe-bg{
        margin-bottom: -216px;
        height: 247px;
    }
    #intro-row{
        top: auto;
        bottom: 7px;
    }
    #intro-row .intro-body{
        padding: 30px 40px;
    }
    #intro-row .intro-body .intro-title{
        font-size: 38px;
    }
    #intro-row .intro-body .intro-content{
        font-size: 16px;
    }
    #readmore_area .readmore-body .content-pane{
        width: 54%;
        padding-right: 70px;
    }
}
@media (min-width: 992px){
    #smr-list .smr-txt-area{
        /* height: 220px; */
    }
    #smr-list .smr-row-v2  .smr-img{
        /* height: 500px; */
        margin-top: 18%;
    }
    #smr-list .smr-row-v2  .smr-img img{
        top: 0px;
        left: 0px;
        /* height: 500px; */
        width: 100%;
    }
    #smr-list .smr-row-v2  .smr-body-v2{
       /* height: 500px; */
       padding: 40px 30px;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2{
        bottom: 5px;
        position: relative;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .bookBtn-area{
        left: 0px;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area{
        right: 10px;
    }
    #intro-row{
        top: auto;
        bottom: 73px;
    }
    #intro-row .intro-body{
        padding: 30px 40px;
    }
    body div#seminar #popup-customized.readmore-popup {
        position: fixed;
    }
}
@media (max-width: 576px){    
    #book_area2 .smr-txt-area {
        height: 300px;
    }
}
@media (min-width: 1100px){
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area{
        right: 30px;
    }
}