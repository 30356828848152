.outer_area {
    text-align: center;
    padding: 10px 0;
}
.outer_area img {
    width: 50px;
}
.outer_area .inner_title {
    /* font-family: "HelveticaNeueMedium"; */
}
#termsofuse .inner_title{
    font-size: 16px;
    color: #333333!important;
}
#termsofuse a{
    background-color: transparent;
}
#termsofuse  a,#termsofuse  a:focus,#termsofuse  a:hover,#termsofuse  a:visited {
    color: #333333;

}