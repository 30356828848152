.filter-groud {
    margin-top: 30px;
    margin-bottom: 20px;
}

#dwsearch.indicator {
    text-align: right;
    margin-bottom: 20px;
}

#dwsearch .indicator .indicator-list {
    margin-right: -5px;
    /*text-align: right;*/
}

#dwsearch .indicator .indicator-list li {
    margin-right: 16px;
}

#dwsearch .indicator .indicator-list li:last-child {
    margin-right: 0px;
}

.search-tooltip {
    padding: 8px 10px !important;
}

input {
    opacity: 1 !important;
}

.selectSption00:hover {
    background: #F5F5F5;
}

#dwsearch .indicator-item-icon {
    display: inline-block;
    width: 10px;
    height: 14px;
    margin-right: 6px;
}

#dwsearch .icon-hotdw {
    background: url('../../../../public/img/bullet/hot.fw.png') 0 0 no-repeat;
    width: 10px;
    height: 14px;
    margin-bottom: -1px;
}

#dwsearch .icon-liquidity {
    background: url('../../../../public/img/bullet/liquidity.fw.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    margin-bottom: -2px;
}

#dwsearch .icon-expiry {
    background: url('../../../../public/img/bullet/expiry.fw.png') 0 0 no-repeat;
    width: 12px;
    height: 13px;
    margin-bottom: -2px;
}

/* #dwsearch .icon-soldout {
    background: url('../../../../public/img/bullet/soldout.fw.png') 0 0 no-repeat;
    width: 12px;
    height: 12px;
    margin-bottom: -2px;
} */

#dwsearch .icon-responsive {
    background: url('../../../../public/img/bullet/responsive.fw.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    margin-bottom: -2px;
}
#dwsearch .icon-lowtimedecay {
    background: url('../../../../public/img/bullet/longest_dated.png') 0 0 no-repeat;
    background-size: contain;
    height: 16px;
    margin-bottom: -2px;
    width: 13px;
}
#dwsearch .icon-soldOut {
    background: url('../../../../public/img/bullet/sold_out.png') 0 0 no-repeat;
    height: 12px;
    margin-bottom: -2px;
    width: 12px;
}

#dwsearch .icon-hotlist {
    background: url('../../../../public/img/bullet/hotlist.fw.png') 0 0 no-repeat;
    width: 13px;
    height: 18px;
    /* position: relative;
    top: 3px; 
    transform: scale(.9);
    /* -ms-transform: scale(.9);
    -webkit-transform: scale(.9); */
}

#dwsearch .ttip {
    color: inherit;
}

#dwsearch .result-disclaimer ul {
    margin-left: 25px;
}

#dwsearch .result-disclaimer ol,
.showmore ol,
#dwsearch .result-disclaimer ul,
.showmore ul {
    padding-left: 10px;
    font-size: 12px;
}

/**result table*/
#dwsearch .result {
    margin-top: -13px;
}

#dwsearch .result>table {
    font-size: 12px;
    width: 100%;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 20px;
}

#dwsearch .result table tbody tr:nth-child(odd) {
    background-color: #F5F6F6;
}
.loader-div {
    position: relative;
    text-align: center;
    min-height: 80px;
  }
#dwsearch .result>table th {
    text-align: center;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-top: 6px;
    padding-bottom: 6px;
    font-weight: normal;
}

#dwsearch .sorttip {
    font-size: 11px;
}

#dwsearch #resulttable-xs {
    margin-bottom: 10px;
    margin-top: 10px;
}

#dwsearch .result-sort-down {
    color: #FF0000;
}

#dwsearch .result-sort-up {
    color: #008022;
}

#dwsearch .result-sort-up,
#dwsearch .result-sort-down {
    cursor: pointer;
}

.search-tooltip {
    pointer-events: auto !important;
    width: 300px;
    opacity: 1 !important;
}

.search-tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.bordered {
    border-right: 1px solid #CCC;
}

#resulttable td {
    text-align: center;
    padding: 6px 0px;
}

#resulttable tr:hover td {
    background: #CEE5F5;
}

.tools-search-tooltip {
    background: #FECC0A !important;
    padding: 5px 5px !important;
    pointer-events: auto !important;
    width: 300px;
    opacity: 1 !important;
}
@media screen and (max-width: 450px) {
    .section-btn-wapper-mobile a{
       width: 48% !important;
       min-width: auto !important; 
       white-space: pre-wrap;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
    }
    #resulttable-xs th:nth-child(5){
        width: 30%;
    }
    #dwsearch #resulttable-lg{
        table-layout: unset;
        width: 600px;
    }
    #dwsearch #resulttable-lg tr td:first-child ,#dwsearch #resulttable-lg tr th:first-child{
        position: sticky;
        left: 0px;
    }
    #dwsearch .result table tbody tr:nth-child(odd) td{
        background-color: #F5F6F6;
    }
    #dwsearch .result table tbody tr:nth-child(even) td{
        background-color: #fff;
    }
    #dwsearch .result  tr th{
        background-color: #fff;
        z-index: 9999;
    }
}
