#campaign.pageobj{
    margin-bottom: 0;
}

#campaign .btn-primary{
    border-radius: 6px;
    padding-top: 9px;
    padding-bottom: 9px;
}

#campaign .top-background{
    background: url(../../../../public/img/education/campaign_topbackground.jpg) center center no-repeat;
    height: 400px;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#campaign .top-background i{
    font-size: 20px;
    /*color: #0066a5;*/ 
}

#campaign .top-txtarea{

}
#campaign .top-txtarea h1{
    font-size: 42px;
    margin-bottom: 5px;
}
#campaign .top-txtarea h3{
    padding:0;
    margin:0;
}

#campaign .videobox{
    margin-top: -80px;
}
#campaign .videobox-item .btn{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 200px;
}
#campaign .videobox-item,
#popup-customized .videobox-item{
    background: white;
    padding: 20px;
    min-height: 460px
}
#campaign .videobox-item-imgarea{
    margin: -20px;
    margin-bottom: 20px;
    cursor: pointer;
}
#campaign .videobox-item-imgarea.passive{
    cursor: inherit;
}
#campaign .videobox-item-imgarea img{
    width: 100%;
}
#campaign .videobox-item-txtarea hr,
#popup-customized .videobox-item-txtarea hr{
    border-top: 2px solid #FFD400;
    margin-top: 10px;
    margin-bottom: 10px;
}
#campaign .videobox-item-txtarea h3,
#popup-customized .videobox-item-txtarea h3{
    text-align: center;
}
#campaign .videobox-item.inactive,
#popup-customized .videobox-item.inactive{
    background-color: #858585;
    position: relative;
}
#campaign .videobox-item.inactive,
#campaign .videobox-item.inactive p,
#campaign .videobox-item.inactive h3,
#popup-customized .videobox-item.inactive,
#popup-customized .videobox-item.inactive p,
#popup-customized .videobox-item.inactive h3{
    color: #FFF;
}
#campaign .videobox-item-fbarea,
#popup-customized .videobox-item-fbarea{
    position: absolute;
    bottom: 30px;
    left: 20px;
    right: 20px;
    text-align: center;
}
#campaign .videobox-item-fbarea .fb-like,
#popup-customized .videobox-item-fbarea .fb-like{
    margin-bottom: 10px;
}

#campaign .hrbar{
    font-size: 26px;
    color: #FFF;
    background-color: #333333;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
}

#campaign .bttm{
    padding-top: 50px;
    padding-bottom: 40px;
    background-color: skyblue;
    background: url(../../../../public/img/education/campaign_bttmbackground.jpg) center center no-repeat;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#campaign .bttm-row{
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
}
#campaign .bttm-background img{
    display: block;
    width: 100%;
}
#campaign .bttm-item-left{
    padding-right: 10px;
}
#campaign .bttm-item-left button,
#campaign .bttm-item-left a{
    width: 100%;
    margin-top: 16px;
    background-color: #333333 !important;
    border-color: #333333 !important;
    color: #FFF !important;
}
#campaign .bttm-item-right{
    padding-left: 10px;
}
#campaign .bttm-item-left p,
#campaign .bttm-item-right p{
    font-size: 20px;
}
#campaign .bttm-disclaimer{
    margin-top: 40px;
}

#campaign .notiform{
    border: 1px solid #666666;
    background: #FFFFFF;
    padding: 40px;
}
#campaign .notiform p{
    margin-bottom: 20px;
}
#campaign .notiform input{
    display: block;
    width: 100%;
   margin-bottom: 16px;
    height: 40px;
    font-size: 16px;
}
#campaign .notiform input.input-warning{
    border: 1px solid #FF0000 !important;
}
#campaign .notiform .warning{
    color: #FF0000;
    margin-bottom: 16px;
}
#campaign .notiform button{
    display: block;
    width: 100%;
}

#campaign .notiform .fbarea{
    display: block;
    margin-left:auto;
    margin-right:auto;
    position: relative;
    height: 48px;
    max-width: 220px;
}
#campaign .notiform .fbarea img{
    width: 100%;
}
#campaign .notiform .fbarea-left{
    position: absolute;
    top:0;
    width: 48px;
}
#campaign .notiform .fbarea-left img{
    border:0;
    cursor: pointer;
}
#campaign .notiform .fbarea-right{
    position: absolute;
    top: 0;
    left: 48px;
    right: 0;
    text-align: center;
}
#campaign .notiform .fb-like{
    height: 28px;
}

#campaign #popup-video{
    display: table;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    text-align: center;
}
#campaign #popup-video>div{
    display: table-cell;
    vertical-align: middle;
}
#campaign #popup-video .inner-area{
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 660px;
    background-color: #333333;
    padding: 30px;
}
#campaign #popup-video .inner-area .btn-close{
    position: absolute;
    top: 1px;
    right: 1px;
    cursor: pointer;
}

#campaign .videoplayer{
    width: 100%;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
}
#campaign .videoplayer-container{
    height: 0;
    width: 100%;
    padding-bottom: 56%;
    overflow: hidden;
    position: relative;
}
#campaign .videoplayer iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

#campaign #popup-video-bg,
#campaign #popup-customized-bg{
    display: block;
    position: fixed;
    top:0;
    left:0;    
    right:0;
    bottom:0; 
}
#campaign #popup-video-bg{
    background-color: rgba(255,255,255,0.5);
}
#campaign #popup-customized-bg{
    background-color: rgba(0,0,0,0.6);
}

#campaign #popup-customized>div{
    background-color: initial;
}

#campaign #popup-customized .btn-close{
    position: absolute;
    right: 2px;
    top: 8px;
    width: 32px;
    height: 32px;
}

#campaign #popup-video .btn-close{
    position: absolute;
    right: 2px;
    top: 8px;
    width: 32px;
    height: 32px;
}

#campaign #popup-customized .btn-close:before,  
#campaign #popup-customized .btn-close:after,
#campaign #popup-video .btn-close:before,  
#campaign #popup-video .btn-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 17px;
    width: 2px;
    background-color: #000;
}
#campaign #popup-customized .inactive .btn-close:before,
#campaign #popup-customized .inactive .btn-close:after,
#campaign #popup-video .btn-close:before,  
#campaign #popup-video .btn-close:after {
    background-color: #FFF !important;
}
#campaign #popup-customized .btn-close:before ,
#campaign #popup-video .btn-close:before {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari */
    transform: rotate(45deg);
}
#campaign #popup-customized .btn-close:after,
#campaign #popup-video .btn-close:after {
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);
}

.table-fb{
    margin-left: auto;
    margin-right: auto;
}

.table-fb .td-fb-like iframe,
.table-fb .td-fb-like .fb-like > span{
    width: 55px !important;
}
.table-fb .td-fb-like,
.table-fb .td-fb-share{
    overflow: hidden;
}
.table-fb .td-fb-share .fb-like{
    margin-left: -54px;
}


/*.fb-like iframe{
    width: 53px !important;
}*/

.fb-share{
    position: relative;
    height: 0px;
    top: -34px;
    left: 29px;
}

.fb-share button{
    margin-bottom: 0px;
}

.fbarea-right .fb-share{
    top: -26px;
    left: 93px;
}

.fb-share ._2tga._49ve {
    border-radius: 3px;
    font-size: 11px;
    height: 20px;
    width: 44px !important
    /*padding: 0 0 0 2px;*/
}

.fb-share ._2tga {
    background: #4267b2;
    border: 1px solid #4267b2;
    color: #fff;
    cursor: pointer;
    /* font-family: Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    margin: 0;
    -webkit-user-select: none;
    white-space: nowrap;
}

.fb-share ._2tga span._49vh, 
.fb-share ._2tga span._5n6h, 
.fb-share ._49vh, ._5n6h {
    /* font-family: Helvetica, Arial, sans-serif; */
    vertical-align: middle;
}


@media only screen and (min-width : 768px) {
    .is-table-row {
        display: table;
    }
    .is-table-row .col-sm-4 {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
}
@media screen and (max-width: 992px){
    
}
@media screen and (max-width: 767px){
    #campaign p.small{
        font-size: 10px;
    }
    #campaign .btn-primary{
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: 14px;
    }
    #campaign .btn-lm{
        background-color: #333333;
        color: #FFF;
    }
    
    #campaign .row{
        margin-left: -15px;
        margin-right: -15px;   
    }
    #campaign .top-background{
        height: 320px;
        background: url(../../../../public/img/education/campaign_topbackground_mobile.jpg) center center no-repeat;
        background-size: cover;
    }
    #campaign .top-background h1{
        font-size: 30px;
        margin-top: 10px;
    }
    #campaign .top-background h3{
         font-size: 16px;
    }
    #campaign .top-background i{
         font-size: 14px;
    }
    
    #campaign .videobox-item {
        min-height: inherit;
        margin-bottom: 16px;
        padding: 10px;
    }
    #campaign .videobox-item .btn{
        margin-top: 0px;
    }
    
    #campaign .videobox-item-imgarea {
        margin: -10px;
        margin-bottom: 10px;
    }
    #campaign .videobox-item-txtarea h3{
        font-size: 18px;
        margin:0;
    }
    #campaign .hrbar{
        margin-top: 0px;
        font-size: 18px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    
    #campaign .bttm{
        padding-top: 0;
        padding-bottom: 0;
        background: inherit;
    }
    #campaign .bttm-item-left,
    #campaign .bttm-item-right{
        margin-left: -5px;
        margin-right: -5px;
    }
    #campaign .bttm-item-left{
        padding: 20px 10px;
        background: url(../../../../public/img/education/campaign_bttmbackground.jpg) center center no-repeat;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    #campaign .bttm-item-right{
        padding-left:0;
    }
    #campaign .bttm-item-left p, 
    #campaign .bttm-item-right p{
        font-size: 14px;
    }
    #campaign .bttm-item-left button{
        margin-top: 8px;
    }
    #campaign .notiform{
        border:0;
        padding: 20px
    }
    #campaign .notiform input{
        display: block;
        width: 100%;
        margin-bottom: 16px;
        height: 34px;
        font-size: 14px;
    }
    #campaign .notiform .warning{
        font-size: 14px;
    }
    #campaign .bttm-disclaimer-holder{
        background-color: #EBECEC;
    }
    #campaign .bttm-disclaimer{
/*        font-size: 10px;*/
        margin-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    
    #popup-customized  .videobox-item {
         max-width: 600px;
         min-height: 260px;
         margin-left: 20px;
         margin-right: 20px;
    }
    
    .fbarea-right .fb-share{
    top: -28px;
    left: 93px;
}
   
   
    
}
