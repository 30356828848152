.flex_row{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
}
.flex_column{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
}
.howCalculate_con{
    margin: 20px 0;
    overflow-x: auto;
}
.howCalculate_title{
    font-weight: 700;
    font-size: 18px;
    display: block;
    white-space: nowrap;
}
.howCalculate_title2{
    margin-top: 20px;
}
.howCalculate_center{
    margin: 0 10px;
}
.howCalculate_count{
    color: #000000;
    font-size: 18px;
    margin-top: 15px;
    text-align: center;
    white-space: nowrap;
}
.howCalculate_underline{
    border-bottom: 2px solid #000000;
}
.howCalculate_right label{
    margin-left: 5px;
}
.howCalculate_example{
    border: 2px solid #000000;
    padding: 6px;
    width: 500px;
}
.howCalculate_example span{
    width: 50%;
    color: #000000;
    margin-bottom: 4px;
    display: block;
}
.howCalculate_example_count{
    margin: 20px 0;
}
.howCalculate_example_total{
    width: 386px;
    color: #000000;
    text-align: right;
    display: block;
    font-size: 20px;
}
#warranttbl{
    table-layout: unset !important;
}
#warranttbl td,
#warranttbl th{
    min-width: 85px;
}
@media screen and (max-width: 767px){
    #ppBoxHTML .overlay-inner>div{
        padding:40px 10px 0px 10px!important;
    }
}