/*******************************************************************************
 common styling - color
*******************************************************************************/
.color-primary {
    color: #FECC0A !important;
}

.color-default {
    color: #333;
}

.color-inverse {
    color: #fff;
}

.color-hyperlink {
    color: #0675C2 !important;
}


/*******************************************************************************

*******************************************************************************/
.combobox {
    position: relative;
}

.tablecom th {
    text-align: left;
}

.combobox-input {
    margin: 0;
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}

/*******************************************************************************

*******************************************************************************/
#comparetbl table .btn-primary {
    border-radius: 3px;
    border-color: #FECC0A !important;
}

#comparetbl .table {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#comparetbl .table>tbody th {
    width: 25%;
}

#comparetbl .table>tbody td {
    text-align: center;
    border-left: 1px solid #ddd;
}

#comparetbl .table>tbody tr:last-child {
    border-bottom: 1px solid #FECC0A;
}

#comparetbl .table>tbody tr.rw-ticker td,
#comparetbl .table>tbody tr.rw-issuer td {
    /* font-family: "HelveticaNeueMedium"; */
}

#comparetbl tr.rw-symbl td {
    font-weight: bold;
}

.table {
    font-size: 14px;
}

.panel-collapse>div {
    min-height: 180px;
    max-height: 260px;
    overflow-y: auto;
}

#accordion .panel-collapse .table {
    margin: 0;
    text-align: center;
}

#accordion .panel-collapse .table td {
    white-space: nowrap;
}

#accordion .panel-heading {
    position: relative;
}

#accordion .panel-heading a {
    color: #333333;
}

.disabled-area {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ddd;
    z-index: 8;
    -khtml-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-filter: "alpha(opacity=60)";
    filter: alpha(opacity=60);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
    opacity: 0.6;
}

#collapse_dwcompare2 .table-striped>tbody>tr:nth-of-type(odd) {
    background-color: transparent;
}

#collapse_dwcompare2 .table-striped>tbody>tr:first-child,
#collapse_dwcompare2 .table-striped>tbody>tr:nth-of-type(even) {
    background-color: #F5F6F7;
}

.comparison-tooltip {
    pointer-events: auto !important;
    max-width: 250px;
}

.comparison-tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
}