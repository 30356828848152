.alertBox p {
    color: rgb(255, 225, 128);
    cursor: pointer;
    font-size: 20px;
    line-height: 25px;
}

.alertBox {
    position: absolute;
    top: 256px;
    /* left: 38%; */
    font-size: 25px;
    text-align: center;
    width: 100%;
}

.alertBox a,
.alertBox a:focus,
.alertBox a:hover,
.alertBox a:visited {
    color: #333;
    width: 100%;
    display: block;
    font-size: 17px;
}

.ulBox {
    list-style: none;
    padding: 0;
}

.ulBox li {
    color: #333;
    cursor: pointer;
    line-height: 40px;
}

.ulBox li :hover {
    color: rgb(255, 225, 128);
}