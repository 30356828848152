
.footer_page{
    position: fixed;
    width: 100%;
    bottom: 0;
    min-height:46px;
    background-color: rgb(16, 16, 16);
    font-size: 11px;
    color: #8f8f8f;
    z-index: 99999999;
    padding: 5px 10px;
}
.footer_page .flex_row{
    display: flex;
    display: -webkit-flex;
    align-items: center;
    flex-direction: row;
    margin: 0 !important;
}
.footer_page .flex_column{
    display: flex;
    display: -webkit-flex;
    align-items: center;
    flex-direction: column;
    margin: 0 !important;
}
.footer_con{
    height: 100%;
    width: 100%;
    justify-content: space-between;
}
.footer_left{
    cursor: pointer;
    width: 25%;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-start;
}
.footer_right{
    cursor: pointer;
    width: 25%;
    text-align: right;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
}
.footer_center{
    flex: 1;
    text-align: center;
}
.footer_center a,
.footer_center a:hover,
.footer_center a:visited,
.footer_center a:focus{
    color: #8f8f8f;
    line-height: 18px;
}
.footer_con .prve{
    display: block;
    width: 23px;
    min-width: 23px;
    height: 23px;
    margin-right: 5px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuNvyMY98AAAH5SURBVEhLlVVZcsIwDM1VOi0Qkn4yw8AnDBQIWxi2XoqDcBDuAD1LcfXsCMsLFD5evER+krU5UUrdRbtV64z69cNukZ0IarPMFEasx736odN668TOMaKb3fZHZzVNNaHEdt6kMdXzzSLX45rkIB/jCTZmX43Dep6p/dIc3hGJnceBG00H6dHnchZl0Ti6B60Cd1/C/qNbOApuk8kghW/FIQvjDneUtxGxUMRzU6A/8JkvxBYxofvf+N0gdBvHQJOXRVMHzwrJwy4g83uZVrIuKf9fTbOTJu+2jNUuLPl+aSzfU5AxauKIcu2qSgYKwJuM+rWor7elvIUhu57Z4lBeAopQHwn5MchnY4EhARmsV5eZI/METklkM4C6FNF9g9RLBIuAnAU5S67nifMfYN8C3yKovsuI/EFmaJKUghgqAHyL/XVCPUL73C8MBh9gn8u8d2HPmjP5KRn3ZLbEU4zn15+CbuMaYA1yydE1qa2+R/LcYL/8DPaQ5zy3/nYVbsmd4K0qtFGloyskwTeAVVKBBGIEOVT8rfy5t7wGW1yAdF+3Xbe9BSiGTd1un6nA9eJeUCNdkUENR/TzvLImnqqcRcaYXK/p9Yr3cwZeFLxEPpl/IwRNFlMx/OclYiAG5SzsOT4h8NIbKoHXH/lK1/b6vXn92w9ff5X8AZ4eGlH8vUhGAAAAAElFTkSuQmCC);
}
.footer_con .next{
    display: block;
    width: 23px;
    min-width: 23px;
    height: 23px;
    margin-left: 5px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuNvyMY98AAAIDSURBVEhLjVVJYsIwDMxf2JxwZWlypKULCRBatn6Kh/CQ/KH0LcW1rCiWY1F6mMSR5ZE8spVIa30T01EnfZv1T4d1Uhnow3pokOj9Kq5eZr0TzEvrCKIxm3bTbaEYIZJKeDd+4C/xBIb8SUGmzeJjeZuYo5irc5vL+/jIB2dp4XEVjo00zmYTGJpdJF6AZgAZU5a70ifaCbIgufJs4Fc895sA9pE9dFKnq/Kyug8XAJNSOpv2bQ0sORQPJmRSpX8uhX0flrEnEaG9bpPHlSXPRt0U5GikYAV0YxagniN/67cKZQPe6PXRnuNgUjp+169FM+ZJSADeyGRQn2cZREJy6EsuSsN3BQDeiBv+i+v3UrQDuP6G/Nb2XB1CKCNR0dqBnzkgyNwraL2YbJ91IlhcmFN1AnKCkTn4VvO/CkRzcEn0ZWm2Pmj54N3gOwXeqO56zNEBM6egyhYTiH25cP5YktYoD/Datrpdh5eHE0DmoDGfb18c/MZAMJ6MO6m9oZt8UDlnjEwBwY4a+7JB8L14JBNdFvUNhQf0AmpOPCMa83qQ3tyP5unt9RZAMY+9dssJUSJ+1JR4kWxXZH29IQeYBib28xAY2C+sbVhyPycsTGTM0mVOaJMhcEd3/0SEdNJLt3lS+VvHYHCRuN7QXsFf4gkMHPzvTwVHDCuwP4zlHzNCR7/Qpxol1LPx9QAAAABJRU5ErkJggg==);
}
.footer_con .text{
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
}
.footer_center_home{
    text-align: center;
    font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}
@media screen and (max-width: 767px) { 
    .footer_page{
        display: flex;
        display: -webkit-flex;
        align-items: center; 
    }
    .footer_left,
    .footer_right{
        width: 50%;
    }
}