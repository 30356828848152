#dwterms .uppertbl {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    margin-bottom: 20px;
    /*    margin-top: 10px;*/
}
#priceTable{
    width: 100%;
     margin-bottom:15px;
}
#priceTable td {
    /* vertical-align: sub; */
    line-height: 35px;
}
#priceTable td .react-datepicker-wrapper input{
    height: 30px;
}
#priceTable .react-datepicker__input-container input  {
    width: 100%;
    /* border:0; */
}
#priceTable .react-datepicker__tab-loop {
    display: inline-block;
}
#priceTable .react-datepicker-wrapper {
    width: 40%;
    padding: 5px 0px;
}
#dwterms .uppertbl-col-bid {
    /*    border-right:10px solid white;*/
    vertical-align: middle;
    width: 50%;
}

#dwterms .uppertbl-col-chglbl {
    /*    border-bottom:10px solid white;*/
    width: 25%;
}

#dwterms .uppertbl-col-chgval {
    /*    border-bottom:10px solid white;*/
    text-align: right;
    width: 25%;
}

.TermsTable th,
.TermsTable td {
    text-align: left;
    font-size: 16px;
}

#srtable{
    overflow-x: auto;
}

#srtable .priorTable{
    table-layout: unset;
}

.priorTable th,
.priorTable td {
    font-size: 16px;
    min-width: 80px;
}
.minW70{
    min-width: 70px;
}

#dwterms .uppertbl-col-pchgval {
    text-align: right
}

#dwterms .uppertbl td {
    padding: 15px;

}

#dwterms .bidprice.val {
    font-size: 40px;
    line-height: 40px;
}

#dwterms #srtable {
    border-bottom: 1px #CCCCCC dotted;
}

#dwterms #srtable>table {
    margin-bottom: 0;
    white-space: break-spaces;
}

#dwterms #srtable .srtable-leftlbl {
    width: 30%;
}

#dwterms #srtable .srtable-leftval {
    width: 20%;
    /*border-right:20px solid #fff;*/
}

#dwterms #srtable .srtable-rightlbl {
    width: 30%;
    /*border-left:20px solid #fff;*/
}

#dwterms #srtable .srtable-rightval {
    width: 20%;
}


#dwterms #pstable,
#dwterms #indtable {
    display: table;
    width: 100%;
    margin-bottom: 20px;
}

.indicator_table {
    margin-bottom: 5px !important;
}

#dwterms #pstable {
    margin-bottom: 40px;
}

#dwterms #indtable table {
    table-layout: fixed;
}

#dwterms #indtable table th {
    font-weight: normal;
    color: #0675C2;
}

#dwterms #indtable table.visible-md th {
    width: 25%
}

#dwterms #chartbox {
    margin-bottom: 35px;
}

#dwterms .ctab {
    width: 100%;
    margin-bottom: 20px;
}

#dwterms .ctab-item {
    text-align: center;
    width: 25%;
    border: 2px solid #FECC0A !important;
    height: 33px;
    cursor: pointer;
}

#dwterms .ctab-item.active {
    background-color: #FECC0A;
    color: #000;
}

#dwterms .ctab-item-space {
    width: 12px;
}

#dwterms .chart-legend {
    margin-bottom: 20px;
}

#dwterms .chart-legend-left,
#dwterms .chart-legend-right {
    text-align: center;
}

#dwterms .chart-legend-bar {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 3px;
    bottom: 4px;
    margin-left: 5px;
}

#dwterms .chart-legend-bar.warrant {
    background-color: #F7941E;
}

#dwterms .chart-legend-bar.underlying {
    background-color: #097CCF;
}

#dwterms .chart-legend-tbl {
    width: 200px;
    margin: 0 auto;
}

#dwterms .nochart {
    width: 100%;
    height: 100%;
    border: 1px solid #CCC;
    position: relative;
}

#dwterms .nochart-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

#dwterms #dwterms-disclaimer table {
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}

#dwterms #dwterms-disclaimer table tr>td:first-child {
    white-space: nowrap;
}

#dwterms #defination {
    margin-top: 10px;
}

#dwterms #defination .panel-heading {
    background-color: #666666;
    padding: 4px 10px;
}

#dwterms #defination .panel-title {
    font-size: 14px;
}

#dwterms #defination .glyphicon {
    color: white;
}

#dwterms #defination #collapse_dwcompare3 {
    font-size: 12px;
    padding: 10px;
}

#dwterms #defination .table {
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}

#dwterms #defination .table td {
    padding-left: 0;
}

#dwterms #defination .table tr td:first-child {
    white-space: nowrap;
}

#dropdown-dwterms {
    margin-top: 10px;
    margin-bottom: 30px;
}

#dwterms .ricbar {
    position: relative;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 0px;
    min-height: 36px;
    height: auto;
}

#dwterms .tickerbar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: 8px;
    right: 200px;
    background-color: #DFDBCF !important;
}

#dwterms .ricbar-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 190px;
    bottom: 0;
}

.chartbox {
    width: 100%;
    height: 470px;
    position: relative;
    overflow: hidden;
    text-align: left;
}

#dwterms #srtable {
    border-bottom: 1px #CCCCCC dotted;
}

#dwterms #srtable .srtable-leftlbl {
    width: 30%;
}

#dwterms #pstable,
#dwterms #indtable {
    display: table;
    width: 100%;
    margin-bottom: 20px;
}

#dwterms #indtable table {
    table-layout: fixed;
}

.indicator_table {
    margin-bottom: 5px !important;
}

#indtable .table-striped>tbody>tr:nth-of-type(even) {
    background-color: #fff;
}
#dwterms .tablist {
    position: relative;
    height: 60px;
    margin-top: 20px;
}
#dwterms .tablist-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
#dwterms .tablist-left table {
    table-layout: fixed;
}
#dwterms .tablist-left table td {
    border-right: 5px solid #fff;
}
#dwterms .tablist-item {
    width: 130px;
    height: 55px;
    display: table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5px solid #E0E0E0;
    background-color: #EEF0F2;
    cursor: pointer;
}
#dwterms .tablist-item-txt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
}
#dwterms .tablist .active .tablist-item {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}
#dwterms .tablist .tablist-item:focus,
#dwterms .tablist .tablist-item:hover {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}
#dwterms #minidwcalc table tr>td:last-child {
    width: 50%;
}

#dwterms #minidwcalc table,
#dwterms #minidwcalc table input {
    width: 100% !important;
}

/* #dwterms #minidwcalc .slider.slider-horizontal {
    width: 100% !important;
} */

#dwterms #minidwcalc .hidden-xs, #dwterms #minidwcalc .hidden-sm {
    display: table !important;
}

@media screen and (max-width: 767px) {
    .ricbar .tickerbar {
        font-size: 12px;
        text-align: center;
        right: 140px !important;
        overflow: hidden;
        line-height: 36px;
    }
    #dwterms .tablist-item {
        width: auto;
    }
    .ricbar .ricbar-btn {
        /* font-family: HelveticaNeueLight; */
        width: 130px !important;
        padding: 0;
        line-height: 36px;
    }
    /* #priceTable td {
        vertical-align: sub;
    } */
    #priceTable tr:nth-child(2){
        vertical-align: sub;
    }
    #priceTable tr:nth-child(4){
        vertical-align:bottom;
    }
    /* #dwterms #srtable .srtable-rightlbl{
        width:41%;
    }
    #dwterms #srtable .srtable-rightval{
        width:10%;
    } */
    #priceTable .react-datepicker__input-container input  {
        width: 100%;
        /* border:0; */
    }
    #priceTable .react-datepicker__tab-loop {
        display: block;
    }
    #priceTable .react-datepicker-wrapper {
        width: 90%;
    }
    #dwterms .tickerbar{
        padding-left: 0;
        position: unset;
    }
    #dwterms .ricbar{
        padding: 0;
    }
    #dwterms #ticker{
        line-height: 18px;
        min-height: 36px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
    }
}