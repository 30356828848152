.tblh {
    position: relative;
    margin-top: 20px;
    height: 40px;
    margin-bottom: 10px;
}

.tblh-left {
    position: absolute;
    left: 0;
    bottom: 0;
}

.tblh h2.small {
    /*    font-family: 'HelveticaNeueMedium';*/
    font-size: 18px;
    font-weight: normal;
    margin: 0;
}

/*.lastupdatebox{
    font-size: 12px;
    float:right;
}*/
.table.turnoverTable {
    font-size: 16px !important;
}

#topTbl table {
    font-size: 16px;
    white-space: nowrap;
}

#topTbl th,
#topTbl td {
    text-align: center;
}

#topTbl tr td:first-child {
    width: 130px;
}

.chartbox {
    position: relative;
    margin-top: 30px;
}

.chartbox h2.small {
    /*    font-family: 'HelveticaNeueMedium';*/
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    /*    text-align: center;*/
    margin-bottom: 20px;

}

.chartbox-pie {
    height: 420px;
    width: 100%;
}

.chartbox-legend {
    position: absolute;
    right: 0;
}

.chartbox-legend-item {
    font-size: 12px;
    position: relative;
    height: 18px;
    margin-bottom: 10px;
}

.chartbox-legend-square {
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: -1px;
}

.chartbox-legend-txt {
    margin-left: 23px;
}

.chartbox-legend-square.cbg00 {
    background-color: #0675C2;
}

.chartbox-legend-xs {
    margin-top: 10px;
    min-width: 280px;
    margin-left: auto;
    margin-right: auto;
}

.chartbox-legend-xs table {
    table-layout: fixed;
    width: 100%;
}

.chartbox-legend-xs table td {
    padding-bottom: 10px;
}

.chartbox-legend-xs .chartbox-legend-item {
    margin-bottom: 0;
    overflow: hidden;
}

.chartbox-legend-square.cbg01 {
    background-color: #00A1E0;
}

.chartbox-legend-square.cbg02 {
    background-color: #6CC263;
}

.chartbox-legend-square.cbg03 {
    background-color: #F7941E;
}

.chartbox-legend-square.cbg04 {
    background-color: #FECC0A;
}

.chartbox-legend-square.cbg05 {
    background-color: #C61C2D;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 991px) {
    .turnoverdist_left{
        padding-right: 0 !important;
    }
}
@media screen and (max-width: 767px) {
    #topTbl table {
        font-size: 11px;
    }

    #topTbl table td,
    #topTbl table th {
        padding-left: 2px;
        padding-right: 2px;
    }
}

/*@media screen and (max-width: 767px) and (orientation:portrait){    
    #topTbl table{
        font-size: 11px;
    }
}*/