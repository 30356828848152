#highlight .highlight-date {
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

a,
a:focus,
a:hover,
a:visited {
    color: #0675C2;
    text-decoration: none;
}
#highlight-content img{
    max-width: 100%!important;
    height: auto!important;
}
#highlight .fbSharebtn {
    margin: 0 0 10px 10px;
}

#highlight .pnbar {
    position: relative;
    height: 22px;
}

#highlight .pnbar-prev {
    position: absolute;
    right: 50%;
    margin-right: 30px;
}

#highlight .pnbar-prev a.disabled {
    color: #CCC;
    cursor: default;
}

#highlight .pnbar-vl {
    position: absolute;
    right: 50%;
    top: 2px;
    bottom: 0;
    width: 0;
    border-left: 1px solid #CCC;
}

#highlight .pnbar-next {
    position: absolute;
    left: 50%;
    margin-left: 30px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-facebook-f:before,
.fa-facebook:before {
    content: "\f09a";
}

#highlight .pnbar-next a.disabled {
    color: #CCC;
    cursor: default;
}