.pageobj>.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
}

.container-fluid {
    max-width: 1200px;
}

#sp500 .section {
    padding-top: 0px;
}

.section {
    background-color: #fff;
    padding: 40px;
    margin-top: 20px;
    width: 100%;
}

#sp500 .row.row-fullw {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 30px;
    padding-right: 30px;
}

#sp500 .livematrix-row {
    background: url(../../../../public/img/education/sp500_header.png) no-repeat center center;
    background-size: cover;
    width: auto;
    height: 304px;
    margin-bottom: 40px;
}

#sp500 .livematrix-col {
    width: 100%;
    font-size: 36px;
    max-width: 20em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

#sp500 .livematrix-row .color-01 {
    color: #EC7001 !important;
}

#sp500 .livematrix-row .col-h {
    font-size: 36px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 30px;
}

#sp500 .livematrix-row .col-p {
    font-size: 24px;
}

#sp500 .row.row-fullw {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 30px;
    padding-right: 30px;
}

#sp500 .fpa-col {
    margin-bottom: 20px;
}

img {
    vertical-align: middle;
}

#sp500 .fpa-row {
    background-color: #F6F6F6;
    padding-top: 30px;
    padding-bottom: 30px;
}

#sp500 .fpa-row h3 {
    font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: #333333;
    line-height: normal;
}

p {
    margin: 0 0 10px;
}

#sp500 .fpa-col {
    margin-bottom: 20px;
}

#sp500 .fpa-col .table-header-txt {
    text-align: left;
    color: #F38019;
    padding: 10px;
    border: #d6d6d6 1px solid;
}

#sp500 .fpa-col .table-holder {
    overflow-x: auto;
}

#sp500 .fpa-col table {
    width: 100%;
    border-right: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
}

#sp500 .fpa-col th {
    background-color: #000000;
    color: #FFFFFF;
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}

#sp500 .fpa-col th,
#sp500 .fpa-col td {
    font-size: 12px;
    text-align: center;
    /* font-family: HelveticaNeueMedium; */
    font-weight: normal;
    padding: 8px 4px;
}

#sp500 .fpa-col th,
#sp500 .fpa-col td {
    font-size: 12px;
    text-align: center;
    /* font-family: HelveticaNeueMedium; */
    font-weight: normal;
    padding: 8px 4px;
}

#sp500 .fpa-col td a {
    color: #EC7001 !important;
}

#sp500 .fpa-col td {
    border-bottom: 1px solid #CCCCCC;
}

#sp500 .fpa-col {
    margin-bottom: 20px;
}

#sp500 .htt-row .color-01 {
    color: #EC7001 !important;
}

#sp500 .row.row-fullw {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 30px;
    padding-right: 30px;
}

.selector-loadingindicator {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../../../../public/img/bullet/loading.gif") center center no-repeat;
    background-color: white;
    z-index: 990;
    -khtml-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-filter: "alpha(opacity=60)";
    filter: alpha(opacity=60);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
    opacity: 0.6;
}

.text-center {
    text-align: center;
}

#sp500 .stt-row.ipadbg {
    background: url(../../../../public/img/education/ipad.png) right bottom no-repeat;
    width: auto;
    min-height: 420px;
    position: relative;
}

#sp500 .stt-col {
    position: absolute;
}

#sp500 .stt-item {
    padding-left: 50px;
    margin-top: 25px;
}

#sp500 .stt-item .pimg {
    margin-bottom: 8px;
}

#sp500 .stt-item .ph {
    font-size: 22px;
    /* font-family: 'HelveticaNeueMedium'; */
}

#sp500 .stt-item .ptxt {
    font-size: 14px;
    /* font-family: 'HelveticaNeueMedium'; */
}

@media screen and (max-width: 767px) {

    #sp500 .stt-row.ipadbg {
        background-size: 60%;
    }
}

@media screen and (max-width: 991px) {
    .section {
        padding: 20px 10px;
    }
}

@media screen and (max-width: 992px) {
    #sp500 .row.row-fullw {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 5px;
        padding-right: 5px;
    }
}