.swiper-wrapper {
    position: relative;
    margin-top: 74px;
    width: 100%;
    height: 100%;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
}

.swiper-wrapper .ul {
    height: 100%;
    list-style: none;
    width: 100%;
    padding-left: 0px;
}

.swiper-wrapper .ul .items {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}

.ul li.show {
    display: block;
}

.ul li {
    display: none;
}

.ul li img {
    width: 100%;
    height: 100%;
}

.swiper-wrapper .swiper-pagination {
    top: 90%;
    text-align: right;
    right: 5%;
    left: auto;
    position: absolute;
}

.swiper-pagination li {
    float: left;
    width: 16px;
    height: 16px;
    margin: 0px 5px;
    border-radius: 50%;
    transition: all .3s;
    background-color: antiquewhite;
    list-style: none;
}

.swiper-pagination li.active {
    background-color: #FECC0A;
}

.swiper-botton .left {
    position: absolute;
    top: 50%;
    left: 0px;
}

.swiper-botton .right {
    position: absolute;
    top: 50%;
    right: 0px;
}

.swiper-botton .left,
.swiper-botton .right {
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    transform: translateY(-50%);
    width: 64px;
    height: 100%;
}

.swiper-botton .gallery-nav:hover {
    background-color: rgba(146, 146, 146, 0.5);
}

@media screen and (max-width: 768px) {
    .swiper-wrapper {
        margin-top: 52px;
    }
}

@media screen and (max-width: 991px) {
    .swiper-wrapper {
        margin-top: 53px;
    }
}

@media screen and (max-width: 450px) {
    .swiper-pagination li {
        width: 10px;
        height: 10px;
    }
}