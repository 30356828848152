
#dwvideoind .section-p{
    min-height: inherit;
    margin-bottom: 20px;
}
#dwvideoind .videorack .videorack-title{
    padding-left:20px;
    padding-right:20px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(204, 204, 204, 1);
    font-size: 20px;
}
#dwvideoind .videorack-body{
    position: relative;
    overflow: hidden;
    margin: 10px 0;
}
#dwvideoind .videorack-nav{
    position: absolute;
    background-color: rgba(245, 246, 247, 1);
    width:30px;
    top:0;
    bottom:0;
    height: 100%;
}
#dwvideoind .videorack-nav.backward{
    left:0;
}
#dwvideoind .videorack-nav.forward{
    right:0;
}

#dwvideoind .videorack-nav-icon{
    display: block;
    position: absolute;
    width: 16px;
    height: 26px;
    left: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
#dwvideoind .videorack-nav.backward .videorack-nav-icon{
    background: url("../../../../public/img/bullet/nav-backward.png") center center no-repeat; 
}
#dwvideoind .videorack-nav.forward .videorack-nav-icon{
    background: url("../../../../public/img/bullet/nav-forward.png") center center no-repeat; 
}
#dwvideoind .swiper-button-next, #dwvideoind .swiper-container-rtl .swiper-button-prev,
#dwvideoind .swiper-button-prev, #dwvideoind .swiper-container-rtl .swiper-button-next{
    background-image: none;
}
#dwvideoind .swiper-button-next, #dwvideoind .swiper-button-prev{
    margin-top: 0;
}
#dwvideoind .videorack-page{
    table-layout: fixed;
}
#dwvideoind .videorack-page td{
    max-width: 180px;
    border-left: 10px solid white;
    border-right: 10px solid white;
    vertical-align: top;
}
#dwvideoind .videorack-item{
    width: 100%;
    cursor:pointer;
    max-width: 207px;
}
#dwvideoind .videorack-item-hidden{
    visibility: hidden;
}
#dwvideoind .videorack-item-txt{
    margin-top: 6px;
    /*height: 68px;*/
    min-height: 68px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#dwvideoind .videoplayer{
    width: 100%;
    margin-bottom: 20px;
}
#dwvideoind .videoplayer-container{
    height: 0;
    width: 100%;
    padding-bottom: 39%;
    overflow: hidden;
    position: relative;
}
#dwvideoind iframe{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

#dwvideoind .videoinfo{
    /*max-width: 650px;*/
    width: 100%;
}
#dwvideoind .videoinfo-title{
    /* font-family: "HelveticaNeueMedium"; */
    margin-bottom: 10px;
}
#dwvideoind .videoinfo-p{
    margin-bottom: 20px;
}

#dwvideoind .tablist{
    position: relative;
    margin-top: 20px;
    margin-bottom: 10px;
}
#dwvideoind .tablist-item{
    width:100%;
    height:30px;
    display:table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid #E0E0E0;
    background-color:#EEF0F2;
    cursor:pointer;
}
#dwvideoind table{
    table-layout:fixed;
    width:100%
}
#dwvideoind table td{
    border-right:10px solid #fff;
    width: 50%;
}
#dwvideoind table td:last-child{
    border-right:0;
}
#dwvideoind .tablist-item-txt{
    display:table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
}
#dwvideoind .videolist-item{
    max-width: 320px;   
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    cursor: pointer;
}
#dwvideoind .videolist-item-txt{
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}
#dwvideoind .tablist .active .tablist-item{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}

#dwvideoind .text-bold{
    font-weight: bold;
}
#dwvideoind .preImg{
    position: absolute;
     cursor: pointer;
     top: 40%;
     left: 10px;
     transform: rotate(179deg);
      height: 75px;
}
#dwvideoind .nextImg{
    position: absolute;
     cursor: pointer;
     top: 40%;
     right: 10px;
      height: 75px;
}
#dwvideoind .slick-next{
    background-color: initial;
    height: 100%;
    right: 0;
    padding: 0;
    width: 65px;
    z-index: 100000;
}
#dwvideoind .slick-prev{
    background-color: initial;
    height: 100%;
    left: 0;
    padding: 0;
    width: 65px;
    z-index: 100000;
}
#dwvideoind .slick-prev:before ,#dwvideoind .slick-next:before {
    content: ''!important;
}
#dwvideoind .slick-arrow:hover {
    background-color: rgba(145, 145, 145, 0.5);
}
@media screen and (max-width: 992px){
    #dwvideoind .videorack-page td{
        max-width: 240px;
    }
    #dwvideoind .nextImg{
        right: 5px;
        height: 50px;
    }
    #dwvideoind .preImg{
          height: 50px;
          left: 5px;
    }
    #dwvideoind .slick-next,#dwvideoind .slick-prev {
        width: 40px;
    }
    
}
@media screen and (max-width: 767px){
    #dwvideoind .videoplayer-container{
        padding-bottom: 56%;
    }
}
