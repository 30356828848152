.question-header {
    text-align: center;
    height: 35px;
    line-height: 35px;
    background-color: #666;
}

.question-list {
    border: 1px solid #EAEAEA;
}

.question-list>div {
    background-color: #F5F6F7;
    margin: 10px;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.question-list>div a {
    color: #333;
    display: block;
}

.answer-area {
    padding-bottom: 30px;
}

.answer-area thead th {
    text-align: center;
}

.answer-area .content-question {
    /* font-family: "HelveticaNeueMedium"; */
    font-size: 17px;
    text-align: center;
    border-bottom: 1px solid #FECC0A;
    margin-top: 40px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.answer-area table {
    width: 80%;
    margin: auto;
}

.answer-area table td {
    text-align: center;
}

.question-list div {
    cursor: pointer;
}

.question-list img {
    margin-top: -3px;
    cursor: pointer;
    width: 22px;
    display: none;
}

.answer-area img {
    width: 22px;
    float: right;
    -ms-transform: rotate(270deg);
    /* IE 9 */
    -webkit-transform: rotate(270deg);
    /* Chrome, Safari, Opera */
    transform: rotate(270deg);
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .answer-area .content-question {
        font-size: 14px;
    }
}