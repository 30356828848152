#overnightmarkets .section-p{
    margin-bottom: 30px;
    min-height: inherit;
}
#overnightmarkets .newstbl-htxt{
    /* font-family: "HelveticaNeueLight"; */
    height: 30px;
    line-height: 30px;
    background-color: #CCC;
    padding-left: 10px;
}
#overnightmarkets .newstbl-row{
    position:relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #CCC;
}
#overnightmarkets .newstbl-content{
    margin-bottom:40px;
}
#overnightmarkets .newstbl-content .newstbl-row:last-child{
    border-bottom:1px solid #FFF;
}
#overnightmarkets .newstbl-col-right{
    margin-left:100px;
}
#overnightmarkets .newstbl-col-left{
    position:absolute;
    top:26px;
    left:0;
    text-align: left;
}
#overnightmarkets .newstbl-col-left span{
    /* font-family: "HelveticaNeueMedium"; */
    color: #0675C2;
}
#overnightmarkets .newstbl-col-dt{
    /* font-family: "HelveticaNeueMedium"; */
    margin:0;
    font-size: 16px;
    padding-top:8px;
    padding-bottom:8px;
    margin-bottom:6px;
}
#overnightmarkets .newstbl-col-dd{
    margin-bottom: 30px;
}
#overnightmarkets .newstbl-col-rm{
    text-align:right;
}
#overnightmarkets .newstbl-col-rm .btn{
    width: 150px;
}
#overnightmarkets .newstbl-bttm{
    position:relative;
    padding-left:40px;
    padding-right:40px;
    padding-top:8px;
    padding-bottom:8px;
    height: 32px;
}
#overnightmarkets .newstbl-cal-icon.input-group{
    position: absolute;
    top: -25px;
    right: 0px;
    display: inline-block;
    border-collapse: inherit;
}
#overnightmarkets .input-group-addon{
    text-align:right;
    padding:0;
    color: inherit;
    background-color: inherit;
    border: 0;
    border-radius: inherit;
    display:inline-block;
}
#overnightmarkets .newstbl .swiper-container {
    width: 100%;
    min-height: 300px;
} 
#overnightmarkets .newstbl .swiper-pagination{
    position: absolute;
    top: -8px;
    left: 35%;
}
#overnightmarkets .swiper-wrapper{
    margin-top: 0;
}
@media screen and (max-width:767px){
    #overnightmarkets .newstbl .swiper-pagination{
        left: 25%;
    }
}