

/*******************************************************************************
 overide bootstrap - page-header
*******************************************************************************/
.page-header .btn-primary, .page-header .btn-primary:hover, .page-header .btn-primary:focus {
    color: #333 !important;
    background-color: #FECC0A !important;
    border-color: #e4b709 !important;
}
.page-header .selectedTrade, .page-header .selectedTrade:hover, .page-header .selectedTrade:focus{
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

#pageHeader .tradeDropDown{
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 410px;
    padding: 10px 0;
}
#pageHeader .tradeDropDown a{
    margin-bottom: 5px;
}
#pageHeader p{
    font-size: 16px;
}
#pageHeader .dropdown-or{
    display: flex;
}
#pageHeader .dropdown-or hr{
    width: 40%;
    margin-top: 10px;
    margin-bottom: 0px;
}
#pageHeader .dropdown-or p{
    margin: 0 0 5px;
}
#pageHeader .tradeDropDown .btn-primary:hover, .tradeDropDown .btn-primary:focus{
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
#pageHeader .broker_link{
    width: 45%;
    margin-bottom: 5px;
    margin-right: 2px;
    margin-left
    : 2px;
}

/*******************************************************************************
 How to get started
*******************************************************************************/  


