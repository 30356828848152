#wcalbox {
    margin-bottom: 30px;
}

#wcalbox > table {
    table-layout: fixed;
    text-align: left;
    /*width:100%*/
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
}

#wcalbox > table tr td {
    /*padding:5px 30px 5px 0px;*/
    vertical-align: middle;
}

#wcalbox > table tr {
    height: 42px;
}

#wcalbox .wcalbox-row-lbl {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

#wcalbox .wcalbox-col-lbl {
    text-align: center;
    width: 215px;
    padding-left: 10px;
}

#wcalbox .wcalbox-col-slider {
    padding-left: 10px;
    padding-right: 10px;
}

#wcalbox .wcalbox-col-input {
    padding-left: 10px;
}

#wcalbox .wcalbox-row-ctr {
    height: 62px;
    padding-bottom: 20px;
}

#wcalbox .wcalbox-input {
    height: 100%;
    text-align: center;
    width: 100%
}

#wcalbox .slider.slider-horizontal {
    width: 100%;
    margin-top: -6px;
}

#wcalbox .slider.slider-horizontal .slider-track {
    height: 14px;
}

#wcalbox .slider-handle {
    top: 1px;
}

#wcalbox .resetbtn {
    text-align: right;
}

#wcalbox .resetbtn a {
    min-width: 100px;
}

#pstable {
    margin-bottom: 30px;
    border-bottom: 1px #CCCCCC dotted;
}

#pstable > table {
    table-layout: fixed;
    margin-bottom: 0;
}

#pstable th {
    width: 45%;
}

#srtable {
    margin-bottom: 20px;
}

#srtable > table {
    table-layout: fixed;
    margin-bottom: 0;
}

#srtable > table th, #srtable > table td {
    vertical-align: middle;
}

#srtable .srtable-leftlbl {
    width: 30%;
}

#srtable .srtable-leftval {
    width: 20%;
    border-right: 20px solid #FFFFFF;
}

#srtable .srtable-rightlbl {
    width: 30%;
    border-left: 20px solid #FFFFFF;
}

#srtable .srtable-rightval {
    width: 20%;
}

#ivtable th, #ivtable td {
    text-align: center;
}

#ivtable thead tr th {
    border-bottom: 0;
    background-color: #F5F6F7;
}

#ivtable th.active, #ivtable td.active {
    /*background-color: #CEE5F5;*/
    background-color: #ffffff;
}

.bold_title {
    /* font-family: "HelveticaNeueMedium"; */
}

.tip_box {
    background-color: #FECC0A;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.iv-slider-top {
    border: dashed 2px #FECC0A;
    border-bottom: none;
}

.iv-slider-bottom {
    border: dashed 2px #FECC0A;
    border-top: none;
}

.iv-slider-full {
    border: dashed 2px #FECC0A;
    margin-left: 4px;
    padding-left: 4px;
    padding-right: 4px;
    width: calc(33.3333% - 8px);
}

/*******************************************************************************
 calc section combobox
*******************************************************************************/
.combobox {
    position: relative;
}

.combobox-toggle {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 36px;
    padding: 0;
    cursor: pointer;
}

.combobox-toggle, .combobox-toggle:focus, .combobox-toggle:hover, .combobox-toggle:visited {
    color: #000;
}

.combobox-input {
    margin: 0;
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}

.combobox-toggle-icon {
    margin-top: 10px;
    margin-right: 12px;
}

.ui-widget {
    /* font-family: 'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif; */
    font-size: 14px;
}

.ui-menu .ui-menu-item {
    text-align: center;
}

.ui-menu .ui-menu-item a.ui-state-hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
}

.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
    color: #262626
}

.ui-autocomplete.ui-menu {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

#dropdown-calc {
    margin-top: 10px;
    margin-bottom: 30px;
}

/*******************************************************************************
 How to get started
*******************************************************************************/
#brochure .page-header {
    padding-top: 20px;
    border-bottom: 1px solid #1F90CE;
}

.tradeDropDown {
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 370px;
    padding: 10px 0;
}

.tradeDropDown a {
    margin-bottom: 5px;
}

.selectedTrade, .selectedTrade:hover, .selectedTrade:focus {
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

.tradeDropDown .btn-primary:hover, .tradeDropDown .btn-primary:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

.tradeDropDown .broker_link {
    margin-bottom: 5px;
    width: 45%;
}

/*******************************************************************************
 Responsive @media
******************************************************************************/
@media screen and (max-width: 767px) {
    #brochure .advert .swiper-wrapper {
        height: 0;
    }

    #pstable, #srtable, #ivtable {
        font-size: 11px;
    }

    #brochure .section5_box {
        flex-direction: column;
        padding: 5px;
        display: block !important;
        overflow: hidden;
    }

    #brochure .section5_text {
        margin: 0 auto;
    }

    #brochure .section5_link {
        text-align: center !important;
    }

    #brochure .section1 {
        border: 0 !important;
        padding-right: 0 !important;
    }

    #brochure .section2 {
        padding: 0 !important;
        background: rgb(240, 240, 240);
        padding-bottom: 20px !important;
        border: 0 !important;
        margin-bottom: 20px !important;
    }

    #brochure .section3 {
        padding: 0 !important;
        border: 0 !important;
        background: rgb(240, 240, 240);
        border-right: 0 !important;
        margin-bottom: 20px !important;
    }

    #brochure .WarrantLink {
        width: 50%;
    }

    #brochure .font_div {
        font-size: 33px !important;
    }

    #brochure .padding_0 {
        padding-right: 0 !important;
    }

    #brochure .fontDiv {
        font-size: 31px !important;
    }

    #brochure .margin_10 {
        margin-left: 10px !important;
    }

    #brochure .section4 {
        padding: 0 !important;
        background: rgb(240, 240, 240);
        border-left: 0 !important;
    }

    #brochure .section5_icon {
        width: 15% !important;
        float: left;
    }

    #brochure .brochure_p {
        line-height: 10px !important;
    }

    #brochure .section5_text_p {
        margin-left: 25px !important;
    }

    #brochure .section5_icon1 {
        margin: 0 !important;
        margin-left: 15px !important;
        margin-top: 15px !important;
        float: left;
    }

    #pstable th {
        width: 50%;
    }

    h3 {
        font-size: 16px;
    }

    #srtable .srtable-leftlbl {
        width: 24%;
    }

    #srtable .srtable-leftval {
        width: 26%;
        border-right: 0;
    }

    #srtable .srtable-rightlbl {
        width: 24%;
        border-left: 0;
    }

    #srtable .srtable-rightval {
        width: 26%;
    }

    #wcalbox .resetbtn {
        text-align: center;
    }

    #wcalbox .resetbtn a {
        width: 100%;
        height: 35px;
    }

    .iv-slider-full {
        width: calc(100% - 8px);
    }

    .tradeDropDown {
        width: 320px;
    }

    h1 {
        font-size: 18px;
    }
}

@media screen and (max-width: 1020px) {
    #brochure .section5_link {
        margin-left: 5px !important;
    }
}

@media screen and (max-width: 991px) {
    #wcalbox .wcalbox-row {
        margin-bottom: 8px;
    }

    #wcalbox .wcalbox-col {
        margin-bottom: 3px;
        text-align: center;
    }

    #wcalbox .slider.slider-horizontal .slider-track {
        height: 10px;
    }

    #wcalbox .slider-handle {
        width: 16px;
        height: 16px;
    }

    #wcalbox .wcalbox-input {
        width: 100%;
        height: 30px;
    }

    #wcalbox .row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .tradeDropDown {
        right: 20px;
    }
}

@media screen and (max-width: 410px) {
    #brochure .section5_link {
        font-size: 11px!important;
    }
    #brochure .LiveMatrixLink {
        font-size: 11px!important;
    }
    #brochure .WarrantLink {
        font-size: 11px!important;
    }
    #brochure .text_p {
        font-size: 14px!important;
    }
    #brochure .HSWT_3_Gearing {
        margin-left: 5px!important;
    }
}