
#SiMSCI p{
    word-wrap: break-word;
}
#SiMSCI .navbar-toggler.collapsed .glyphicon-menu-down:before {
    content: "∨";
    font-size: 20px;
}
#SiMSCI .navbar-toggler .glyphicon-menu-down:before {
    content: "∧";
    font-size: 20px;
    z-index: 111;
}
#SiMSCI  .tradeDropDown a,#SiMSCI  .tradeDropDown .dropdown-or{
    display: none;
}
#SiMSCI .hstech-hr{
    margin:5px 0px;
}
#SiMSCI .navbar{
    z-index: 1;
}
#SiMSCI .accordion h3{
    font-size:23px;
}
#SiMSCI #body-wrapper h2,#SiMSCI #body-wrapper h3{
    display: none;
}
#SiMSCI #dwselector .small{
    font-size:30px;
}
#SiMSCI .fixed-top{
    padding:15px!important;
}
#SiMSCI .fixed-top h3{
    font-size:18px;
}
#SiMSCI .videoplayer-container{
    height: 100%;
    padding-bottom: 0px;
}

@media screen and (max-width: 440px) {
    #SiMSCI .btn{
        font-size:12px!important;
        padding: 5px 2px;
    }
    #SiMSCI .btn_viewmore{
        font-size: 14px !important;
        padding: 6px 12px;
    }
    #SiMSCI  h2{
        font-size:25px!important;
    }
    #SiMSCI  #dwselector h1{
        font-size:25px!important;
    }

}
@media screen and (max-width: 980px) {
    #SiMSCI .page-header h3{
        font-size: 18px;
    }
    #SiMSCI .paddingRight{
        padding-right: 0px!important;
    }

}
