#subscribe p.small,
#subscribe h3 {
    color: white;
}

.bg-layer img {
    height: 100%;
}

.content-layer>.page-header {
    text-align: center;
    border: 0;
    display: flow-root;
}

.subh1 {
    font-size: 70px;
}

#subscribe .title_2 {
    font-size: 45px;
}

.title_3 {
    /* font-family: 'HelveticaNeueMedium'; */
    margin-top: 15px;
    font-size: 16px;
}

.title_4 {
    font-size: 16px;
}

.btn-closes {
    position: absolute;
    cursor: pointer;
    top: 8px;
    right: 5px;
    width: 20px;
    height: 20px;
}

#view-sample-btn {
    display: inline-block;
    width: 180px;
    font-size: 20px;
    padding: 2px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #0675C2;
    cursor: pointer;
}

#subscribe .section {
    position: relative;
    padding: 0;
    margin-bottom: 40px;
    overflow: hidden;
}

.content-layer {
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 40px;
    right: 40px;
    color: white;
}

.content-layer .tips-layer {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
}

.content-layer .tips-layer .row {
    margin-left: -15px;
    margin-right: -15px;
}

.content-layer .tips-layer .col-xs-12,
.content-layer .tips-layer .col-sm-4 {
    z-index: 100;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}


.tips-layer .tip {
    cursor: pointer;
}

.content-layer .tips-layer .col-sm-4>div {
    border-radius: 5px;
    background-color: white;
    height: 260px;
    padding: 20px 8px;
    text-align: center;
}

.content-layer .tips-layer .col-sm-6>div {
    border-radius: 5px;
    background-color: white;
    height: 260px;
    padding: 20px 8px;
    text-align: center;
}

.content-layer .tip-icon {
    height: 35%;
}

.content-layer .tip-icon img {
    height: 100%;
}

.content-layer .tip-item {
    color: #666666;
}

.content-layer .tip-item .tip-title {
    /* font-family: "HelveticaNeueMedium"; */
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 8px;
}

.shadow-layer {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    margin-bottom: -20px;
}

#subscribe .signup-layer {
    width: 530px;
    margin: auto;
    /*margin-top: 150px;*/
    /* margin-top: 15px; */
}

#subscribe .signup-layer p a {
    color: inherit;
    text-decoration: underline;
}

.title_email {
    font-size: 20px;
    padding: 4px 0;
}

#subscribe #email {
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: black;
}

.warning {
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}

#subscribe #submitBtn {
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
}

#subscribe input.agreechckbox {
    position: relative;
    top: 3px;
    margin-right: 4px;
}

.greatnewsletter {
    width: 100%;
    text-align: center;
}

.greatnewsletter.hidden-xs {
    margin-top: 105px;
}

#subscribe .signup-layer .page-header {
    margin: 0 0 20px;
}

.jCaptcha {
    color: #333333;
    width: 200px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .content-layer {
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }

    h1 {
        font-size: 50px;
    }

    .title_4 {
        font-size: 16px;
    }

    .content-layer .tips-layer {
        font-size: 14px;
        line-height: 1.2;
        width: 95%;
    }

    .content-layer .tips-layer .col-sm-4>div {
        height: 240px;
        padding: 14px 8px;
    }

    .shadow-layer {
        position: absolute;
        top: 45%;
        margin-bottom: -4px;
    }

    .greatnewsletter.hidden-xs {
        margin-top: 130px;
    }

    #subscribe .signup-layer {
        /*margin-top: 180px;*/
        margin-top: 15px;
    }
}

@media screen and (max-width: 767px) {
    .page-header .subh1 {
        font-size: 32px;
    }

    #subscribe .title_2 {
        font-size: 22px;
    }

    .title_3 {
        font-size: 14px;
    }

    .title_4 {
        font-size: 12px;
    }

    #view-sample-btn {
        font-size: 14px;
        padding: 6px;
    }

    #subscribe .section {
        /*height: 1340px;*/
        /*height: 1380px;*/
        height: 1460px;
        overflow: hidden;
    }

    .bg-layer.visible-xs-block img {
        width: auto;
        height: 100%;
    }

    .content-layer {
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }

    .content-layer .tips-layer .col-sm-4>div {
        height: 220px;
        padding: 10px 8px;
        max-width: 300px;
        margin: auto;
    }

    .content-layer .tip-item .tip-title {
        font-size: 18px;
        margin: 4px 0;
    }

    .shadow-layer {
        position: static;
        max-width: 300px;
        margin: auto;
        margin-top: 40px;
        padding: 20px 10px;
    }

    #subscribe .signup-layer {
        width: 100%;
        margin: 0;
    }

    #subscribe .page-header {
        margin-top: 0;
    }
}
.newpopup_image{
    position: fixed;
    top: 57px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: url(data:image/gif;base64,R0lGODlhIAAgAPcAAAAAAHNzc5SUlJaWlpiYmJmZmZycnKKioqSkpKWlpampqaurq62tra+vr7GxsbW1tbe3t7i4uLu7u76+vr+/v8LCwsbGxsjIyMrKysvLy87OztLS0tXV1dbW1tfX19nZ2dvb293d3d7e3uDg4OLi4uPj4+Tk5Obm5ufn5+np6erq6uvr6+zs7O3t7e7u7u/v7/Dw8PHx8fPz8/T09PX19fb29vf39/j4+Pr6+gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAgACAAAAj/AP8JHEiwIIEBHAoqXMhQ4IIAAQY0nCgQxQIDKAYqgCiAIAkTNigOZFCgAAONHAe2ECGChEiBGEoW+CBwY4CO/2iwFMHipUAEJQ/UTPnvBMsRPgWGkHnhn82OM3a6mJjigQWCDUoaePH0HwmWLge6AElQAgMGDjwITCFzQgSICVzslCFwxlcRJghCOHsWgssKJTf8EwHinwyWJ/7ZMLoTKcEMfM9WeKEhY0EaLm6s3CkiscIVFCILbviCMwm6DUc8OLtiok4RI6a+5FBCJIwUDGmg2M0bhQqfLlYIH77CxogOyJMjRz3xBmfOKY4rT868YY3nO1HEGIHds0gWvXmHnlzoYobtFDhe2mVJw/VR2bkZs3wxsfROEuYVbt554saL9pe9cAMLz50wnkB3gUXXZjD8c0MN/0TVmWLywUaQCe8JZMNOK8glQgseipCfDHflNRANJPw2EGNIrcBSRneFJdBYFEkowlQuipCRjfC9FKNAOVrGYlIhohakQK996BOGEwL54kAEtpSUCSMceORAHx2Y1EBXbklRCihoOVFAADs=) center center no-repeat;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .6);
}
.newpopup_image .newpopup_image_box{
    position: relative;
    height: 76%;
    overflow-x: hidden;
    overflow-y: auto;
}
.newpopup_image .newpopup_image_box::-webkit-scrollbar{
    width: 10px;
}