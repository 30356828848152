.answerA-item {
    background-color: #94edd1 !important;
    border-left: 1px solid #000;
}

.border {
    border-left: 1px solid #fff;
}

.question li {
    margin-top: 10px;
}

.question li span {
    font-size: 15px;
}

.question h1 {
    font-size: 30px;
}
.main-p p {
    padding-left:15px;
}
.main-p ul {
    list-style-type: decimal;
}

#popup-customized ul {
    max-width: 440px;
    padding: 25px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}

@media screen and (max-width: 695px) {
    .question li span:first-child {
        font-size: 12px;
        padding: 5px 10px !important;
    }

    .question h1 {
        font-size: 16px;
    }

    .question {
        padding-left: 5px !important;
        padding-right: 20px !important;
    }

    .inputWidth {
        width: 85% !important;
    }

    .question li span:last-child {
        font-size: 12px;
        padding: 5px 0px 5px 5px !important;
    }
}

/* .header {
    display: none;
}

.sharebtn {
    display: none;
}

.footer {
    display: none;
} */