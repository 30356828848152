.advert .swiper-wrapper{
    margin-top: 20px;
    height: 343px;
}
.advert .swiper-wrapper .swiper-pagination{
    position: absolute;
    bottom: 4%;
    right: 45%;
}
.advert .swiper-pagination li {
    width: 10px;
    height: 10px;
    margin: 0px 3px;
    opacity: 0.3;
}
.advert .swiper-pagination li.active{
    opacity: 1;
}