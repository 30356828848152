#indexdw .page-header{
    border-bottom: 0;
 }
 
 #indexdw .col-h{
     font-size: 36px;
 }
 
 #indexdw .row.row-fullw{
     margin-left: -40px;
     margin-right: -40px;
     padding-left: 30px;
     padding-right: 30px;
 }
 
 #indexdw .moreinfobtn{
     display: block;
     height: 35px;
     width: 220px;
     color: #000000;
     line-height: 35px;
     border: 1px solid #000000;
     text-decoration: false;
     font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd';
     text-align: center;
     cursor: pointer;
 }
 .newstbl-col-dt a{
    color:#000!important;
 }
 /*******************************************************************************
 For livematrix-row
 *******************************************************************************/
 #indexdw .imggrip{
    margin-top: 20px;
 }
 #indexdw .imggrip-col{
     position: relative;
     text-align: center;
     margin-bottom: 20px;
     cursor: pointer;
     padding-left: 0px;
     padding-right: 0px;
 }
 #indexdw #topimggrip > .slick-initialized{
     padding-left: 0px;
     padding-right: 0px;
     position: relative;
 }
 #indexdw #topimggrip > .slick-initialized > .slick-list{
    margin-right: -15px;
    margin-left: -15px;
 }
 #indexdw #topimggrip .slick-initialized ul.slick-dots{
     display: none !important;
 }
 #indexdw #topimggrip .slick-arrow{
    position: absolute;
    width: 65px;
    top: 0;
    left: 0px;
    height: calc(100% - 21px);
    background-color: rgba(146 146 146 / 0);
    transform: none;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 10;
 }
 #indexdw #topimggrip .slick-arrow.slick-next{
     left: inherit;
     right: 0px;
 }
 #indexdw #topimggrip .slick-arrow:hover{
    background-color: rgba(146 146 146 / 0.5);
 }
 #indexdw #topimggrip .slick-arrow.slick-prev::before{
     content: "";
     position: absolute;
     left: 50%;
     top:50%;
     transform: translateX(-50%);
     width: 16px;
     height: 26px;
     background-image: url(../../../../public/img/education/nav-backward.png); 
     opacity: 1;
 }
 #indexdw #topimggrip .slick-arrow.slick-next::before{
    content: "";
    position: absolute;
    left: 50%;
    top:50%;
    transform: translateX(-50%);
    width: 16px;
    height: 26px;
    background-image: url(../../../../public/img/education/nav-forward.png);
    opacity: 1;
}
 #indexdw #topimggrip .slick-slide{
    padding-left: 15px;
    padding-right: 15px;
 }
 #indexdw #topimggrip .slick-slide .imggrip-col{
     margin-bottom: 14px;
 }
 #indexdw .imggrip-col img{
    width: 100%;  
 }
 #indexdw .imggrip-col-txt{
     position: absolute;
     text-align: left;
     top:30%;
     bottom: 0;
     left:30px;
     right:30px;
     overflow: hidden;
 }
 #indexdw .imggrip-col-h{
     font-size: 24px;
     min-height: 56px;
     color: #FECC0A;
     margin-top: 20px;
 }
 #indexdw h3.imggrip-col-h{
    font-size: 20px; 
    margin-bottom: 9px;
 }
 
 #indexdw .imggrip-col-a{
     color: #FFFFFF;
 }
 

 /*******************************************************************************
 For livematrix-row
 *******************************************************************************/
 #indexdw .livematrix-row,
 #indexdw .livematrix-col .col-txtarea,
 #indexdw .livematrix-col.ipad-col{
     height: 304px;
     margin-bottom: 40px;
 }
 #indexdw .livematrix-col.ipad-col{
     display: flex;
     align-items: center;
 }
 #indexdw .livematrix-col.ipad-col .imggrip-col{
     width:50%;
     margin: auto;
 }
 #indexdw .livematrix-row{
     background: url('../../../../public/img/education/livematrix_bg.jpg') no-repeat center center;
     background-size: cover;
     width: auto;
 }
 #indexdw .livematrix-col .col-txtarea{
     width:100%;
 }
 #indexdw .livematrix-col .col-txtarea td{
      vertical-align: middle;
      padding: 0px;
 }
 #indexdw .livematrix-col .col-txtarea td h2.col-h{
    margin-top: 20px;
    margin-bottom: 10px;
 }
 #indexdw .livematrix-col .col-txtarea td p.col-p{
     margin-bottom: 10px;
 }
 #indexdw > .container-fluid > .section > p{
     margin-bottom: 10px;
 }
 #indexdw .whatis-imggrip .imggrip-col{
     max-width: 535px;
 }
 #indexdw #wtkm > .row{
     margin-left: -10px;
     margin-right: -10px; 
 }
 #indexdw #wtkm .imggrip-col{
     padding: 0px 10px;
 }
 /*******************************************************************************
 For indices-row 
 *******************************************************************************/
 #indexdw .indices-row{
     padding-top: 60px;
     padding-bottom: 60px;
     background-color: rgba(245, 246, 247, 1);
 }
 #indexdw .indices-row-inverse{
     background-color: #FFFFFF;
 }
 #indexdw .indices-top{
     position: relative;
     margin-bottom: 27px;
 }
 #indexdw .indices-top img{
     position: absolute;
     width: 60px;
     height: 60px;
     left: 10px;
     top: -3px;
 }
 #indexdw .indices-top .col-h{
     margin-left: 80px;
 }


 #indexdw .new-icon {
    position: absolute;
    right: 0;
    bottom: 10px;
    font-weight: bold;
    padding: 2px 15px;
    line-height: 1.42857143;
}



#indexdw .title_bg{
    background-color: #000;
    color: white;
}
#indexdw .grab_vouchers {
    font-size: 43px;
    line-height: 40px;
    margin-top: 10px;
    padding: 5px;
}
#indexdw .color-yellow {
    color: #FECC0A;
    font-weight: bold;
}
#indexdw .banner_400 {
    padding: 5px;
    font-size: 29px;
    line-height: 28px;
}
#indexdw .banner-right {
    padding:0px 5px;
    line-height: 1.42857143;
}
#indexdw .joinTitle {
    padding: 5px;
    font-size: 20px;
    line-height: 1.42857143;
}
#indexdw .joinSize {
    font-size: 15px;
    padding-left: 20px;
    list-style: decimal;
    list-style-position: outside;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1.42857143;
}
#indexdw .subscribe_btn {
    text-align: center;
    background-color: #FECC0A;
    color: black;
    height: 50px;
    border-radius: 2px;
    cursor: pointer;
    padding: 5px 0;
    margin: 5px 0;
    width: 195px;
}
.index-warrant.modal-dialog{
    max-width: 700px;
    margin: 25vh auto !important;
    height: auto;
    background: #000000;
    min-height: auto;
}
.index-warrant .popupbox-content{
    padding: 60px;
}
.index-warrant .btn-close{
    width: 16px;
    height: 16px;
    padding: 0px;
    opacity: 1;
}
.index-warrant .page-header{
    margin-top: 0;
    margin-bottom: 15px;
    border: 0;
    padding-bottom: 5px;
    display: block !important;
}
.index-warrant h1, .index-warrant p{
    color: white;
}
.index-warrant .hstech-hr {
    border: 1px solid #FECC0A;
    width: 100%;
    margin: 0;
    left: 0;
    opacity: 1;
}
.index-warrant input[type=text]{
    width: 100% !important;
    padding: 4px;
    margin: 0px !important;
    border: none;
}
.index-warrant .warning {
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
.index-warrant #submitBtn {
    font-size: 20px;
    font-weight: bold;
    color: #000000 !important;
    width: 100% !important;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    background-color: #FECC0A;
    border-radius: 10px !important;
    margin-top: 20px;
    margin-bottom: 0px !important;
}
.index-warrant p.small{
    margin-bottom: 10px;
}
 /*******************************************************************************
 For rating-row 
 *******************************************************************************/
 .slider-modal .rating-item{
    border: 1px solid #000000;
    padding: 10px 10px 0;
 }
 .slider-modal h4.rating-item-ttl{
    color: #333333;
    line-height: normal;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
 }
 .slider-modal .rating-item img{
     width: 60px;
     height: 60px;
 }
 .slider-modal .rating-img{
     height: 60px;
     display: flex;
     align-items: center;
     overflow: hidden;
 }
 .slider-modal .rating-img img{
    width: auto !important;
    margin: 0px !important;
    height: auto;
    max-height: 60px;
    max-width: 60px;
 }
 .slider-modal .rating-item p{
     color: #333;
     font-size: 16px;
 }
 .slider-modal .rating-item {
     height:100%;
 }
 .slider-modal .tw_table{
    margin-top: 10px;
 }
 .slider-modal .tw_warrant {
    background-color: #FFFFFF;
    padding: 10px;
 }
 .slider-modal .call_td .tw_type {
    padding: 2px 10px;
    text-align: center;
    background-color: #70C05F;
    border-radius: 5px;
    font-size: 18px;
    width: 66px;
 }
 .slider-modal .put_td .tw_type {
    padding: 2px 10px;
    text-align: center;
    background-color: #A91F2E;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 18px;
    width: 66px;
 }
 .slider-modal .popupbox-content .tw_warrant table td {
    text-align: left;
    padding: 0px 2px;
    font-size: 16px;
    line-height: 1.42;
}
.slider-modal .popupbox-content a.viewLM,
.slider-modal .popupbox-content a.viewC{
    display: inline-block;
    width: auto;
    float: right;
    margin-left: auto;
    transition: all 0.3s ease-in;
}
.slider-modal .popupbox-content a.viewLM.undefined,
.slider-modal .popupbox-content a.viewC.undefined{
    opacity: 0.5;
    pointer-events: none;
}
.slider-modal .popupbox-content a.viewLM > .vlmbtn,
.slider-modal .popupbox-content a.viewC > .vcbtn{ 
    color: #333333;
    position: relative;
    margin: 10px -2px 10px 10px;
    width: auto;
    bottom: inherit;
    height: auto;
    max-width: none;
    border: none;
    padding: 6.43px 12px;
}
.slider-modal h2.table-heading{
    font-size: 30px;
    margin-top: 10px;
}
.slider-modal table#warranttbl{
    border-collapse: collapse;
}
.slider-modal table#warranttbl tbody{
    border-top: none;
}
.slider-modal table#warranttbl tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
.slider-modal table#warranttbl tr:nth-of-type(even){
    background-color: #fff;
}
.slider-modal table#warranttbl tr td,
.slider-modal table#warranttbl tr th{
    font-size: 16px;
    padding: 8px;
    border:none;
    line-height: 1.42857143;
}
 
 /*******************************************************************************
 For popupbox (popup box)
 *******************************************************************************/
 .btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
.put_td .tw_type {
    padding: 2px 20px;
    background-color: #A91F2E;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 18px;
    width: 66px;
}
.popupbox-content .rating-item {
    border: 1px solid #000000;
    padding: 10px 10px 0;
}
.rating-img {
    height: 60px;
}
.ppc-h img, .rating-item img {
    width: 58px !important;
    height: 50px !important;
    margin-right: 10px;
}
.popupbox-content .rating-row [class*="col-"], .popupbox-content .rating-item {
    height: 100%;
}
.tw_warrant {
    background-color: #FFFFFF;
    padding: 10px;
}
.tw_title {
    display: flex;
    margin-top: 10px;
}
.call_td .tw_type {
    padding: 2px 20px;
    background-color: #70C05F;
    border-radius: 5px;
    font-size: 18px;
    width: 66px;
}
.tw_ticker {
    font-size: 16px;
    font-weight: bold;
    color: #DD9529;
    padding-left: 10px;
}
.vlmbtn, .vcbtn {
    color: #333333;
    position: relative;
    float: right;
    margin: 10px 10px 0;
}
#ppBoxHTML {
    display: block;
}
#ppBoxHTML1 {
    display: block;
}
#ppBoxHTML1 .mdHeight{
    height: 215px;
}
#warranttbl{
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    table-layout: fixed;
}
#warranttbl >thead>tr>th {
    font-weight: normal;
    line-height: normal;
    border-bottom: 1px solid #FECC0A;
}
#warranttbl td{
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    word-break: break-all;
}
#warranttbl >tbody tr:first-child {
    border-top: 1px solid #FECC0A;
}
#warranttbl >tbody>tr:nth-of-type(odd) {
    background-color: #F5F6F7;
}
 .slider-modal{
    width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    background-color: transparent !important;
    min-height: auto !important;
 }
 .slider-modal .modal-content,
 .slider-modal .modal-body{
    background-color: transparent;
    width: auto;
    margin: 0px auto;
    height: auto;
 }
 .slider-modal .overlay-inner{
     margin: 15vh auto;
     width: 100%;
     max-width: 1080px;
     height: auto;
     background: #F5F6F7;
 }
 .slider-modal .btn-close{
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
    padding: 0px;
    opacity: 1;
 }
 .slider-modal .noscroll{
     position:relative;
     overflow:hidden;
 }
 .slider-modal .popupbox-content {
    padding: 60px;
    background-color: transparent;
    color: #000;
 }
 .slider-modal .popupbox-content img{
     margin-left: auto;
     margin-right: auto;
     width: 100%;
 }
 .slider-modal .popupbox-content .ppc-h{
    margin-top: 0;
    text-transform: capitalize !important;
    font-size: 30px;
 }
 .slider-modal .popupbox-content p{
     color: #333;
     margin-bottom: 10px;
 }
 .slider-modal .popupbox-content .ppc-p:first-child{
     margin-top: 5px;
 }
 .slider-modal .popupbox-content .ppc-chart{
     margin-top: 40px;
     max-width: 800px;
 }
 .slider-modal .popupbox-content .ppc-table{
     max-width: 494px;
 }
 .slider-modal .popupbox-content table th,
 .slider-modal .popupbox-content table td{
    text-align: center; 
    padding-left: 2px;
    padding-right: 2px;
 }

.btm-modal-index.modal-dialog{
    max-width: 1080px;
    margin: 15vh auto;
    min-height: auto;
    height: auto;
}
.btm-modal-index .modal-body{
    background-color: transparent;
}
.btm-modal-index .overlay-inner{
    width: 100%;
    margin: 0px;
    background: #F5F6F7;
    padding: 60px;
}
.btm-modal-index .modal-content{
    border: none;
}
.btm-modal-index img.btn-close{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 16px !important;
    height: 16px;
    padding: 0px;
    color: #000;
    opacity: 1;
}
.btm-modal-index .overlay-inner > h2.ppc-h{
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 0px; 
}
.btm-modal-index .overlay-inner > h2{
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
}
.btm-modal-index .overlay-inner > p{
    margin-bottom: 10px;
}
.btm-modal-index .overlay-inner > ol{
    padding-left: 40px;
    margin-bottom: 10px;
}
.btm-modal-index .overlay-inner h5.text-bold{
    margin: 10px 0px;
}
.btm-modal-index .overlay-inner > ol li p{
    margin-bottom: 10px;
}
.btm-modal-index .overlay-inner img{
    width: 100%;
    max-width: 494px;
}

.btm-modal-index table#warranttbl{
    border-collapse: collapse;
    width: 100%;
}
.btm-modal-index #warranttbl tr td,
.btm-modal-index #warranttbl tr th{
    padding: 8px;
    text-align: center;
    font-size: 16px;
    color: #333333;
    line-height: 1.42857143;
    vertical-align: top;
}
.btm-modal-index #warranttbl tr th{
    border-bottom: 1px solid #FECC0A;
    font-weight: normal;
    font-family: "MCQGlobal_W_Md";
}
.btm-modal-index #warranttbl tr:last-child{
    border-bottom: 1px solid #FECC0A;
}
.btm-modal-index #warranttbl tbody tr:nth-of-type(even) {
    background-color: #FFFFFF;
}
#ppBoxHTML1 .mdPadding{
    padding: 0px;
}
 
#indexdw .signup-layer .page-header {
    margin: 0 0 20px;
}
#indexdw  #email {
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: black;
    margin:0;
}
#indexdw .signup-layer p a {
    color: inherit;
    text-decoration: underline;
}
#indexdw #submitBtn {
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
    margin:0;
}
 /*******************************************************************************
 r For responsive
 *******************************************************************************/

@media screen and (max-width: 1200px){
    #indexdw .subscribe_btn {
        width: 100%;
    }
 }


 @media screen and (max-width: 1024px){
    #topimggrip{
        overflow: hidden;
    }
    #indexdw .title_bg .banner-right .col-md-6:nth-child(2){
        padding-left: 10% !important;
    }
 }




 @media screen and (max-width: 992px){
    #ppBoxHTML1 .mdHeight{
        height: auto;
    }
    #ppBoxHTML1 .mdHeight .col-md-4{
        padding: 0px;
    }
    .tw_table{
        max-height: none;
    }
    /* #ppBoxHTML1  .call_table{
        height: 759px;
    } */
    #ppBoxHTML1  .mdPadding{
        padding: 0px 10px;
    }
    #ppBoxHTML1  .call_table{
        padding: 0px;
    }
     #indexdw .tablearea{
         margin-left: -10px;
         margin-right: -10px;
     }
     #indexdw .tablearea .inner_title{
         font-size: 28px;
     }
     #indexdw .tablearea p,.tablearea ul{
         font-size: 14px;
     }
     #indexdw .learnmore .learmore-title{
         font-size: 28px;
     }
     
     #indexdw .row.row-fullw {
         margin-left: -10px;
         margin-right: -10px;
         padding-left: 5px;
         padding-right: 5px;
     }
         
     #indexdw .imggrip-col-h{
         font-size: 18px;
         min-height: 42px;
     }
     #indexdw .imggrip-col-txt{
         left: 20px;
         right: 20px;
     }
     
     #indexdw .rating-item{
         position: relative;
         margin-bottom: 10px;
     }
     #indexdw .rating-item img{
         position: absolute;
         top: 10px;
         left: 10px;
     }
     /* #indexdw .rating-item-txtarea
     {
         margin-left: 70px;
     } */
     .overlay-inner{
         width: 90%;
     }
     .overlay-inner .popupbox-content{
         padding-left: 30px;
         padding-right: 30px;
     }
     #indexdw .title_bg .banner-right .col-md-6:nth-child(2){
        padding-left: 8% !important;
    }
    #indexdw .title_bg .col-md-6{
        width: 100% !important;
    }
    #indexdw .grab_vouchers {
        font-size: 30px;
        line-height: 30px;
        margin-top: 8px;
    }
    #indexdw .banner_400 {
        margin-top: 5px;
        font-size: 18px;
        line-height: 20px;
        padding: 0 5px;
    }
    #indexdw .banner-right {
        margin-top: 8px;
    }
    #indexdw .joinTitle {
        margin-top: 5px;
        font-size: 12px;
        padding: 0 5px;
    }
    #indexdw .banner-right .col-md-6.one{
        width: 50% !important;
    }
    #indexdw .subscribe_btn{
        width: 195px;
    }
 
 }
 @media screen and (max-width: 767px){
     #indexdw .tablearea .td-text{
         width:100%;
         padding: 20px 10px;
     }
     
     #indexdw .tablearea .altarea{
         background-color:#333;
     }
     #indexdw .tablearea .inner_title .lead{
         font-size: 42px;
     }
     #indexdw .tablearea .altarea p, #indexdw .tablearea .altarea ul, #indexdw .tablearea .altarea th, #indexdw .tablearea .altarea td{
         color: #fff;
     }
     #indexdw .tablearea .altarea th, #indexdw .tablearea .altarea td{
         font-size: 11px;
     }
     #indexdw .tablearea .altarea .table-striped-inverse>tbody>tr:nth-of-type(even) {
         background-color: #666;
     }
     #indexdw .learnmore{
         margin-left: -10px;
         margin-right: -10px;
     }
     #indexdw .learnmore .learmore-title{
         font-size: 24px;
     }
     #indexdw .tablearea a{
         display: block;
         width:auto;
         height: 33px;
         background-color: #666;
         border: none;
         line-height: 33px;
         margin-top: 20px;
     }
     #indexdw .learnmore a{
         margin-left: 10px;
         margin-right: 10px;
         width: auto;
     }
     
     #indexdw .moreinfobtn{
         width: 100%;
     }
     
     #indexdw .imggrip.row{
         margin-left: -5px;
         margin-right: -5px;
     }
     #indexdw .imggrip-col{
         padding-left: 5px;
         padding-right: 5px;
         margin-bottom: 10px;
     }
     #indexdw .imggrip-col-txt{
         top: 35%; 
     }
     
     #indexdw .livematrix-col .col-txtarea td{
         vertical-align: top;
     }
     #indexdw .indices-right{
         margin-top: 10px;
     }
     #indexdw .indices-right .col-p{
         margin-bottom: 20px;
     }
     #indexdw .indices-row-inverse .moreinfobtn{
         margin-bottom: 20px;
     }
     
     #indexdw .livematrix-col.ipad-col{
         display:none;
     }
     table#warranttbl,table#sp500warranttbl,table#a50warranttbl,table#hsiwarranttbl,table#whstecharranttbl,table#klciwarranttbl{
         font-size: 14px;
     }
     #indexdw #topimggrip .slick-arrow{
        max-width:50px;
     }
     #indexdw .new-icon{
        right: 10px;
     }
     #indexdw .title_bg .col-md-6:nth-child(1){
        height: 12.5rem !important;
    }
    #indexdw .grab_vouchers {
        font-size: 20px;
        line-height: 20px;
        margin-top: 5px;
    }
    #indexdw .banner_400 {
        margin-top: 4px;
        font-size: 14px;
        line-height: 14px;
    }
    #indexdw .banner-right .one .sharebtn-tl {
        margin-left: 5px !important; 
        margin-top: 5px;
    }
    #indexdw .banner-right .col-md-6.one{
        height: inherit !important;
        width: 40% !important;

    }
    #indexdw .banner-right .one:nth-child(2){
        width: 60% !important;
    }
    #indexdw .title_bg .banner-right .col-md-6:nth-child(2){
        padding-left: 0px !important;
    }
    #indexdw .livematrix-row .livematrix-col, #indexdw #wtkm .row .imggrip-col{
        width: 100% !important;
    }
    #indexdw #wtkm .imggrip-col{
        padding:0px 5px !important;
    }
    #indexdw .livematrix-col .col-txtarea td p.col-p, #indexdw .moreinfobtn {
        font-size: 14px;
    }
    
    
 }
 
 
 @media screen and (max-width: 400px){
     #indexdw .imggrip-col-txt{
         top: 20%; 
     }
     #indexdw .imggrip-col-h{
         font-size: 14px;
         margin-bottom: 0px;
         min-height: 31px;
     }
     #indexdw .imggrip-col-a{
         font-size: 10px;
     }
     #indexdw #topimggrip .slick-slide{
        padding: 3px;
     }
     #indexdw .subscribe_btn{
        max-width: 178px;
     }
     #indexdw .banner-right .col-md-6.one{
        padding-right: 0px;
     }
     #indexdw #topimggrip .slick-arrow{
        top: 3px;
        left: 0px;
        height: calc(100% - 26px);
     }
 }
 
 @media (min-width: 768px){
     #indexdw .col-sm-5th {
         width: 20%;
     }
 }
