body {
    margin: 0;
    padding: 0;
    color: #fff;
}
.InvestorAcademy .panel-collapse>div {
    max-height: revert!important;
    overflow-y: auto;
}


.InvestorAcademy .imgholder img {
    max-width: 180px;
    /* max-height: 186px; */
}

.InvestorAcademy .mytop {
    position: fixed;
    display: flex;
    justify-items: start;
    width: 100%;
    background-color: black;
    border-bottom: 1px solid #575757;
    max-height: 70px;
    z-index: 9999999999;
}

.InvestorAcademy .imgholder {
    text-align: center;
    width: 100%;
    padding: 10px 0 20px;
}

.InvestorAcademy .imgholder img {
    width: 100%;
}

.InvestorAcademy .top-line {
    width: 2px;
    height: 30px;
    float: left;
    background-color: #FFFFFF;
    margin: 15px 12px 15px 6px
}

.InvestorAcademy .top-menu {
    padding: 20px 15px 20px 25px;
    right: 0px;
    position: absolute;
}

.InvestorAcademy .mytop span {
    height: 60px;
    color: #FFD843;
    line-height: 58px;
    float: left;
    /* font-family: thincond; */
    font-size: 27px;
    letter-spacing: 1px !important;
    font-weight: 100;
}


/**  尾部  **/

.InvestorAcademy .my-footer {
    position: fixed;
    width: 100%;
    bottom: 0px;
    min-height: 46px;
    background-color: rgb(16, 16, 16);
    z-index: 99;
    font-size: 11px;
    color: #8f8f8f;
    line-height: 36px;
    padding-bottom: 10px;
    z-index: 99999999;
}

.InvestorAcademy .my-footer>div {
    display: inline-block;
}


.InvestorAcademy .footer-left {
    left: 10px;
    top: 15px;
    position: absolute;
}

.InvestorAcademy .footer-left .prve {
    width: 23px;
    height: 23px;
    /* background: url(../../../public/img/investoracademy/prev.png); */
    background: url('../../../public/img/investoracademy/prev.png');
    cursor: pointer;
}

.InvestorAcademy .my-footer .footer-center {
    display: block;
    margin: 0 auto;
    /* position: absolute;
    left: 32%; */
    text-align: center;
}

.InvestorAcademy .footer-right {
    right: 10px;
    position: absolute;
    top: 15px;
}

.InvestorAcademy .footer-right div {
    float: right !important;
}

.InvestorAcademy .footer-right a>a {
    margin-right: 5px !important;
}

.InvestorAcademy .footer-right .next {
    width: 23px;
    height: 23px;
    /* background: url(../../../public/img/investoracademy/next.png); */
    background: url('../../../public/img/investoracademy/next.png');
    cursor: pointer;
}

.InvestorAcademy .foot-ul {
    position: relative;
    top: -21px;
    z-index: 10;
    display: inline-block;
}

.InvestorAcademy .foot-ul a,
.InvestorAcademy .foot-ul a:focus,
.InvestorAcademy .foot-ul a:hover,
.InvestorAcademy .foot-ul a:visited {
    color: #8f8f8f;
}

/**  右侧栏  **/

.InvestorAcademy #dotbar {
    position: fixed;
    height: 334px;
    width: 50px;
    right: 0px;
    top: 50%;
    margin-top: -137px;
    z-index: 99;
}

.InvestorAcademy .homebtn {
    cursor: pointer;
    width: 18px;
    height: 16px;
    /* background: url(../../../public/img/investoracademy/home.png); */
    background: url('../../../public/img/investoracademy/home.png');
    margin-bottom: 15px;
}

.InvestorAcademy .dotbtn {
    cursor: pointer;
    width: 14px;
    height: 14px;
    /* background: url(../../../public/img/investoracademy/dot.png) center center no-repeat; */
    background: url('../../../public/img/investoracademy/dot.png') center center no-repeat;
    margin-bottom: 15px;
    margin-left: 2px;
}

.InvestorAcademy .dotbtn.selected {
    /* background: url(../../../public/img/investoracademy/dotactive.png) center center no-repeat !important; */
    background: url('../../../public/img/investoracademy/dotactive.png') center center no-repeat !important;
}


/**   investormy.html    **/

.InvestorAcademy .my-main {
    width: 100%;
    /* overflow: auto; */
    position: absolute;
    padding-top: 250px;
    font-size: 14px;
    z-index: 999999;
    text-align: center;
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
    /*  */
}
.InvestorAcademy .color_0675C2{
    color: #0675C2 !important;
}
.InvestorAcademy .main-bg {
    /* background-image: url("../../../public/img/investoracademy/MacWarrantsSite-bg.jpg"); */
    background-image: url("../../../public/img/investoracademy/MacWarrantsSite-bg.jpg");
    background-size: 100% 100%;
    background-position: center center;
}

.InvestorAcademy .imgbg {
    position: fixed;
    /* background-color: #1F1F1F; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: .1;
    z-index: 99999;
    opacity: 1;
}

.InvestorAcademy .bodygbg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../../public/img/investoracademy/bg_video_box_75.png') repeat 0 0;
    z-index: 999999;
    /* position: fixed;
    z-index: 9999999;
    background-color: #1F1F1F;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    z-index: 1; */
}

.InvestorAcademy .main {
    max-width: 800px;
    /* height: 450px; */
    margin: auto;
    padding-bottom: 300px;
}

.InvestorAcademy .main>div {
    display: inline-block;
}

.InvestorAcademy .main-img {
    background-color: #303030;
    height: 144px;
    width: 235px;
    margin-right: 3px;
    /* margin-bottom: 3px; */
    background: url('../../../public/img/investoracademy/bg_video_box_75.png') repeat 0 0;
}

.InvestorAcademy .main-img:hover {
    background-color: #010101;
}

.InvestorAcademy .resizable {
    height: 144px;
    width: 235px;
}

.InvestorAcademy .resizable1 {
    background: url('../../../public/img/investoracademy/1.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable2 {
    background: url('../../../public/img/investoracademy/2.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable3 {
    background: url('../../../public/img/investoracademy/3.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable4 {
    background: url('../../../public/img/investoracademy/4.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable5 {
    background: url('../../../public/img/investoracademy/5.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable6 {
    background: url('../../../public/img/investoracademy/6.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable7 {
    background: url('../../../public/img/investoracademy/7.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable8 {
    background: url('../../../public/img/investoracademy/8.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable9 {
    background: url('../../../public/img/investoracademy/9.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable10 {
    background: url('../../../public/img/investoracademy/10.png') 0% 0% / 100%;
}

.InvestorAcademy .resizable11 {
    background: url('../../../public/img/investoracademy/11.png') 0% 0% / 100%;
}


/**      **/

.InvestorAcademy .domain>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.InvestorAcademy #pagetitlediv2 {
    /* width: 370px; */
    height: 100%;
    margin: auto;
    margin-bottom: 30px;
}

.InvestorAcademy #ptitle {
    /* font-family: thincond; */
    font-size: 18px!important;
    letter-spacing: 1px;
    cursor: pointer
}

.InvestorAcademy #pname2 {
    /* font-family: thincond; */
    font-size: 30px;
    /* color: #FFD843; */
    letter-spacing: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
}

.InvestorAcademy .showtext2 {
    font-size: 14px;
    margin: 10px 0;
}

.InvestorAcademy .showtile2 {
    /* color: #FFD843; */
    text-align: center;
    margin: 20px 0;
}

.InvestorAcademy .hexmenu .imgmask {
    position: relative;
    display: inline-block;
    margin: auto;
}

.InvestorAcademy .hexmenu .hexbg {
    min-width: 200px;
    max-width: 480px;
    width: 100%;
}

.InvestorAcademy .hexmenu .imgmask .hexarea {
    position: absolute;
    display: block;
}

.InvestorAcademy .hexmenu .imgmask .hexarea .topictxt {
    display: table;
    width: 100%;
    height: 100%;
}

.InvestorAcademy .hexmenu .imgmask .hexarea a {
    color: #000;
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    /* font-weight: 600; */
}

.InvestorAcademy .imgholder {
    text-align: center;
    width: 100%;
    padding: 10px 0 20px;
}

.InvestorAcademy .my-h2 {
    color: #FFDA44;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.InvestorAcademy .contentbox {
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.InvestorAcademy .flex_row{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0 10px 0;
}
.InvestorAcademy .flex_row h3{
    margin: 0;
}
.InvestorAcademy p.disclaimer {
    color: red;
    font-size: 0.8em;
    text-align: center;
}

.InvestorAcademy .contentbox p.disclaimer {
    color: red;
    font-size: 0.8em;
    text-align: center;
}
.Playlist_img{
    padding-bottom: 5px;
    width: 100%;
     height: 435px;
}
/* p a {
    color: #FE3200;
    text-decoration: none;
} */
.InvestorAcademy .videoplayer {
    height:200px;
}
.InvestorAcademy .imgbg {
    position: fixed;
    background-color: #1F1F1F;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url(../img/MacWarrantsSite-bg.jpg) no-repeat center center; */
    background: url(../../../public/img/education/MacWarrantsSite-bg.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}


.InvestorAcademy .tipholder {
    width: 100%;
    padding: 10px 0 20px 0;
}

.InvestorAcademy .tipbox {
    max-width: 800px;
    margin: 0 auto;
    border: 8px solid #363636;
    padding: 0 16px 0 0;
}

.InvestorAcademy .tipbox h2 {
    color: #FFDA44;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.InvestorAcademy .imgholder img {
    max-width: 600px;
}

.InvestorAcademy .imgholder {
    text-align: center;
    width: 100%;
    padding: 10px 0 20px;
}

.InvestorAcademy .contentbox p {
    text-align: justify;
    color:#000;
}

.InvestorAcademy .contentbox h2 {
    color: #FFDA44;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.InvestorAcademy .subtitle {
    height: 46px;
    line-height: 46px;
    width: 46px;
    background: url('../../../public/img/education/yellow01.png') no-repeat left center;
    margin-right: 34px;
}

.InvestorAcademy .subtitle span {
    display: inline;
    margin: 0px 34px 0px 17px;
    color: #000000;
    font-size: 1.5em;
}

.InvestorAcademy .subtitle h3 {
    display: inline;
}

.InvestorAcademy .contentbox table {
    margin: 0 auto;
    border-spacing: 2px;
    border-collapse: separate;
    padding: 10px 0 20px 0;
}

td,
th {
    padding: 0;
}

.InvestorAcademy .contentbox table.ruletable tbody td {
    height: 3em;
    width: 30%;
    color: #000000;
}

.InvestorAcademy .contentbox table.ruletable tbody td.col2 {
    background-color: #FFFF80;
    text-align: center;
}

.InvestorAcademy .contentbox table.ruletable tbody td.col3 {
    background-color: #FFD400;
    text-align: center;
}

.InvestorAcademy .contentbox table.ruletable tbody td.col1 {
    background-color: #FFF4D2;
    text-align: left;
    padding-left: 1em;
}

.InvestorAcademy .formulaholder {
    margin: 0 auto;
    max-width: 800px;
    min-width: 200px;
    text-align: center;
    /* padding: 0px 0px 20px 20px; */
}

.InvestorAcademy .formulabox {
    padding: 1em;
    display: inline-block;
    /* text-align: center; */
    /* background-color: #404040; */
}

.InvestorAcademy .formulabox p {
    font-size: 1em;
    margin: 0;
}

.InvestorAcademy .formulabox p span.leqt {
    color: #FFDA44;
}

.InvestorAcademy .formulabox .leqt,
.InvestorAcademy .formulabox .reqt {
    display: inline-block;
}

.InvestorAcademy .formulabox .fraction .upper_line {
    border-top: 1px solid #000;
}

.InvestorAcademy .edulist li {
    /* background-image: url(../../../public/img/investoracademy/nswCH.png); */
    background-position: -5px;
    background-repeat: no-repeat;
    padding-left: 7px;
}

.InvestorAcademy .edulist .ttl {
    font-size: 16px;
}

.InvestorAcademy .edulist p {
    text-align: left;
}

.InvestorAcademy .txtbox {
    max-width: 60%;
    margin: 0 auto;
    padding: 1em;
    background: rgb(56, 56, 56);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQ1NDg0ZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(56, 56, 56, 1)), color-stop(100%, rgba(0, 0, 0, 1)));
    background: -webkit-linear-gradient(top, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -o-linear-gradient(top, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -ms-linear-gradient(top, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to bottom, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#868686', endColorstr='#000000', GradientType=0);
}

.InvestorAcademy .txtbox h2,
.InvestorAcademy .txtbox p {
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

.InvestorAcademy .exampleholder {
    margin: 0 auto;
    max-width: 600px;
    min-width: 200px;
    padding: 10px 0px 20px 0px;
}

.InvestorAcademy .exampleholder .examplebox {
    display: inline-block;
    border: 4px solid #404040;
    /* background-color: #000000; */
    padding: 16px;
    width: 100%;
}

.InvestorAcademy .exampleholder .examplebox .col {
    float: left;
    width: 100%;
    margin: 2px 0;
}

.InvestorAcademy .exampleholder .examplebox .lbl,
.InvestorAcademy .exampleholder .examplebox .val {
    float: left;
}

.InvestorAcademy .exampleholder .examplebox .lbl {
    color: #FFDA44;
}

.InvestorAcademy .listholder {
    margin: 0 auto;
}

.InvestorAcademy .imgholder h2 {
    text-align: left;
    margin: 0 auto 6px auto;
    max-width: 600px;
    width: 100%;
}
.InvestorAcademy .eduhtmlBtnLeft{
    position: fixed;
    top: 50%;
    left: 6%;
    width: 100px;
    height: 100px;
    z-index: 9999999;
}
.InvestorAcademy .eduhtmlBtnRight{
    position: fixed;
    top: 50%;
    right: 6%;
    width: 100px;
    height: 100px;
    z-index: 9999999;
}
.InvestorAcademy .eduhtmlBtnLeft .prve{
    /* width: 100%;
    height: 100%; */
    /* background: url('../../../public/img/investoracademy/prev.png'); */
    cursor: pointer;
    font-size: 130px;
    color:yellow;
    /* background-size: 100% 100%; */
}
.InvestorAcademy .eduhtmlBtnRight .next{
    /* width: 100%;
    height: 100%; */
    /* background: url('../../../public/img/investoracademy/next.png'); */
    cursor: pointer;
    font-size: 130px;
    /* background-size: 100% 100%; */
    color:yellow;
}
.InvestorAcademy .main{
    width: '955px' 
}
@media screen and (max-width: 768px) {
    .InvestorAcademy .mytop span {
        display: none;
    }
    .InvestorAcademy .eduhtmlBtnLeft{
        left:0%;
    }
    .InvestorAcademy .eduhtmlBtnRight{
        right: 0%;
    }
    .InvestorAcademy .main{
        width: 100%;
    }
    .InvestorAcademy .main-img {
        height: 59px;
        width: 94px;
    }
    .InvestorAcademy .resizable {
        height: 57px;
        width: 99px;
    }
    .InvestorAcademy .foot-ul-top{
        line-height: normal;
    }
    .InvestorAcademy .foot-ul {
        top: 0px;
        display: block;
    }
}


body {
    background-color: black
        /*background-color:#151515;*/
        /*background-color:#1F1F1F;*/
}
/* -------------------------------------------- */
.InvestorAcademy .mobilemenu .menuitem {
    position: relative;
    margin: 10px auto;
    width: 100%;
    max-width: 360px;
    height: 60px;
    background-color: #FFC000;
    border-radius: 10px;
    display: table;
}
.InvestorAcademy .mobilemenu .menuarrow {
    position: absolute;
    margin-top: -18px;
    right: 12px;
    height: 36px;
    top: 50%;
}
.InvestorAcademy .mobilemenu .menuicon {
    position: absolute;
    height: 50px;
    left: 12px;
    top: 50%;
    margin-top: -25px;
}
.InvestorAcademy .mobilemenu .menutitle {
    position: absolute;
    top: 50%;
    /* left: 30%; */
    left: 80px;
    right: 50px;
    font-size: 16px;
    line-height: 16px;
    margin-top: -9px;
}
.InvestorAcademy .mobilemenu .menuitem a {
    text-decoration: none;
    color: #000;
}
@font-face {
    font-family: 'thincond';
    /* src: url('../../../public/fonts/HelveticaNeueLT-ThinCond/'); */
    src: url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-ThinCond.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-ThinCond.otf') format('opentype'),
        url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-ThinCond.woff') format('woff'),
        url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-ThinCond.ttf') format('truetype'),
        url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-ThinCond.svg#thincond') format('svg');
    font-weight: normal;
    font-style: normal;
    letter-spacing: 1px
}

@font-face {
    font-family: 'roman';
    /*src: url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-Roman.ttf');*/
    src: url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-Roman.eot?#iefix') format('embedded-opentype'),
        /*url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-Roman.otf')  format('opentype'),*/
        url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-Roman.woff') format('woff'),
        url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-Roman.ttf') format('truetype'),
        url('../../../public/fonts/HelveticaNeueLT-ThinCond//HelveticaNeueLT-Roman.svg#roman') format('svg');
    font-weight: normal;
    font-style: normal;
    letter-spacing: 1px
}
.InvestorAcademy .alert{
    margin-bottom: 5px!important;
    cursor: pointer;
    background: #E0E0E0;
    border-bottom: 1px solid yellow;
    text-align: left;
    display: flex;
}
.InvestorAcademy .panel-heading{
    background: #DFDBCF!important;
    text-align: left;
}
.InvestorAcademy .active{
    background: #FFD834!important;
}
.InvestorAcademy{
    /* text-align: left!important; */
}