.tblh{
    position:relative;
    margin-top:20px;
    height:40px;
    margin-bottom:10px;
}
.tblh-left{
    position: absolute;
    left:0;
    bottom:0;
}
.tblh-right{
    position:absolute;
    right:0;
    bottom:0;
}
.tblh h2.small{
    /* font-family: 'HelveticaNeueMedium'; */
    font-size: 18px;
    font-weight: normal;
    margin:0;
}
.lastupdatebox{
    font-size: 12px;
    float:right;
}
.tbl th, .tbl td{
    text-align: center;
    vertical-align: middle;
     padding: 8px 2px;
}
.tbl th:nth-child(1){
    min-width: 120px;
}
.tbl tr td{
    white-space: nowrap;
}
.chartbox h2.small{
    /* font-family: 'HelveticaNeueMedium'; */
    font-size: 18px;
    font-weight: normal;
    margin:0;
    text-align: center;
}
.chartbox-bars{
    height:370px;
    width:100%;
}
.chartbox .legend{
    text-align: center;
    padding: 30px
}
.chartbox .legend-item{
    display: inline-block;
    position: relative;
}
.chartbox .legend-item-box{
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
}
.chartbox .legend-item-txt{
    white-space: nowrap;
    line-height: 18px;
    margin-left: 28px;
    margin-right: 20px;
    margin-bottom: 5px;
}
.chartbox .legend-item-box.index{
    background-color: #DD5C10;
}
.chartbox .legend-item-box.stock{
    background-color: #F7941E;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 767px){    
    .tbl th{
        font-size: 11px;
        width: 25% !important;
    }
    .tbl td{
        font-size: 11px;
    }
    .tbl th:nth-child(1){
        min-width: 60px;
    }
    .tbl th, .tbl td{
        padding: 8px 2px !important;
    }
    .chartbox .legend{
        text-align: left;
    }
    .turnoverTable th,
    .turnoverTable td{
        min-width: 105px !important;
    }
}
@media screen and (max-width: 490px){    
    .chartbox-bars{
        width:490px;
    }
}