#pagesearch .pagesearch-top {
    background-color: #ebecec;
    margin-top: 80px;
}
#pagesearch .pagesearch-top .container-fluid {
    padding-top: 16px;
    padding-bottom: 16px;
}
.container-fluid {
    padding-right: 10px;
    padding-left: 10px;
}
.container-fluid {
    max-width: 1200px;
}
#pagesearch .psearchbox {
    max-width: 480px;
}
#pagesearch .psearchbox input {
    font-size: 24px;
    height: 48px;
    text-align: left;
}
#pagesearch .psearchbox .btn {
    font-size: 24px;
}
#pagesearch .psearchbox .glyphicon {
    color: #CCC;
}
#pagesearch .pagesearch-body {
    background-color: #ffffff;
}
#pagesearch .search-resultbox {
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 300px;
}
#pagesearch .search-result {
    padding: 16px 0;
    border-bottom: 1px solid #cccccc;
}
#pagesearch .search-result a {
    color: #00a1e0;
    text-decoration: none;
}
#pagesearch .search-result-title {
    font-size: 18px;
}
#pagesearch .search-resultbox .nomatch {
    text-align: center;
}