#document .tablist {
    position: relative;
    height: 60px;
    margin-top: 20px;
}

#document .tablist-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#document .tablist-right {
    position: absolute;
    right: 0;
    bottom: 0;

}

.dataListClass p:hover {
    background: #3B86E8 !important;
}

#document .tablist-left table {
    table-layout: fixed;
}

#document .tablist-left table td {
    border-right: 10px solid #fff;
}

#document .tablist-left table td:last-child {
    border-right: 0;
}

#document .tablist-item {
    width: 130px;
    height: 55px;
    display: table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5px solid #E0E0E0;
    background-color: #EEF0F2;
    cursor: pointer;
}

#document .tablist-item-txt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
}

#document .tablist .active .tablist-item {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}

#document .tablist .tablist-item:focus,
#toptradewarrant .tablist .tablist-item:hover {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}

#document .tabcontent a img {
    border: 0
}

#document .tabcontent.tableSize th,
#document .tabcontent.tableSize td {
    font-size: 16px;
}

/*******************************************************************************
 Base propectus
******************************************************************************/

/*******************************************************************************
 termsheet
******************************************************************************/
#document #termsheet table th,
#document #termsheet table td {
    text-align: center;
}

/*******************************************************************************
 notices
******************************************************************************/
#document #notice .notice-p {
    padding-top: 15px;
}

#document #notice tr .date {
    white-space: nowrap;
}

/*******************************************************************************
 swiper
******************************************************************************/
.doctbl .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: url(../../../../public/img/bullet/page-marker-active.png) 0 0 no-repeat;
    background-size: contain;
}

.doctbl .swiper-pagination-bullet {
    cursor: pointer;
    width: 16px;
    height: 16px;
    opacity: inherit;
    border-radius: inherit;
    margin-left: 2px;
    margin-right: 2px;
    background: url(../../../../public/img/bullet/page-marker.png) 0 0 no-repeat;
    background-size: contain;
}

#document .doctbl .swiper-pagination {
    position: inherit;
}

#document .doctbl-bttm {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 32px;
    margin-bottom: 20px;
}

/*******************************************************************************
 Responsive @media
******************************************************************************/
@media screen and (max-width: 767px) {

    #document .table td,
    #toptradewarrant .table th {
        font-size: 11px;
        padding-left: 3px !important;
        padding-right: 3px !important;
        /*        white-space: nowrap;*/
    }

    #document .tablist-left table {
        width: 100%;
    }

    #document .tablist-item {
        width: 100%;
    }

    #document .tablist-item-txt {
        font-size: 12px;
    }

    #document #topTabs ul li {
        width: 32.5%;
    }
}

@media screen and (max-width: 330px) {
    #document .tablist-item-txt {
        font-size: 10px;
    }

    #document .table td,
    #document .table th {
        font-size: 9px;
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

}