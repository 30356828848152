#wheretotrade .broker-table-holder{
    background: #ffffff;
    font-weight: normal;
    overflow-x: auto;
}
#wheretotrade .broker-table th,
#wheretotrade .broker-table td{
    text-align: center;
    vertical-align: middle;
}
#wheretotrade .broker-table td{    
    padding-top: 16px;
    padding-bottom: 16px;
}
#wheretotrade .broker-table .broker-td-logo img{
    width: 120px;
}
#wheretotrade .broker-table .broker-td-tick{
    background: url('../../../../public/img/education/broker_tick.png') center center no-repeat;
}
#wheretotrade .broker-td-contact a,
#wheretotrade .broker-td-email a{
    display: inline-block;
/*    padding: 10px*/
}
#wheretotrade .broker-table .broker-phone{
    background: url('../../../../public/img/bullet/icon_phoneto.png') center center no-repeat;
    display:block; 
    width: 12px; 
    height: 12px; 
}
#wheretotrade .broker-table .broker-email{
    background: url('../../../../public/img/bullet/icon_mailto.png') center center no-repeat;
    display:block; 
    width: 17px; 
    height: 12px; 
}
#wheretotrade .broker-table .broker-td-contact,
#wheretotrade .broker-table .broker-td-email
{
    white-space: nowrap;
}
#wheretotrade .broker-table{
    margin-bottom: 0;
}
.top-pointer{
    display: flex;
    font-size: 22px;
    padding-left: 0px;
    padding-right: 0px;
}
.top-pointer:first-child{
    padding-left: 10px;
}
.top-pointer:last-child{
    padding-right: 10px;
}
.area_top a{
    color: #333;
}
.pointer_tiger{
    position: relative;
    top: -50px;
}
.top_line{
    margin: 0px 5px;
    width: 100%;
    background: url('../../../../public/img/started/bg.png') center center repeat-x;
}
.top-img{
    padding-left: 0px;
    padding-right: 0px;
}
.top-img:first-child{
    padding-left: 10px;
}
.top-img:last-child{
    padding-right: 10px;
}
.top-img a{
    color: #333 !important;
}
.top-img img{
    width: 40%;
    margin-top: 5px;
}
.top_img_text{
    width: 65%;
}
.section_area{
    background:#C7EAF8;
    padding: 10px;
    margin: 10px 0;
    font-weight: bold;
}
.section_number{
    font-size: 130px;
}
.section_title{
    font-size: 22px;
}
.broker_img{
    cursor: pointer;
}
.section3 input{
    width: 100%;
    margin-bottom: 5px;
}
.section3 li{
    margin-bottom: 10px;
}
.move-right-sm{
    padding-left: 20px;
    padding-right: 0px;
}
.section_content{
    font-weight: 200;
}
.section_content .content_bold{
    font-weight: bold;
}
/*******************************************
popupbox
*********************************************/

#wheretotrade #ppboxEligibility .overlay-inner, 
#wheretotrade #ppboxHTML2 .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}

#ppboxEligibility .page-header, #ppboxHTML2 .page-header {
    margin-top: 0px;
}

#ppboxEligibility p, #ppboxEligibility h3, #ppboxHTML2 p, #ppboxHTML2 h3 {
    color: #ffffff;
}

#ppboxEligibility input, #ppboxHTML2 input {
    width: 30%;
    margin: 0 10px;
    color: #333333;
}
/*******************************************
ttip
*******************************************/
.qtip-default{
    background: #ffffff;
    max-width: 450px;
}
.qtip-default .page-header{
    margin-top: 10px;
}
@media screen and (max-width: 991px){
    #ppboxHTML .overlay-inner, #ppboxEligibility .overlay-inner, #ppboxHTML2 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
}
@media screen and (max-width: 767px){
    #wheretotrade .broker-table td{    
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .qtip-default{
        max-width: 280px;
    }    
    .broker-td-note img{
        width: 12px;
        width: auto;
    }
    .section_title{
        display: flex;
        vertical-align: middle;
    }
    .section_title span{
        margin-top: auto;
        margin-bottom: auto;
        font-size: 18px;
    }
    .section_number{
        font-size: 35px !important;;
        margin-right: 5px;
    }
    .top_line{
        width: 80%;
    }
    .move-right-sm{
        padding-left: 10px;
        padding-right: 10px;
    }
    #wheretotrade #ppboxEligibility .overlay-inner, 
    #wheretotrade #ppboxHTML2 .overlay-inner{
        margin: 25vh auto;
        width: 80%;
        height: auto;
        background: #000000;
        color: #ffffff;
    }
}
@media screen and (max-width: 510px){
    .section_number {
        font-size: 70px;
    }
}