.showImgpopup_fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}
.showImgpopup_con{
    width: 100%;
    margin: 0 auto;
}
.showImgpopup_con img{
    width: 100%;
}
.showImgpopup_con .showImgpopup_close{
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
}
.showImgpopup_con .showImgpopup_close img{
    width: 20px;
    height: 20px;
    margin: 0 20px 20px 0;
} 
