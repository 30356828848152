.hometop {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    /* margin-bottom:-60px; */
}

/********************** tabnav *************************/
.tabnav {
    margin-bottom: 30px;
    margin-top: -55px;
}

.tabnav-item {
    padding: 15px 5px 15px 5px;
    text-align: center;
    background-color: #fff;
    border-bottom: 5px solid #eee;
    cursor: pointer;
}

.tabnav-img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
}
#home #qlink-dwvideo .slick-slider.show-desk button.slick-arrow {
    height: calc(100% - 7px);
}
#home #qlink-dwvideo .slick-slider.show-desk button.slick-arrow.slick-next {
    left: inherit;
    right: 0;
}
#home #qlink-dwvideo .slick-slider.show-desk button.slick-arrow {
    background-color: initial;
    color: #ddd;
    height: 100%;
    left: 0;
    padding: 0;
    width: 65px;
    z-index: 10;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
#home #qlink-dwvideo .slick-slider.show-desk button.slick-arrow:before {
    top: 270px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
}
#home .slick-slider.show-desk button.slick-arrow.slick-next:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAaCAYAAAC+aNwHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAG+SURBVDhPlZStTsNQFMfb2wmSdQtyggdAINcWEhCVhOBA7A2Qm0DgcAhI2BsgEEPgRwJJDWZZ5yr2AIglmCVbMrMP/qc9LWz33rX8ktt7zuk9//t9jZQwDA9R+uCKQ4Uw6YPEfVQBSo385XLZcl23TXYe5mZyCkQaEHlhV4tYrVZPqNeSCSHEM6Zzwa4WYZpmyPYmJfzr5ImIyWTSQv2auBKxCKZ5zL6E8H1/DpEGbK0IptnVicS7QARBsGPb9jt6VDaEyBTlCAsbcSgmEyCiKLJns1lXJwJGKH69Xh8m7oYA8V8RSYBgkT5E6Iyo+FosFo7neSOlANHr9Wo4C4FOBOsxxGHztQIEiViW1Ye5l0QkQsGGknK5PEU1TjwZjGJXK5AuJsyDJCIxwvTOLXbWwJkoYf7bzsQY/05oJ6QRUHKlUulsSZ5Sz8ptTJNhKi8QJ58i+ZNDRjaCvGQwx7Zd/k0mMoFqtXqPSnt10XsDB+eN3YxYYDAYPKJBM44ooCfOcRzlbRV4MG7zkre9j/QinbEtkZdMCDS6QT1P3F8Qvy7yMgs0+qAFgp2JYFRtxB/YLQY9nijfKHccKoBh/AAoXNp+5gvhJgAAAABJRU5ErkJggg==);
    right:0%!important;
    left:auto!important;
}
#home .slick-slider button.slick-arrow:hover {
    background-color: hsla(0,0%,57%,.5)!important;
}
#home .slick-slider.show-desk button.slick-arrow:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAaCAYAAAC+aNwHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHUSURBVDhPlZQ/S8NAGMbzb7DQbg4d/AAiWQotjaBDhoIdBAcXRzcHF7+Lu4KDg90cOjhEoUtJwaVDP4BIB8FCA83QPz7P5RITL23jD65379t73rt7771o/2EwGJyhvff7/RPp0nTZb8X3/XNd1x8xtFarVYBxu16v9woFwKpH6Dw0SzhAHGRrAIoxuYvJZelKMzbkIBeInQ1i8rp2BxDvo+O2q8LxBwTulUql/CMgy1XTNH0M9yJPllhs23agHIFiwzC48jrxyLKsU4ppZwLEYpyZ21egeLlcurVabSJdv3VQVNxsNsfSJTD5MxwOy/jzDeID4VUZLxaLY8dxPqWdYFA8m826GNuRS4EruhB/RGYWIwzDO6zMSstDiFGyo8hUYRJ3o6EKzj3B1pOE5WFgwgUTJO0MTCgTywRLl4K4hQKFw7s/TF9fjKgDeTUttMwVxXAn8/n8mQmXroRMKRet/7gKifIWGAQTfaya+wIZJAiCluu6IW3lLfDKIG5jYrJKGl55pVJ58DxPfFxyXyPhhwTdC9qOcKh0ptPpxdoAJP0djDwKtxsDkE1BcMyvrQGIDPIkzTSXShLzaDQaHbzWG2kKaCPh99IsBnZyjfaNdhV5NO0HR+Tivbr5dpUAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    border-color: #ddd;
    border-width: 0 5px 5px 0;
    content: "";
    height: 30px;
    left: 50%;
    padding: 0;
    position: absolute;
    right: 15px!important;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 20px;
    opacity: 1;
}
#home  .slick-slider.show-desk ul.slick-dots {
    display: block!important;
    position: absolute;
    bottom: 25px;
    right: inherit;
}
#home  .slick-slider.show-desk ul.slick-dots li {
    width: auto;
    height: auto;
}
#home .slick-slider.show-desk ul.slick-dots li.slick-active button{
    background-color: #FECC0A;
    border-color: #FECC0A;
  }
  #home .slick-slider.show-desk ul.slick-dots li button::before{
    display: none;
  }
  #home .slick-slide img{
    display: unset;
  }
  #home  .slick-slider.show-desk button.slick-arrow:before {
    top: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    left:15%;
}
#home  .slick-slider.show-desk ul.slick-dots li button {
    width: 16px;
    height: 16px;
    opacity: 1;
    border-radius: 50%;
    border: 1px solid #c5c5c5;
    background-color: initial;
}
/* .tabnav .active:after {
     position:absolute;
     bottom:0;
     left:50%;
     width:0;
     height:0;
     margin-left:-18px;
     vertical-align:middle;
     content:" ";
     border-right:18px solid transparent;
     border-bottom:18px solid #FECC0A;
     border-left:18px solid transparent;
 } */
.tabnav-item.active,
.tabnav-item:focus,
.tabnav-item:hover {
    background-color: #000;
    border-bottom: 5px solid #FECC0A;
}

.tabnav-item.active:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -18px;
    vertical-align: middle;
    content: " ";
    border-right: 18px solid transparent;
    border-bottom: 18px solid #FECC0A;
    border-left: 18px solid transparent;
}

.tabnav-item.active .tabnav-htxt,
.tabnav-item:hover .tabnav-htxt,
.tabnav-item.focus .tabnav-htxt {
    color: #FECC0A;
}

.tabnav-item.active .tabnav-txt,
.tabnav-item:hover .tabnav-txt,
.tabnav-item:focus .tabnav-txt {
    color: #fff;
}

.tabnav-htxt {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 16px;
}

.tabnav-txt {
    font-size: 13px;
    height: 36px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    color: #333;
}

.nav-dtxt {
    text-align: center;
    margin-top: 10px;
    margin-bottom: -30px;
    display: none;
}

.nav-dtxt.show {
    display: block;
}

.tabnav-item a {
    text-decoration: none;
}

.tabnav-item-dwtool .tabnav-img {
    background: url("../../../public/img/home/dwtool-lg.png") 0 0 no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.tabnav-item-dwstat .tabnav-img {
    background: url("../../../public/img/home/dwstat-lg.png") 0 0 no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.tabnav-item-dwedu .tabnav-img {
    background: url("../../../public/img/home/dwedu-lg.png") 0 0 no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.tabnav-item-dwnews .tabnav-img {
    background: url("../../../public/img/home/dwnews-lg.png") 0 0 no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.tabnav-item-dwtool.active .tabnav-img,
.tabnav-item-dwtool:hover .tabnav-img,
.tabnav-item-dwtool:focus .tabnav-img {
    background: url("../../../public/img/home/dwtool-active-lg.png") 0 0 no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.tabnav-item-dwstat.active .tabnav-img,
.tabnav-item-dwstat:hover .tabnav-img,
.tabnav-item-dwstat:focus .tabnav-img {
    background: url("../../../public/img/home/dwstat-active-lg.png") 0 0 no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.tabnav-item-dwedu.active .tabnav-img,
.tabnav-item-dwedu:hover .tabnav-img,
.tabnav-item-dwedu:focus .tabnav-img {
    background: url("../../../public/img/home/dwedu-active-lg.png") 0 0 no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.tabnav-item-dwnews.active .tabnav-img,
.tabnav-item-dwnews:hover .tabnav-img,
.tabnav-item-dwnews:focus .tabnav-img {
    background: url("../../../public/img/home/dwnews-active-lg.png") 0 0 no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.tab-container {
    overflow: hidden;
}

/********************** tab-container *************************/
.tab-container {
    margin-bottom: 20px;
    overflow: hidden;
}

.section {
    background-color: #fff;
    padding: 40px;
    margin-top: 20px;
    width: 100%;
}

.section>.page-header {
    margin-top: 0;
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
}

.news-title {
    /* font-family: HelveticaNeueMedium; */
    margin-bottom: 20px;
}

.news-summary {
    min-height: 80px;
    overflow: hidden;
}

.section-btn-wapper {
    width: 100%;
    text-align: right;
    margin-top: 20px;
}

#qlink-dwvideo .videoplayer {
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

#qlink-dwvideo .videoplayer-container {
    height: 0;
    width: 100%;
    /* padding-bottom: 57%; */
    overflow: hidden;
    position: relative;
}

#qlink-dwvideo iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*position: absolute;*/
}

#toptradeddw h2.small {
    font-size: 16px;
    /* font-family: HelveticaNeueMedium; */
}

#toptradeddw table,
#toptradeddw table a {
    font-size: 14px;
}

#toptradeddw #topindices th,
#toptradeddw #topindices td,
#toptradeddw #topdw th,
#toptradeddw #topdw td {
    text-align: center;
    white-space: nowrap;
}

#toptradeddw #topindices td,
#toptradeddw #topdw td {
    white-space: nowrap;
}


/*******************************************************************************
 #topmover
*******************************************************************************/
#topmover .page-header {
    display: flex;
    justify-content: space-between;
}

#topmover .vmbtn,
#topmover .vabtn,
#topmover .lmbtn,
#topmover .wtbtn {
    cursor: pointer;
    border: 2px solid #FECC0A !important;
    font-size: 12px;
    margin-top: 5px;
    padding: 2px;
    text-align: center;
    width: 45%;
    color: #333;
    line-height: 1;
}

#topmover .vabtn {
    margin-left: 5px;
}

#topmover .vmbtn-link {
    color: #333333;
    display: flex;
}

.hotlist-col {
    padding-left: 0px;
    padding-right: 0px;
}

.hotlist-left,
.hotlist-right {
    padding: 0 !important;
}

.hotlist-item-change {
    font-size: 14px;
}

.hotlist-item-dataarea {
    cursor: pointer;
}

.hotlist-col-h {
    /* font-family: "HelveticaNeueMedium"; */
    width: 100%;
    text-align: center;
    background-color: #DFDBCF;
    padding: 7px 0;
}

.hotlist-row {
    margin-right: 0;
    margin-left: 0;
}

.hotlist-item {
    width: 100%;
    padding: 10px;
    position: relative;
}

.right-border {
    border-right: 1px solid #d2d2d2;
}

.hotlist-item-trend {
    width: 26px;
    height: 16px;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-image: url("../../../public/img/bullet/hotlist-up.png");
    margin-top: 7px;
}

.hotlist-item-trend.up {
    background-image: url("../../../public/img/bullet/hotlist-up.png");
}

.hotlist-item-trend.down {
    background-image: url("../../../public/img/bullet/hotlist-down.png");
}

.hotlist-item-nm {
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    min-height: 42px;
    color: #0675C2;
}

.hotlist-item-unm {
    font-size: 15px;
    white-space: nowrap;
}

.hotlist-item-params {
    position: absolute;
    top: 44px;
    left: 42px;
}

.hotlist-item-eg {
    font-size: 14px;
    margin-top: 5px;
}

.hotlist-item-pc {
    padding-left: 10px;
    font-size: 19px;
}

.hotlist-more {
    margin-top: 60px;
}

.hotlist-item-data {
    display: flex;
}

/*******************************************************************************
 dwsearch section
*******************************************************************************/
#qlink-dwsearch .section-btn-wapper.section-center {
    display: flex;
    justify-content: space-between;
}

#qlink-dwsearch .filter-item {
    margin-bottom: 10px;
}

#qlink-dwsearch .section-p {
    min-height: auto;
}

#qlink-dwsearch .section-btn-wapper.section-center {
    display: flex;
    justify-content: space-between;
}

.section-btn-wapper {
    width: 100%;
    text-align: right;
    margin-top: 20px;
}

#home .btn.btn-primary {
    min-width: 150px;
}

/*******************************************************************************
 warrant tools
*******************************************************************************/
.tools-row {
    margin-bottom: 5px;
}

.tools-row .tools-title {
    padding: 5px;
    font-size: 20px;
    background-color: #FECC0A;
    text-align: center;
}

.tools-row .tools-detail {
    background-color: #FFFFFF;
}

.tools-row .glyphicon {
    float: right;
    right: 10px;
    top: 5px;
}

.tools-row .page-header {
    margin-top: 0;
}

.tools-row .section-p {
    min-height: 30px;
}

.tools-row #lmtablecontainer {
    /* min-height: 50px; */
}

/* #lmtablecontainer tbody  tr:last-child{
    background-color: rgba(9,124,207,0.2);
} */
#selector-tools {
    position: relative;
}

.tools-row .swiper-wrapper {
    margin-top: 0;
}

.tools-row .swiper-botton .left,
.tools-row .swiper-botton .right {
    width: 35px;
}

/*******************************************************************************
Home page Quick Headlines
********************************************************************************/
#quickHeadlines #topTbl table th,
#quickHeadlines #topTbl table td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: left;
    white-space: normal;
}

#quickHeadlines #topTbl table {
    width: 100%;
    margin-bottom: 10px;
}

#quickHeadlines #topTbl table .detail {
    text-align: right;
}

#quickHeadlines #topTbl table .news {
    width: 22%;
    /* font-family: "HelveticaNeueMedium"; */
}

#quickHeadlines .section-btn-wapper.section-center {
    display: flex;
    justify-content: space-between;
}

#quickHeadlines .section-btn-wapper.section-center .section-txt {
    text-align: left;
    font-weight: normal;
}

/*******************************************************************************
 live index future (global market)
*******************************************************************************/
#indexfuture table th,
#indexfuture table td {
    text-align: center;
    border-bottom: 1px solid #333333;
    padding-left: 2px;
    padding-right: 2px;
    vertical-align: middle;
}

#indexfuture table td a {
    /* font-family: "HelveticaNeueMedium"; */
    text-decoration: none;
    color: #0675C2;

}

#indexfuture table td a {
    /* font-family: "HelveticaNeueMedium"; */
    text-decoration: none;
    color: #0675C2;

}

#indexfuture table td li a {
    color: #262626;
}

#indexfuture .uptick .val.up,
#indexfuture .downtick .val.up,
#indexfuture .uptick .val.down,
#indexfuture .downtick .val.down {
    color: #333333 !important;
}

#indexfuture .uptick {
    background-color: #6CC263 !important;
}

#indexfuture .downtick {
    background-color: #C61C2D !important;
}

#lmatrix.section .section-p {
    min-height: 30px;
}

#home #lmatrix .ricbar {
    font-size: 20px;
}

#lmatrix .lbtable {
    border-bottom: 1px solid #FECC0A;
}

#lmatrix .section-p {
    min-height: 30px;
    margin-bottom: 10px;
}

/*******************************************************************************
#annoucement 
*******************************************************************************/
#announcement .media-heading {
    /* font-family: 'Roboto'; */
    font-weight: 500;
    font-style: Medium;
    font-size: 16px;
    margin-bottom: 0;
}

#announcement .date {
    font-size: 12px;
}

#announcement .media {
    margin-bottom: 30px;
}

#announcement .media:last-child {
    margin-bottom: 0px;
}

#announcement .media-body p {
    font-size: 14px;
}

#announcement p {
    /* font-family: 'Roboto'; */
    font-weight: 300;
    font-style: Light;
    font-size: 14px;
}

#announcement .media-readmore {
    /* font-family: 'HelveticaNeue', 'Helvetica Neue'; */
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #0675C2;
    text-align: right;
}


/********************** footBanner *************************/
.hf-banner {
    /*    background-color:#fff;*/
    padding-top: 30px;
    padding-bottom: 30px;
}

.hf-banner .banner-img {
    width: 100%;
    height: auto;
}

/*******************************************************************************
 loading image
*******************************************************************************/
#home .chartbox .loadingindicator {
    z-index: 30
}

#dwtool .overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.6);
}

#dwtool .overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}

#dwtool #ppboxHTML2 .overlay-inner {
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}

#dwtool .popupbox-content {
    padding: 60px;
}

.noscroll {
    position: relative;
    overflow: hidden;
}

#dwtool #ppboxHTML2 .page-header {
    margin-top: 0px;
}

#dwtool #ppboxHTML2 p,
#dwtool #ppboxHTML2 h3 {
    color: #ffffff;
    text-align: start;
}

#dwtool #ppboxHTML2 input {
    width: 100%;
    margin-bottom: 10px;
    color: #333333;
}

#dwtool #ppboxHTML2 .warning {
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}

#dwtool #ppboxHTML2 input {
    margin: 0;
}

.general-readmore {
    cursor: pointer;
}

/********* ppboxHTML *************/
#home #ppboxHTML .overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
    color: #333;
}

.ppc-h {
    margin: inherit;
    padding-left: 0;
}

/***************** how to get start ****************/
.holder-qlink-getstart .ttip {
    color: #333;
}

.holder-qlink-getstart .top_line {
    margin: 0px 5px;
    width: 100%;
    background: url(../../../public/img/started/bg.png) center center repeat-x;
}

.holder-qlink-getstart .top-pointer {
    display: flex;
    font-size: 22px;
    padding-left: 0px;
    padding-right: 0px;
}

.holder-qlink-getstart .top-img {
    padding-left: 0px;
    padding-right: 0px;
}

.holder-qlink-getstart .top-img img {
    width: 40%;
    margin-top: 5px;
}

.holder-qlink-getstart .top_img_text {
    width: 65%;
    font-size: 70%;
    word-break: break-all;
}

/************* chart button ************/
#warranttools .ctab-item.active {
    background-color: #FECC0A;
}

#warranttools .ctab-item {
    text-align: center;
    width: 25%;
    border: 2px solid #FECC0A !important;
    height: 33px;
    cursor: pointer;
}

/*************  tw-table*****************/
.tw_table {
    /* background-color: rgb(245, 246, 246); */
    max-height: 220px;
}

.call_table {
    padding: 10px;
}

.tw_title>div {
    display: inline-block;
}

.tw_warrant table {
    margin-top: 10px;
}

.call_table .tw_type {
    padding: 2px 20px;
    background-color: #70C05F;
    border-radius: 5px;
    font-size: 18px;
}

.put_table {
    padding: 10px;
}

.put_table .tw_type {
    padding: 2px 20px;
    background-color: #A91F2E;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 18px;
}

.tw_ticker {
    font-size: 18px;
    font-weight: bold;
    color: #DD9529;
    padding-left: 10px;
}

.tw_dsply_name {
    font-weight: bold;
}

/*************  lightbox    *************/
#lightbox {
    display: table;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    text-align: center;
}

#lightbox>div {
    display: table-cell;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.6);
}

#lightbox .inner-area {
    display: inline-block;
    position: relative;
    z-index: 3000;
}

#lightbox .btn-close {
    position: absolute;
    left: 2px;
    top: 8px;
    width: 32px;
    height: 32px;
}

#lightbox .countdown-txt {
    position: absolute;
    top: 5px;
    right: 8px;
    font-size: 12px;
    color: #ffffff;
}

#lightbox .inner-area img {
    width: 100%;
}

#lightbox .noshow {
    font-size: 12px;
    text-decoration: underline;
    color: #333333;
    position: absolute;
    bottom: 6px;
    left: 8px;
}

#lightbox .btn-close:before,
#lightbox .btn-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 17px;
    width: 2px;
    background-color: #fff;
}

#lightbox .btn-close:after {
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#lightbox .btn-close:before {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* tooltip */
.warran-tooltip {
    pointer-events: auto !important;
    max-width: 400px;
    opacity: 1 !important;
    /* padding:5px 0px!important; */
    padding: 0px 0px !important;
}

.warran-tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.section_title span {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    color: #555;
    font-weight: bold;
}

.section_area {
    background: #C7EAF8;
    font-weight: bold;
    padding: 10px !important;
    padding-bottom: 20px !important;
    margin: 0px !important;
    color: #000;
}

.section_area.areaMargin {
    margin-top: 10px !important;
}

.broker-table-holder {
    background: #ffffff;
    font-weight: normal;
}

.table-striped>tbody>tr:nth-of-type(even) {
    background-color: #fff;
}

.table.table-striped {
    font-size: 14px;
}

.broker_img {
    cursor: pointer;
    width: 90px;
}

.broker-table .broker-td-logo img {
    width: 90px;
}

.broker-table .broker-td-tick {
    background: url(../../../public/img/education/broker_tick.png) center center no-repeat;
}

.broker-table .broker-phone {
    background: url(../../../public/img/bullet/icon_phoneto.png) center center no-repeat;
    display: block;
    width: 12px;
    height: 12px;
}

.broker-table .broker-email {
    background: url(../../../public/img/bullet/icon_mailto.png) center center no-repeat;
    display: block;
    width: 17px;
    height: 12px;
}

.section3 li {
    margin-bottom: 10px;
}

.section_content {
    font-weight: 200;
}

.section3 .btn {
    width: 100%;
    margin-bottom: 5px;
}

.btn-primary {
    /* min-width: 190px; */
}

.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
}

#ppboxEligibility .overlay-inner {
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}

.popupbox-content {
    padding: 60px;
}

#ppboxEligibility .page-header {
    margin-top: 0;
}

.page-header {
    border-bottom: 1px solid #FECC0A;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin: 40px 0 20px;

}

#ppboxEligibility p.small {
    font-size: 12px;
    font-weight: normal;
}

.lives-tooltip {
    width: 400px;
}

/* 弹出框 */
.disclaimer-overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20000;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
}

#disclaimer-ppboxHTML .overlay-inner {
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}

#disclaimer-ppboxHTML .popupbox-content {
    padding: 30px;
}

#disclaimer-ppboxHTML .page-header {
    margin-top: 0px;
}

.page-header {
    border-bottom: 1px solid #FECC0A;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

#disclaimer-ppboxHTML p,
#disclaimer-ppboxHTML h3 {
    color: #ffffff;
}

#disclaimer-ppboxHTML input {
    width: 30%;
    margin: 0 10px;
    color: #333333;
}

.hottab {
    color: #7f7f7f;
    cursor: pointer;
}

.hottab.active {
    color: #333333;
    font-weight: bold;
}

.ulist.active {
    display: block;
}

.ulist {
    margin-bottom: 20px;
    max-height: 300px;
    overflow-y: scroll;
    display: none;
}

.hotlist-item-nm:hover {
    text-decoration: underline;
}

#lastViewTblw tbody>tr:nth-of-type(even) {
    background-color: #fff;
}

#gearingLevelImage {
    width: 65px;
    height: 55px;
    margin-right: 70px;
}

/* .hotlist-col .hotlist-item.index-item {
    display: flex;
    justify-content: space-between;
}
#topmover .hotlist-col .hotlist-item.index-item .vmbtn-link {
    flex-direction: column;
}
#topmover .hotlist-col .hotlist-item.index-item .vmbtn-link .vmbtn, #topmover .hotlist-col .hotlist-item.index-item .vmbtn-link .vabtn {
    width: 100%;
    margin: 0;
    margin-top: 10px;
    padding: 2px 20px;
} */
/* .css-1uccc91-singleValue {
    margin-left: 25px !important;
} */
.homeTopMversTable th,
.homeTopMversTable td {
    text-align: left;
}

/* 广告 */
.main-disclaimer-overlay {
    position: fixed;
    overflow-y: hidden;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2009;
    background-color: rgba(0, 0, 0, 0.6);
    line-height: 1;
}

.disclaimer-overlay-inner {
    margin: 15vh auto;
    width: 50%;
    height: auto;
    background: #FFFFFF;
}

.disclaimer-page-header {
    border-bottom: 1px solid #FECC0A;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-top: 0px;
}

.disclaimer-page-header h1.small {
    font-size: 22px;
}

.disclaimer-overlay-inner .bold {
    font-weight: bold;
}

.disclaimer-title {
    text-decoration: underline;
}

.disclaimer-overlay-inner .popupbox-content {
    overflow-y: scroll;
    height: 50vh;
    text-align: left;
    padding: 10px;
    border: 1px solid;
    line-height: 22px;
}

.disclaimer-overlay-inner .bold {
    font-weight: bold;
}

.disclaimer-overlay-inner .back-btn {
    color: #fff !important;
    background-color: #b3b3b3 !important;
    border-color: #b3b3b3 !important;
}

#home .btn.btn-primary.back-btn:hover,
#home .btn.btn-primary.back-btn:focus {
    color: #fff !important;
    background-color: #b3b3b3 !important;
    border-color: #b3b3b3 !important;
}

.disclaimer-overlay-inner hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    /* border-top: 1px solid #eeeeee; */
}

.disclaimer-overlay-inner .btn {
    margin: 10px auto;
    border-radius: 10px;
    max-width: 190px;
    width: 49%;
    min-width: auto !important;
}

#brochureExitImage {
    padding-right: 150px
}

#brochureEntreImage {
    padding-right: 5px;
    padding-left: 50px;
}

.SplashPage_container .overlay-inner{
   width: 500px;
}
.SplashPage_container .image{
   height: 385px;
}
.SplashPage_container .btn-closes.btn-left{
    font-size: 22px
}

#brochureExitImage {
    padding-right: 150px
}

#brochureEntreImage {
    padding-right: 5px;
    padding-left: 50px
}

/*****************响应式****************/
@media screen and (max-width: 1700px) {
    .swiper-wrapper .swiper-paginationh {
        top: 80%
    }
}

@media screen and (max-width: 1600px) {
    .tabnav {
        margin-top: -180px;
    }

    .swiper-wrapper .swiper-paginationh {
        top: 70%
    }
}

@media screen and (max-width: 1400px) {
    .swiper-wrapper .swiper-paginationh {
        top: 60%
    }
}



@media screen and (max-width: 768px) {
    .warran-tooltip {
        max-width: 350px;
    }

    .tabnav-img {
        width: 25px;
        height: 25px;
        margin-bottom: 5px;
    }

    .tabnav-item {
        padding-top: 5px;
        padding-left: 15px;
        padding-right: 15px;
        height: 90px;
    }

    .tabnav .col-xs-3 {
        padding-left: 1.5px;
        padding-right: 1.5px;
    }

    .tabnav {
        margin-top: -30px;
    }

    .table.table-striped {
        font-size: 12px;
    }

    .page-header h1 {
        font-size: 18px;
    }

    .tabnav-htxt {
        font-size: 14px;
    }

    .tabnav {
        margin-top: -60px;
    }
    #home #qlink-dwvideo .slick-slider.show-desk button.slick-arrow:before {
        top:160px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
    .table.table-striped {
        font-size: 14px;
    }
    .shortVideoBox{
        height: 544px!important;
    }

}

@media screen and (min-width: 768px) and (max-width: 786px) {
    .tabnav {
        margin-top: -60px;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
    /* .shortVideoBox{
        height: 816px!important;
    } */
}
@media screen and (min-width: 1200px) {
    #home .tabnav-htxt {
        font-size: 22px;
    }
    .swiper-containerh {
        height: 520px;
    }
}
@media screen and (max-width: 991px) {
    .hotlist-col .hotlist-item.index-item {
        display: flex;
        justify-content: space-between;
    }

    #topmover .hotlist-col .hotlist-item.index-item .vmbtn-link {
        flex-direction: column;
    }

    #topmover .hotlist-col .hotlist-item.index-item .vmbtn-link .vmbtn,
    #topmover .hotlist-col .hotlist-item.index-item .vmbtn-link .vabtn {
        width: 100%;
        margin: 0;
        margin-top: 10px;
        padding: 2px 20px;
    }

    .disclaimer-overlay-inner {
        margin: 10vh auto;
        width: 90%;
    }
    /* .shortVideoBox{
        height: 645px!important;
    } */
    .SplashPage_container .btn-closes{
         font-size: 10px
    }
    .SplashPage_container .btn-closes.btn-left{
         font-size: 16px
    }
    .SplashPage_container .image{
        height: auto;
     }
}

@media screen and (max-width: 1200px) {
    .tabnav {
        margin-top: -30px;
    }

    .swiper-wrapper .swiper-paginationh {
        top: 80%
    }
}

@media screen and (min-width: 403px) and (max-width: 786px) {
    #gearingLevelImage {
        margin-right: 30px;
        margin-left: 30px
    }
    #brochureEntreImage {
        padding-right: 30px;
        padding-left: 10px
    }
    #brochureExitImage {
        padding-right: 50px
    }
}

/*当屏幕宽度小于等于480px时*/
@media screen and (max-width: 402px) {
    #gearingLevelImage {
        margin-right: 10px;
        margin-left: 10px
    }
    #brochureEntreImage {
        padding-right: 8px;
        padding-left: 20px
    }
    #brochureExitImage {
        padding-right: 10px
    }
    .SplashPage_container .overlay-inner{
        width: 90%;
    }
}