.htc-main-left,
.htc-main-right {
    min-height: 500px;
}

.mlarea-one,
.mlarea-two,
.mlarea-three,
.mrarea-one,
.mrarea-two,
.mlarea-index {
    min-height: 300px;
    background: #fff;
    margin-top: 70px;
}

.mlarea-one h2,
.mlarea-two h2 {
    margin: 0;
    letter-spacing: 0.1em;
}

.vmbtn-link {
    color: #333333;
    display: flex;
    /* justify-content: space-between; */
}

.tw_table {
    background-color: rgb(245, 246, 246);
    margin-top: 20px;
}

.vmbtn,
.vabtn,
.lmbtn,
.wtbtn {
    cursor: pointer;
    border: 2px solid #FECC0A !important;
    font-size: 12px;
    margin-top: 5px;
    /* margin-right: 10px; */
    padding: 2px;
    text-align: center;
    width: 45%;
    color: #333;
    line-height: 1;
}

.mlarea-three-title h2,
.mrarea-two h2 {
    margin: 0;
    margin-bottom: 20px;
    letter-spacing: 0.1em;
}

.hestch-text {
    padding-top: 20px;
    font-size: 16px;
    color: #333;
    text-align: justify;
}

.hestch-tips {
    font-size: 12px;
    font-style: italic;
}

.hstech-hr {
    border: 1px solid #FECF19;
    width: 100px;
    margin: 0;
    left: 0;
}

/* banner */
.hsh-welcometop {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: -40px;
}

.hsh-welcometop .hsh-welcomeimgtop img,
.hsh-welcometop .hsh-welcomeimgtop2 img {
    width: 100%;
}

.hsh-welcometop .hsh-welcomeimg img {
    width: 100%;
    margin-top: 25px;
}

.hsh-welcometop .hsh-welcomeimg .hsh-welcomeimg-achor {
    position: absolute;
    top: 17%;
    bottom: 58%;
    left: 5%;
    right: 53%;
}

.hsh-video-wrapper {
    display: block;
    width: 100%;
    height: auto;
}

.hestch-container {
    margin-top: -160px;
}

/*Trade the future of the new economy with the Hang Seng TECH Index */
.mlarea-index {
    padding: 30px;
}

/*左一*/
.mlarea-one {
    padding: 30px;
}

.mlarea-one>div:first-child {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/*左二*/
.mlarea-two {
    padding: 30px;
    margin-top: 20px;
}

.chart1-box,
.chart2-box {
    padding: 10px 0;
}

/*左三*/
.mlarea-three {
    padding: 30px 0;
    margin-top: 20px;
}

.mlarea-three-title {
    padding: 0 30px;
    word-break: break-all;
}

.mlarea-three-context {
    min-height: 150px;
    background: #000;
    margin: 10px 30px;
    padding: 20px;
}

.context-title {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.context-title h5 {
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-size: 18px;
}

.context-text {
    color: #FECC09;
    font-size: 16px;
    text-align: justify;
}

/*右一*/
.mrarea-one {
    background: url("../../../../public/img/hstech/webinar-bg.png") no-repeat;
    background-size: cover;
}

.mrarea-one .carousel-control {
    color: grey;
    opacity: 1;
}

#hstech .active {
    color: #FECC09 !important;
}

.mrarea-one .carousel-inner {
    position: relative;
    max-width: 350px;
    overflow: hidden;
    margin: 0 auto;
}

.carousel-box {
    /* background-color: #000; */

    background-color: rgba(0, 0, 0, 0.6);
    /* opacity: .7; */
    min-height: 650px;
    border-radius: 5%;
    max-width: 350px;
    margin: 40px auto;
}

.carousel-box .carousel-caption {
    position: static;
    bottom: 0;
    padding: 30px 50px;
    z-index: 10;
    text-align: left;
    overflow: hidden;
}

.carousel-caption-img {
    width: 100%;
    height: 200px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
    font-size: 35px;
}

/*右二*/
.mrarea-two {
    margin-top: 20px;
    padding: 30px 20px;
    overflow: hidden;
}

.icon-pointer {
    display: flex;
    font-size: 22px;
    padding-left: 0px;
    padding-right: 0px;
}

.icon-pointer .top-line {
    width: 80%;
    margin: 0px 5px;
    background: url(../../../../public/img/started/bg.png) center center repeat-x;
}

.img-pointer {
    align-items: center;
}

.img-pointer img {
    width: 50px;
    height: 50px;
    margin-top: 5px;
}

.img-pointer .icon-text {
    letter-spacing: 0.1em;
    margin-top: 5px;
    color: #333;
    font-weight: 600;
}

.hstechbtn-area {
    width: 120px;
    height: 50px;
    margin-top: 20px;
    float: right;
    bottom: 10%;
}

.hstechbtn-area .learnbtn a,
.hstechbtn-area .signupbtn,
.hstechbtn-area .subscribebtn {
    display: inline-block;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    color: rgb(102, 102, 102);
    border: 1px solid #D8A536;
}

.hstechbtn-area .signupbtn,
.hstechbtn-area .subscribebtn {
    height: 60px;
    line-height: 60px;
    border-radius: 15%;
    color: #000;
    font-size: 18px;
    border: 0;
    bottom: 10%;
}

/*xs-nav*/
.col-xs-5ths {
    position: relative;
    min-height: 1px;
    float: left;
    height: 95px;
    background: #aaa !important;
    margin-right: 5px;
    width: calc(20% - 4px);
}

.col-xs-5ths:last-child {
    margin-right: 0;
}

.hsh-xsnav-scroll {
    position: fixed;
    /* 绝对定位，fixed是相对于浏览器窗口定位。 */
    top: 122px;
    /* 距离窗口顶部距离 */
    left: 0;
    /* 距离窗口左边的距离 */
    width: 100%;
    /* 宽度设置为100% */
    height: 40px;
    /* 高度 */
    z-index: 99;
    /* 层叠顺序，数值越大就越高。页面滚动的时候就不会被其他内容所遮挡。 */
}

.hsh-xsnav-box {
    padding-top: 10px;
    background: #fff;
}

.hsh-xsnav-box .hsh-navimg {
    height: 25px;
}

.hsh-xsnav-box .hsh-navtxt {
    font-size: 8px;
    color: #333;
    font-weight: 600;
    padding-top: 10px;
    line-height: 11px;
    text-align: center;
}

.hsh-xsnav-box.active,
.hsh-xsnav-box:focus,
.hsh-xsnav-box:hover {
    background-color: #000;
    cursor: pointer;
}

.hsh-xsnav-box.active .hsh-navtxt,
.hsh-xsnav-box:hover .hsh-navtxt,
.hsh-xsnav-box.focus .hsh-navtxt {
    color: #FECC0A;
}

.hsh-xsnav-box-what .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-1-black.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.hsh-xsnav-box-grow .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-2-black.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.hsh-xsnav-box-why .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-3-black.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.hsh-xsnav-box-webinars .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-4-black.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.hsh-xsnav-box-how .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-5-black.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

/*图片被选中*/
.hsh-xsnav-box-what.active .hsh-navimg,
.hsh-xsnav-box-what:hover .hsh-navimg,
.hsh-xsnav-box-what:focus .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-1-yellow.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.hsh-xsnav-box-grow.active .hsh-navimg,
.hsh-xsnav-box-grow:hover .hsh-navimg,
.hsh-xsnav-box-grow:focus .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-2-yellow.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.hsh-xsnav-box-why.active .hsh-navimg,
.hsh-xsnav-box-why:hover .hsh-navimg,
.hsh-xsnav-box-why:focus .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-3-yellow.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.hsh-xsnav-box-webinars.active .hsh-navimg,
.hsh-xsnav-box-webinars:hover .hsh-navimg,
.hsh-xsnav-box-webinars:focus .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-4-yellow.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.hsh-xsnav-box-how.active .hsh-navimg,
.hsh-xsnav-box-how:hover .hsh-navimg,
.hsh-xsnav-box-how:focus .hsh-navimg {
    background: url("../../../../public/img/hstech/nav-5-yellow.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

/*整块被选中*/
.col-xs-5ths .active,
.col-xs-5ths:hover,
.col-xs-5ths:focus {
    background: #000 !important;
}

.pointer_target {
    position: relative;
    top: -150px;
}

/* 弹出框 */
#hshbook_area {
    margin: auto;
}

#hshbook_area .page-header,
#hshSubscribe_area .page-header {
    margin-top: 0;
    border: 0
}

#hshbook_area h1,
#hshbook_area p,
#hshSubscribe_area h1,
#hshSubscribe_area p {
    color: white;
}

#hshbook_area input[type=text],
#hshSubscribe_area input[type=text] {
    width: 100%;
    padding: 4px;
    /*margin-bottom: 20px;*/
}

#firstName {
    margin-top: 20px;
}

#hshbook_area .warning,
#hshSubscribe_area .warning {
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}

#hstech #submitBtn {
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
    border-radius: 10px;
    margin-top: 20px;
}

.hsh-overlay {
    width: 100%;
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.hsh-overlay-inner {
    max-width: 700px;
    margin: 25vh auto;
    height: auto;
    background: #000000;
}

#Hstech .popupbox-content {
    padding: 40px;
}

.popup-a a {
    color: inherit;
    text-decoration: underline;
}

#carousel-example-generic {
    margin: 70px auto;
}

.fixed {
    top: 56px;
    z-index: 999999;
    position: fixed;
    width: 100%;
}

/*response-view*/
@media screen and (max-width: 1500px) {}

@media screen and (max-width: 1200px) {

    .mlarea-index {
        margin-top: 0px;
    }

    #carousel-example-generic {
        margin: 0px auto;
    }

    .mrarea-two {
        margin-top: 60px;
    }
}

@media (max-width: 1000px) {
    .fixed {
        top: 50px;
        z-index: 999999;
        position: fixed;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .hestch-container {
        margin-top: 0;
    }

    .hsh-welcometop .hsh-welcomeimg img {
        margin-top: 0px;
    }

    .hsh-xsnav {
        margin-top: -70px;
    }

    .fixed {
        top: 120px;
    }

    .mlarea-three-title {
        padding: 0 20px;
        text-align: justify;
    }

    .mlarea-three-title h2 {
        font-size: 32px;
    }

    .carousel-box .carousel-caption {
        padding: 20px;
    }
}

@media (max-width: 375px) {

    .carousel-box,
    .carousel-inner {
        width: 240px;
    }

    .mlarea-one,
    .mlarea-two,
    .mlarea-three,
    .mrarea-one,
    .mrarea-two,
    .mlarea-index {
        margin-top: 20px;
    }
}