.whyBox{
    margin-top: 78px;
}
.whyBox .stripe-bg{
    display: block;
    background: url(../../../../public/img/bullet/stripe-bg.png) top left repeat-x;
    height: 198px;
    margin-bottom: -178px;
}
.whyBox .container-fluid{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
}
#area_1{
    position: relative;
    margin-top: 20px;
}
#area_1 img{
    width: 100%;
    vertical-align: middle;
    border: 0;
}
#area_1 .inner_title {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 240px;
    margin-left: -120px;
    margin-top: -40px;
    text-align: center;
}
.inner_title{
    font-size: 44px;
    line-height: normal;
    padding-bottom: 15px;
}
#area_1 .subscribe_area {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(0,0,0,0.6);
}
.subscribe_area table {
    width: 100%;
    height: 100%;
    text-align: center;
}
.whyBox table {
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
}
.whyBox td, .whyBox th{
    padding: 0;
}
.subscribe_area .description {
    display: inline-block;
    color: white;
}
#area_1 a,#area_1 a:focus,#area_1 a:hover,#area_1 a:visited {
    color: #0675C2;
    text-decoration: none;
}
#btn-subscribe {
    display: inline-block;
    border: 1px solid #FECC0A;
    border-radius: 5px;
    line-height: 30px;
    width: 200px;
    margin-left: 40px;
    color: #FECC0A !important;
}
.whyBox .tablearea {
    background-color: white;
}
.whyBox .container-fluid img {
    width: 100%;
}
.tablearea .td-text {
    padding: 0 50px;
    width: 45.13%;
}
.inner_title {
    font-size: 44px;
    line-height: normal;
    padding-bottom: 15px;
    color: #FECC0A !important;
}
.tablearea .td-text {
    padding: 0 50px;
    width: 45.13%;
}
#btn-get-started {
    width: 140px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background-color: #666;
    border-radius: 5px;
    margin-top: 20px;
}
.hr-hmgbm {
    margin-top: 10px;
    margin-bottom: 10px;
}
@media screen and (max-width: 991px) {
    .inner_title{
        /* font-family: 'HelveticaNeueLight'; */
    font-size: 28px;
    }
    .whyBox  {
        margin-top: 51px;
    }
    .whyBox .stripe-bg {
        margin-bottom: -198px;
    }
    .dwvideo-iframe{
        height:350px!important;
    }
}
@media screen and (max-width: 767px){
    .tablearea .altarea {
        background-color: #333;
        color: white;
        padding: 40px 10px;
        width: auto;
    }
    #btn-get-started {
        width: auto;
    }
    #area_1 .subscribe_area {
        height: auto;
        min-height: 34px;
        padding: 8px 0;
    }
    #btn-subscribe {
        margin-left: 0px;
    }
}
