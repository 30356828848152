#dwselector .uppertbl {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    margin-bottom: 20px;
    /*    margin-top: 10px;*/
}
#dwselector .highcharts-range-selector-buttons ,#dwselector .highcharts-scrollbar{
    display: none;
}
.css-tlfecz-indicatorContainer {
    cursor: pointer;
}

.css-yk16xz-control {
    border-color: #767676 !important;
}

/* #dwselector .css-1wa3eu0-placeholder {
    text-align: left;
} */

#dwselector .uppertbl-col-bid {
    /*    border-right:10px solid white;*/
    vertical-align: middle;
    width: 50%;
}

#dwselector .uppertbl-col-chglbl {
    /*    border-bottom:10px solid white;*/
    width: 25%;
}

#dwselector .uppertbl-col-chgval {
    /*    border-bottom:10px solid white;*/
    text-align: right;
    width: 25%;
}

#dwselector .uppertbl-col-pchgval {
    text-align: right
}

#dwselector .uppertbl td {
    padding: 15px;

}

#dwselector .bidprice.val {
    font-size: 40px;
    line-height: 40px;
}

#dwselector #srtable {
    border-bottom: 1px #CCCCCC dotted;
}

#dwselector #srtable>table {
    table-layout: fixed;
    margin-bottom: 0;
}

#dwselector #srtable .srtable-leftlbl {
    width: 30%;
}

#dwselector #srtable .srtable-leftval {
    width: 20%;
    /*border-right:20px solid #fff;*/
}

#dwselector #srtable .srtable-rightlbl {
    width: 30%;
    /*border-left:20px solid #fff;*/
}

#dwselector #srtable .srtable-rightval {
    width: 20%;
}


#dwselector #pstable,
#dwselector #indtable {
    display: table;
    width: 100%;
    margin-bottom: 20px;
}

.indicator_table {
    margin-bottom: 5px !important;
}

#dwselector #pstable {
    margin-bottom: 40px;
}

#dwselector #indtable table {
    table-layout: fixed;
}

#dwselector #indtable table th {
    font-weight: normal;
    color: #0675C2;
}

#dwselector #indtable table.visible-md th {
    width: 25%
}

#dwselector #chartbox {}

#dwselector .ctab {
    width: 100%;
    margin-bottom: 20px;
}

#dwselector .ctab-item {
    text-align: center;
    width: 25%;
    border: 2px solid #FECC0A !important;
    height: 33px;
    cursor: pointer;
}

#dwselector .ctab-item.active {
    background-color: #FECC0A;
}

#dwselector .ctab-item-space {
    width: 12px;
}

#dwselector .chart-legend {
    margin-bottom: 20px;
}

#dwselector .chart-legend-left,
#dwselector .chart-legend-right {
    text-align: center;
}

#dwselector .chart-legend-bar {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 3px;
    bottom: 4px;
    margin-left: 5px;
}

#dwselector .chart-legend-bar.warrant {
    background-color: #F7941E;
}

#dwselector .chart-legend-bar.underlying {
    background-color: #097CCF;
}

#dwselector .chart-legend-tbl {
    width: 200px;
    margin: 0 auto;
}

#dwselector .nochart {
    width: 100%;
    height: 100%;
    border: 1px solid #CCC;
    position: relative;
}

#dwselector .nochart-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

#dwselector #dwselector-disclaimer table {
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}

#dwselector #dwselector-disclaimer table tr>td:first-child {
    white-space: nowrap;
}

#dwselector #defination {
    margin-top: 10px;
}

#dwselector #defination .panel-heading {
    background-color: #666666;
    padding: 4px 10px;
}

#dwselector #defination .panel-title {
    font-size: 14px;
}

#dwselector #defination .glyphicon {
    color: white;
}

#dwselector #defination #collapse_dwcompare3 {
    font-size: 12px;
    padding: 10px;
}

#dwselector #defination .table {
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}

#dwselector #defination .table td {
    padding-left: 0;
}

#dwselector #defination .table tr td:first-child {
    white-space: nowrap;
}

#dropdown-dwterms {
    margin-top: 10px;
    margin-bottom: 30px;
}

#dwselector .ricbar {
    position: relative;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 10px;
    height: 36px;
}

#dwselector .tickerbar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding-left: 8px;
    padding-top: 8px;
    right: 200px;
    background-color: #DFDBCF !important;
}

#dwselector .ricbar-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 190px;
    bottom: 0;
}

.underlying_list {
    max-height: 600px;
    overflow-y: scroll;
}

.underlying_list_x_scroll {
    width: 100%;
    overflow-x: scroll;
    position: relative;
}

#underlying_table {
    width: 100%
}

#underlying_table tr {
    height: 70px;
    cursor: pointer;
}

#underlying_table .underlying_name {
    text-align: center;
    color: #0675C2;
    line-height: 1;
}

#underlying_table .uprice {
    color: #333333;
}

#underlying_table_home {
    width: 100%
}

#underlying_table_home tr {
    height: 70px;
    cursor: pointer;
    display: table-cell;
}

#underlying_table_home tr>* {
    display: block;
}

#underlying_table_home .underlying_name {
    width: 100px;
    text-align: center;
    color: #0675C2;
    line-height: 1;
}

#underlying_table_home .uprice {
    color: #333333;
}

.btn_area {
    display: flex;
    /*justify-content: space-between;*/
    /*margin-top: 20px;*/
}

.less {
    text-align: center;
    margin: auto;
    padding: 1px 2px;
    line-height: 1;
    margin-top: 3px;
    border: 2px solid #A6A6A6;
    background-color: #7F7F7F;
    cursor: pointer;
    color: #FFF;
    font-weight: bold;
}

.plus {
    text-align: center;
    margin: auto;
    padding: 1px 2px;
    line-height: 1;
    border: 2px solid #A6A6A6;
    cursor: pointer;
    font-weight: bold;
}

.btn_target .plus {
    color: #FFF;
}

.trending_warrant table {
    width: 100%;
    text-align: center;
}

.trending_warrant table th {
    text-align: center;
    font-size: 14px;
}

.trending_warrant table thead {
    background-color: #FFC000;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
}

.trending_warrant table tbody {
    background-color: #E6E6E6;
    border-bottom: 2px solid #000000;
    line-height: 1;
}

.trending_warrant table td {
    padding: 10px 0;
}

#qtip-0,
#qtip-1,
#qtip-2,
#qtip-3 {
    margin-left: 10px;
}

.pn_icon {
    width: 12px;
    /*position: absolute;*/
    right: -5px;
    top: 50%;
}

.price_area {
    /* display: flex;
    justify-content: space-between;  */
    margin-bottom: 35px;
}

.row_BID,
.row_today,
.row_week,
.row_holding_period {
    font-weight: bold;
}

.last_label {
    vertical-align: middle;
    padding-left: 20px;
    margin: auto 0px;
    font-size: 20px;
}

.main_row {
    margin-left: -20px;
    margin-right: -20px;
}

#dwselector .glyphicon {
    position: relative;
    top: -70px;
    height: 70px;
}

.swiper_left {
    top: 0;
    position: absolute;
    bottom: 0;
    vertical-align: middle;
    text-align: center;
    left: 0;
    width: 30px;
    background-color: rgba(146, 146, 146, 0.5);
    padding-top: 25px;
}

.swiper_right {
    top: 0;
    position: absolute;
    bottom: 0;
    vertical-align: middle;
    text-align: center;
    right: 0;
    width: 30px;
    background-color: rgba(146, 146, 146, 0.5);
    padding-top: 25px;
}

.swiper_up {
    left: 10px;
    position: absolute;
    right: 10px;
    vertical-align: middle;
    text-align: center;
    top: 0;
    height: 30px;
    background-color: rgba(146, 146, 146, 0.5);
}

.swiper_down {
    left: 10px;
    position: absolute;
    right: 10px;
    vertical-align: middle;
    text-align: center;
    top: calc(100% - 30px);
    height: 30px;
    background-color: rgba(146, 146, 146, 0.5);
}

.current_return {
    padding-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.val_target_price {
    font-size: 14px;
}

#target_price_num {
    width: 50%;
}

.holding_text {
    padding-right: 40px;
}

/*******************************************************************************
 dwterms section combobox
*******************************************************************************/
.combobox {
    position: relative;
}

.combobox-toggle {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 36px;
    padding: 0;
    cursor: pointer;
}

.combobox-toggle,
.combobox-toggle:focus,
.combobox-toggle:hover,
.combobox-toggle:visited {
    color: #000;
}

.combobox-input {
    margin: 0;
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}

.combobox-toggle-icon {
    margin-top: 10px;
    margin-right: 12px;
}

.ui-widget {
    /* font-family: 'HelveticaNeueLight', 'HelveticaNeue', 'Roboto', Arial, sans-serif; */
    font-size: 14px;
}

.ui-menu .ui-menu-item {
    text-align: center;
}

.ui-menu .ui-menu-item a.ui-state-hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
}

.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
    color: #262626
}

.ui-autocomplete.ui-menu {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

/*******************************************************************************
 How to get started
*******************************************************************************/
.page-header {
    display: flex;
    justify-content: space-between;
}

.tradeDropDown {
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 370px;
    padding: 10px 0;
}

.tradeDropDown a {
    margin-bottom: 5px;
}

.selectedTrade,
.selectedTrade:hover,
.selectedTrade:focus {
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

.tradeDropDown .btn-primary:hover,
.tradeDropDown .btn-primary:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

.tradeDropDown .broker_link {
    margin-bottom: 5px;
    width: 45%;
}

/*******************************************
popupbox
*********************************************/

.selector-loadingindicator {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../../../../public/img/bullet/loading.gif") center center no-repeat;
    background-color: white;
    z-index: 990;
    -khtml-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-filter: "alpha(opacity=60)";
    filter: alpha(opacity=60);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
    opacity: 0.6;
}

#resulttable tr:not(:first-child) .hideRow {
    display: none;
}

/* 新 */
.select_img_row {
    display: flex;
}

.select_img {
    display: flex;
}

.instruction3 {
    background-color: #FFC000;
    margin: 0px 0px 0px 10px;
    padding: 5px;
}

.current_return {
    padding-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.trending_warrant table {
    width: 100%;
    text-align: center;
}

.trending_warrant table thead {
    background-color: #FFC000;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
}

.trending_warrant table th {
    text-align: center;
}

.trending_warrant .trending_warrant_input {
    display: flex;
    /* display: block; */
    justify-content: flex-start;
}

.trending_warrant .trending_warrant_input_box {
    position: relative;
    width: 60%;
    margin-right: 10px;
}

.up {
    color: #6CC263;
}

.down {
    color: #C61C2D;
}

.plus2 {
    position: absolute;
    display: block;
    top: 1px;
    bottom: 50%;
    right: 5%;
    width: 14px;
    padding: 0;
    cursor: pointer;
}

.plus2 img {
    width: 100%;
    height: 14px;
    vertical-align: top;
}

.less2 {
    position: absolute;
    display: block;
    top: 50%;
    bottom: 1px;
    right: 5%;
    width: 14px;
    padding: 0;
    cursor: pointer;
}

.less2 img {
    width: 100%;
    height: 14px;
    vertical-align: top;
}

.trending_warrant table td {
    padding: 10px 0;
}

a,
a:focus,
a:hover,
a:visited {
    color: #0675C2;
    text-decoration: none;
}

.instruction2 {
    background-color: #CEE5F5;
    margin: auto 10px;
    padding: 5px;
}

.selectSption:hover {
    background: #007FFF;
}

@media screen and (max-width: 1150px) {
    .trending_warrant .trending_warrant_input {
        display: block;
    }

    .trending_warrant .trending_warrant_input_box {
        width: 100%;
    }
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 991px) {}

@media screen and (max-width: 767px) {
    #dwselector h3 {
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    #dwselector .uppertbl {
        margin-top: 0;
    }

    #dwselector .uppertbl td {
        padding: 4px 10px;
    }

    #dwselector .uppertbl-col-bid {
        border-right: 0;
        width: 40%;
    }

    #dwselector .uppertbl-col-bid .lbl,
    .uppertbl-col-chglbl,
    .uppertbl-col-pchglbl {
        font-size: 12px;
    }

    #dwselector .uppertbl-col-bid .val {
        font-size: 14px;
        line-height: normal;
    }

    #dwselector .uppertbl-col-chgval,
    .uppertbl-col-pchgval {
        font-size: 16px;
    }

    #dwselector .uppertbl-col-chglbl {
        border-bottom: 0;
        width: 35%;
    }

    #dwselector .uppertbl-col-chgval {
        border-bottom: 0;
        width: 25%;
    }

    #dwselector #srtable>table {
        font-size: 12px;
    }

    #dwselector #srtable .srtable-leftlbl {
        width: 15%;
    }

    #dwselector #srtable .srtable-leftval {
        border-left: 0;
    }

    #dwselector #srtable .srtable-rightlbl {
        border-right: 0;
    }

    #dwselector #indtable table {
        font-size: 11px;
    }

    #dwselector #indtable {
        margin-bottom: 10px;
    }

    #dwselector #indtable table {
        margin-bottom: 0;
    }

    #dwselector #dwselector-disclaimer table {
        font-size: 11px;
    }

    #dwselector p.small {
        font-size: 11px;
    }

    #dwselector #defination #collapse_dwcompare3 {
        font-size: 11px;
    }

    #dwselector .ricbar {
        height: 25px;
    }

    #dwselector .ricbar-btn {
        /* font-family: HelveticaNeueLight; */
        width: 130px;
        padding: 0;
    }

    #dwselector .tickerbar {
        font-size: 14px;
        text-align: center;
        right: 140px;
        overflow: hidden;
        line-height: 25px;
    }

    .tradeDropDown {
        width: 320px;
    }

    .underlying_list table tr>* {
        display: block;
    }

    .underlying_list table tr {
        display: table-cell;
    }

    #underlying_table .underlying_name {
        padding: 10px;
    }

    #underlying_table tr {
        padding: 0 10px;
    }

    .underlying_list {
        width: 100%;
        overflow-x: scroll;
    }

    .main_row {
        margin-left: -10px;
        margin-right: -10px;
    }

    .val_target_price,
    btn_area,
    val_holding_period {
        font-size: 12px;
    }

    .h2,
    h2 {
        font-size: 25px;
    }

    .last_label {
        padding-left: 0;
        font-size: 17px;
    }

    #target_price_num {
        width: 100%;
        /* max-width: 110px; */
    }

    .holding_text {
        padding-right: 30px;
    }

    .current_return {
        font-size: 15px;
    }

    #resulttable {
        font-size: 11px;
    }

    .swiper_right {
        right: -300px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .underlying_list {
        padding: 30px 0;
    }
}
@media screen and (max-width: 767px){
    .trending_warrant table th{
        font-size: 9px;
    }
    .page .plus2 ,.page .less2{
        width: 15px;
        left: -16%;
    }
    .page .plus2 img,.page .less2 img{
        height:10px
    }
    .home .plus2 { 
        top:0px
    }
}