/* #landingPage .banner{
    width:100%;
    height:450px;
    background: url('../../../public/img/landingPage/Headline image.jpg');
     background: url('../../../public/img/landingPage/Headline image.jpg') no-repeat 0 0 / 100% 100% ;
    background-repeat: round;
} */
#landingPage{
  font-family: 'MCQ_Global_App_Medium';
}
#landingPage .banner img{
    width:100%;
}
#landingPage #accordion1 img{
    width:100%;
}
#landingPage .title{
    margin: 0px 0px 0px 15px;
    padding: 10px 0px;
    color:#29949e;
    font-size: 40px;
    background:#F0F0F0;
    margin-left: 40px;
}
/* #landingPage .navbar-inverse.navbar-larger .navbar-brand {
    height: auto;
    padding: 13px 50px;
} */
#landingPage .Whatare_text_img {
    width:50%;
    margin-top:15px;
}
#landingPage .main{
    width:100%;
    /* height:200px; */
    padding: 15px 40px 20px 40px;
    background:#29949e;
}
#landingPage .main .main_top {
    display:flex;
    align-items: center;
}
#landingPage .main .main_bottom{
    color:#fff;
    font-size: 18px;
}
#landingPage .homepage_text{
    font-size: 18px;
}
#landingPage .homepage_text a{
    color:#000!important;
    text-decoration: underline;
}
#landingPage .main .main_bottom a{
    text-decoration: underline;
}
#landingPage .main .main_top img{
    width: 88px;
    height: 88px;
    margin-right: 10px;
}
#landingPage .main .main_top p,#landingPage .main .main_top h3{
    color:#fff;
}
#landingPage .main .main_top p{
    font-size: 18px;
    margin:0px;
    margin-top:7px;
}
#landingPage .main .main_top h3{
    font-size: 40px;
    margin:0px
}
#landingPage #heading1 .panel-title ,#landingPage #heading2 .panel-title{
    font-size: 24px;
    color:#29949e;
    margin-left: 5px;
    /* font-weight: bold; */
}
#landingPage #heading1 ,#landingPage #heading2{
    border: 2px solid #499C9E;
    background: #CCCCCC;
    border-radius: 5px;
}
#landingPage .panel {
    border:0px;
}
#landingPage a:focus{
    color:unset;
}
#landingPage .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border:0px;
}
#landingPage .panel-default>.panel-heading{
    background:#064258;
    border-radius: 5px;
    color:#CCCCCC;
}
#landingPage .panel-title{
    font-size: 24px!important;
}
#collapse2 .panel-body{
    padding:15px 0px;
}
#landingPage  .panel-collapse>div {
    height:auto;
    max-height: revert;
}
#landingPage .topMovers{
    position: absolute;
    top: 60px;
    left: 41px;
    width: 100px!important;
    height: 30px;
}
#landingPage .liveIndex{
    position: absolute;
    top: 350px;
    right: 268px;
    width: 100px!important;
    height: 30px;
}
#landingPage .Live{
    position: absolute;
    top: 58px;
    right: 191px;
    width: 178px!important;
    height: 30px;
}
#landingPage .WarrantSelector{
    position: absolute;
    bottom: 30px;
    left: 43px;
    width: 129px!important;
    height: 30px;
}
#landingPage .topMovers_arrow{
    position: absolute;
    top: 90px;
    left: 87px;
    width:90%!important;
}
#landingPage .liveIndex_arrow{
    position: absolute;
    top: 357px;
    left: 514px;
    width: 35%!important;
}
#landingPage .Live_arrow{
    position: absolute;
    top: 68px;
    left: 586px;
    width: 26%!important;
}
#landingPage .WarrantSelector_arrow{
    position: absolute;
    bottom: 35px;
    left: 178px;
    width: 78%!important;
}
#landingPage .topMovers_p{
    position: absolute;
    right: 0px;
    width: 100%;
    top: 175px;
    background: #268F92;
    color:#fff;
    font-size: 12px;
    padding:5px;
}
#landingPage .liveIndex_p{
    position: absolute;
    right: 0px;
    width: 100%;
    top: 330px;
    background: #268F92;
    color:#fff;
    font-size: 12px;
    padding:5px;
}
#landingPage .Live_p{
    position: absolute;
    right: 0px;
    width: 100%;
    top: 25px;
    background: #268F92;
    color:#fff;
    font-size: 12px;
    padding:5px;
}
#landingPage .WarrantSelector_p{
    position: absolute;
    right: 0px;
    width: 100%;
    top: 512px;
    background: #268F92;
    color:#fff;
    font-size: 12px;
    padding:5px;
}
#landingPage .Warrant_tools{
    position: absolute;
    top: 3px;
    left: 336px;
    width: 98px!important;
    height: 30px;
}
#landingPage .Warrant_tools_arrow{
    position: absolute;
    bottom: 135px;
    left: 379px;
    width: 54%!important;
}
#landingPage .Warrant_tools_p{
    position: absolute;
    right: 0px;
    width: 100%;
    top: 42px;
    background: #268F92;
    color:#fff;
    font-size: 12px;
    padding:5px;
}
#landingPage .Market_news{
    position: absolute;
    top: 3px;
    left: 422px;
    width: 98px!important;
    height: 30px;
}
#landingPage .Market_news_arrow{
    position: absolute;
    bottom: 44px;
    left: 465px;
    width: 43%!important;
}
#landingPage .Market_news_p{
    position: absolute;
    right: 0px;
    width: 100%;
    top: 10px;
    background: #268F92;
    color:#fff;
    font-size: 12px;
    padding:5px;
}
#landingPage .warrant_education{
    position: absolute;
    top: 3px;
    left: 516px;
    width: 98px!important;
    height: 30px;
}
#landingPage .warrant_education_arrow{
    position: absolute;
    bottom: 106px;
    left: 557px;
    width: 31%!important;
}
#landingPage .warrant_education_p{
    position: absolute;
    right: 0px;
    width: 100%;
    top: 108px;
    background: #268F92;
    color:#fff;
    font-size: 12px;
    padding:5px;
}
#landingPage .warrant_statistics{
    position: absolute;
    top: 3px;
    left: 610px;
    width: 98px!important;
    height: 30px;
}
#landingPage .warrant_statistics_arrow{
    position: absolute;
    bottom: 45px;
    left: 667px;
    width: 18%!important;
}
#landingPage .warrant_statistics_p{
    position: absolute;
    right: 0px;
    width: 100%;
    top: 37px;
    background: #268F92;
    color:#fff;
    font-size: 12px;
    padding:5px;
}
#landingPage .bottom{
    background: #A3D4D9;
    padding:20px 40px 0px 40px;
    display: flex;
    justify-content: space-between;
}
#landingPage .bottom .bottom_left{
    display: flex;
    flex-direction: column;
}
#landingPage .bottom .bottom_left>div{
    color:#fff;
    background:#004559;
    padding:5px 15px;
    text-align: center;
    line-height: 20px;
    max-width: 299px;
}
#landingPage .bottom .bottom_left a{
    color:#fff;
    font-size: 18px;
}
#landingPage .bottom .bottom_left p{
    color:#29949e;
    margin-top:5px;
    font-size: 18px;
    text-align: center;
}
#landingPage .bottom .bottom_right{
    display: flex;
}
#landingPage .bottom .bottom_right div{
    margin:0px 15px;
}
#landingPage .bottom .bottom_right img{
    width:60px;
    height:60px;
    margin: 2px 5px;
}
#landingPage .bottom .bottom_right .youtu_img img{
    width: 70px;
    height: 48px;
    margin: 2px 5px;
    margin-top: 13px;
}
#landingPage .bottom .bottom_center p{
    color:#fff;
    background:#004559;
    padding:2px 15px;
    border-radius: 5px;
    text-align: right;
}
#landingPage .bottom .bottom_right p{
   text-align: center;
   color:#29949e;
   font-size: 10px;
    margin-top: 6px;
}
#landingPage .rights{
    background: #A3D4D9;
    padding:25px 40px;
    margin-top: 2px;
    font-size: 12px;
    
    /* display: flex;
    justify-content: space-between; */
    
}
#landingPage .rights p,#landingPage .rights a{
    color:#29949e;
    text-decoration: revert;
}
#landingPage .Whytrade{
    font-size: 18px;
    font-family: 'MCQ_Global_App'
}
#landingPage .Whytrade h3{
    font-size: 40px;
    margin-top:0px;
    color:#004559;
}
#landingPage .Whytrade h4{
    font-size: 20px;
    color:#004559;
    margin-bottom: 20px;
}
#landingPage .Whytrade p{
    color:#004559;
}
#landingPage .collapsed>.glyphicon{
    transform: rotate(0deg)!important;
    color:#000;
}
#landingPage .glyphicon {
    transform: rotate(90deg)!important;
    color:#29949e;
}
/* #landingPage #collapse2 .glyphicon-triangle-bottom:before{
    color:#000;499C9E
} */
                        
@media screen and (max-width: 992px) {
    #landingPage{
        margin-top:52px!important;
    }
    #landingPage .Whatare_text_img {
        width:100%;
        margin-top:0px;
    }
    #landingPage .banner .banner_text{
        position: absolute;
        z-index: 11;
        opacity: .8;
    }
    #landingPage .panel-default>.panel-heading{
        background:#064258;
        border-radius: 5px;
    }
    #landingPage .whatAre_img{
        padding:0px!important;
    }
    #landingPage .title{
        font-size:20px!important;
        font-family: 'MCQ_Global_App';
        font-weight: bold;
        margin-left: 10px;
    }
    #landingPage .main .main_bottom {
        font-size: 12px;
        padding:0px;
    }
    #landingPage .main .main_top h3{
        font-size: 22px;
    }
    #landingPage .main .main_top p {
        font-size: 15px;
    }
    #landingPage .main .main_top {
        padding: 0px;
        /* justify-content: center; */
    }
    #landingPage .main .main_top img {
        width: 60px;
        height: 50px;
        margin: 10px 10px 10px 23px;
    }
    #landingPage .main {
        padding: 7px 10px;
    }
    /* #landingPage .section {
        margin-top: 10px;
    } */
    #landingPage .panel-heading {
        padding:5px 10px!important;
    }
    #landingPage .sitemap-othr  {
        text-align: left!important;
    }
    #landingPage .rights{
        padding:5px;
    }
    #landingPage .bottom {
        padding: 0px 15px;
        font-family: 'MCQ_Global_App';
    }
    #landingPage .bottom .bottom_left>div{
        padding: 5px 5px 4px 5px;
        margin: 0px;
        min-width: 110px;
        line-height: 9px;
        border: 1px solid #29949e;
    }
    #landingPage .bottom .bottom_right p {
        font-size: 9px;
    }
    #landingPage .bottom .bottom_left a {
        font-size: 9px;
    }
    #landingPage .bottom .bottom_right img{
        width:30px;
        height:30px;
        margin: 2px 3px;
    }
    #landingPage .bottom .bottom_right .youtu_img img{
        margin:0px;
        width: 35px;
        height: auto;
        margin-top: 6px;
        margin-left:3px;
    }
    #landingPage .bottom .bottom_left p {
        font-size: 9px;
        line-height: 9px;
        margin-top: 3px;
    }
    #landingPage .bottom .bottom_right {
        padding-top: 35px;
    }
    #landingPage .bottom_center{
            position: absolute;
            top: 5px;
            left: 0px;
    }
    #landingPage .bottom .bottom_left{
        margin-top:15px;
        flex-shrink: 0;
    max-width: 130px;
    }
    #landingPage .bottom_center p{
        font-size: 12px!important;
    }
    #landingPage .bottom .bottom_right div {
        margin:0px;
    }
    #landingPage .Whytrade h3 {
        font-size: 18px;
    }
    #landingPage .Whytrade h4 {
        font-size: 16px;
    }
    #landingPage .Whytrade {
        font-size: 13px;
        margin-top: 0px;
    }
    #landingPage .Whatare_text>div {
        padding:10px!important;
        font-size: 13px!important;
        color: #004559;
    }
    #landingPage .Whatare_text_img1 img {
        width: 55px!important;
    height: 50px!important;
    margin: 7px!important;
    }
    #landingPage .panel-title {
        font-size: 16px!important;
    }
    #landingPage .homepage_text {
       font-family: 'MCQ_Global_App';
       font-size: 12px;
    }
    #landingPage .bottom .bottom_center p{
        font-size: 10px!important;
    }
    #landingPage .sitemap-corp p {
        font-size: 10px!important;
        margin: 5px 0px;
    }
    #landingPage .sitemap-othr p {
        font-size: 10px!important;
    }
}
@media screen and (max-width: 420px) {
    #landingPage .banner_p{
        margin-top:0px!important;
    }
}
