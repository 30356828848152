#todayhighlight .section-p {
    margin-bottom: 30px;
    min-height: inherit;
}

#todayhighlight .newstbl-htxt {
    /* font-family: "HelveticaNeueLight"; */
    height: 30px;
    line-height: 30px;
    background-color: #CCC;
    padding-left: 10px;
}

#todayhighlight .newstbl-row {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #CCC;
}

#todayhighlight .newstbl-content {
    margin-bottom: 40px;
}

.input-group-addon .react-datepicker__input-container input {
    outline: none;
    border: none;
    cursor: pointer;
}

#todayhighlight .newstbl-content .newstbl-row:last-child {
    border-bottom: 1px solid #FFF;
}

#todayhighlight .newstbl-col-right {
    margin-left: 100px;
}

#todayhighlight .newstbl-col-left {
    position: absolute;
    top: 26px;
    left: 0;
    text-align: left;
}

#todayhighlight .newstbl-col-left span {
    /* font-family: "HelveticaNeueMedium"; */
    color: #0675C2;
}

#todayhighlight .newstbl-col-dt {
    /* font-family: "HelveticaNeueMedium"; */
    margin: 0;
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 6px;
}

#todayhighlight .newstbl-col-dd {
    margin-bottom: 30px;
}

#todayhighlight .newstbl-col-rm {
    text-align: right;
}

#todayhighlight .newstbl-col-rm .btn {
    width: 150px;
}

#todayhighlight .newstbl-bttm {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 32px;
    margin-bottom: 20px;
}

#todayhighlight .newstbl-cal-icon.input-group {
    position: absolute;
    top: -25px;
    right: 0px;
    display: inline-block;
    border-collapse: inherit;
}

#todayhighlight .input-group-addon {
    text-align: right;
    padding: 0;
    color: inherit;
    background-color: inherit;
    border: 0;
    border-radius: inherit;
    display: inline-block;
}

#todayhighlight .newstbl .swiper-container {
    width: 100%;
    min-height: 300px;
}

#todayhighlight .newstbl .swiper-pagination {
    position: absolute;
    top: -8px;
    left: 35%;
}

#todayhighlight .newstbl .swiper-container {
    width: 100%;
    min-height: 300px;
}

#todayhighlights .newstbl .swiper-pagination {
    position: inherit;
}

#todayhighlight .swiper-wrapper {
    margin-top: 0;
}

.react-datepicker__triangle{
    left: 12px !important;
}
@media screen and (max-width:767px) {
    #todayhighlight .newstbl .swiper-pagination {
        left: 25%;
    }
}
@media screen and (max-width:991px) {
    .react-datepicker-popper{
        right: -4px !important;
        left: unset !important;
    }
    .react-datepicker__triangle{
        right: 4px !important;
        left: unset !important;
    }
}