.liveMatrixTable tbody .col_expr {
    display: none;
}

.liveMatrixTable td,
.liveMatrixTable th {
    font-size: 14px !important;
}
@media screen and (max-width: 767px){
    .minW62{
        min-width: 62px;
    }
    .minW82{
        min-width: 82px;
    }
    .minW100{
        min-width: 100px;
    }
}