#dwvideo .swiper-wrapper {
    margin-top: 0;
    color: #333;
    padding: 5px;
}

#dwvideo .swiper-wrapper .swiper-pagination {
    right: 47%;
}

#dwvideo .swiper-botton .left,
#dwvideo .swiper-botton .right {
    width: 30px;
    height: 100%;
    background-color: rgba(245, 246, 247, 1);
}

#dwvideo .gallery,
#dwvideo .videorack {
    margin-left: -40px;
    margin-right: -40px;
}

#dwvideo .videorack .videorack-title {
    padding-left: 40px;
    padding-right: 40px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(204, 204, 204, 1);
    font-size: 20px;
    margin-left: 5px;
}

#dwvideo .videorack-body {
    position: relative;
    overflow: hidden;
    margin: 20px 40px;
    padding-left: 30px;
    padding-right: 30px;
}

#dwvideo .videorack-page {
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
    display: table-cell;
}

#dwvideo .videorack-page td {
    max-width: 330px;
    border-left: 10px solid white;
    border-right: 10px solid white;
    vertical-align: top;
}

#dwvideo .videorack-item {
    width: 100%;
    cursor: pointer;
}

#dwvideo #edurack .videorack-item-txt {
    padding-bottom: 30px;
}

#dwvideo .videorack-item-txt {
    margin-top: 6px;
}

#dwvideo .gallery-nav.backward {
    left: 0;
}

#dwvideo .gallery-nav.forward {
    right: 0;
}

#dwvideo .videorack-nav-icon {
    display: block;
    position: absolute;
    width: 16px;
    height: 26px;
    left: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#dwvideo .gallery-nav.backward .videorack-nav-icon {
    background: url("../../../../public/img/bullet/nav-backward.png") center center no-repeat;
}

#dwvideo .gallery-nav.forward .videorack-nav-icon {
    background: url("../../../../public/img/bullet/nav-forward.png") center center no-repeat;
}

#dwvideo .swiper-button-disabled,
#dwvideo .swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none;
}