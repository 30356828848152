#unsubscribe{
    background-color: #000;
    color:#FFFFFF;
}
.unsubscribe_box{
    /*width: 400px;*/
    text-align: center;
    margin:30px 0 auto;

}
.unsubscribe_box>p,.unsubscribe_box>h3,.unsubscribe_box button{
    color:#FFFFFF;
}
.unsubscribe_box>h3{
    font-size: 40px;
}
.unsubscribe_box>p{
    font-size:26px
}
.unsubscribe_box button{
    font-size: 24px;
    /* margin-right: 10px; */
    width: 170px;
    margin-top: 20px;
}
.unsubscribe_box button:foucs{
    outline:0!important;
}
.unsubscribe_box p input{
    margin-right:25px;
}
#Popups-one img{
    height: 280px;
    width: 280px;
}
#Popups-two img{
    height: 280px;
    width: 280px;
}
.unsubscribe_box  input{
    width: 240px;
}
@media screen and (max-width: 695px) {
   #unsubscribe img{
    width: 180px;
    height: 180px;
   }
}