#wcalbox {
    margin-bottom: 30px;
}



.random {
    color: #999 !important;
}

#wcalbox>table {
    table-layout: auto !important;
    text-align: left;
    /*width:100%*/
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
}
#wcalbox .wcalbox_table td{
    width:50%;
}
#wcalbox>table tr td {
    /*padding:5px 30px 5px 0px;*/
    vertical-align: middle;
}

#wcalbox>table tr {
    height: 42px;
}

#wcalbox .wcalbox-row-lbl {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

#wcalbox .wcalbox-col-lbl {
    text-align: center;
    width: 215px;
    padding-left: 10px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 2.5rem !important;
}

#wcalbox .wcalbox-col-slider {
    padding-left: 10px;
    padding-right: 10px;
}

#wcalbox .wcalbox-col-input {
    padding-left: 10px;
}

#wcalbox .wcalbox-row-ctr {
    height: 62px;
    padding-bottom: 20px;
}

#wcalbox .wcalbox-input {
    height: 100%;
    text-align: center;
    width: 100%
}

#wcalbox .slider.slider-horizontal {
    width: 100%;
    margin-top: -6px;
}

#wcalbox .slider.slider-horizontal .slider-track {
    height: 14px;
}

#wcalbox .slider-handle {
    top: 1px;
}

#wcalbox .resetbtn {
    text-align: right;
}

#wcalbox .resetbtn a {
    min-width: 100px;
}

#pstable {
    margin-bottom: 30px;
    border-bottom: 1px #CCCCCC dotted;
}

.react-datepicker__day-name {
    font-size: 14px;
}


.react-datepicker__current-month {
    font-weight: 500 !important;
    font-size: 14px !important;
}

#pstable .tableSize td,
#pstable .tableSize th {
    font-size: 16px;
}

#pstable>table {
    table-layout: fixed;
    margin-bottom: 0;
}

#pstable th {
    width: 45%;
}

#srtable {
    margin-bottom: 20px;
}

#srtable>table {
    table-layout: fixed;
    margin-bottom: 0;
}

#srtable>table th,
#srtable>table td {
    vertical-align: middle;
}

#srtable .srtable-leftlbl {
    width: 30%;
}

#srtable .srtable-leftval {
    width: 20%;
    border-right: 20px solid #FFFFFF;
}

#srtable .srtable-rightlbl {
    width: 30%;
    border-left: 20px solid #FFFFFF;
}

#srtable .srtable-rightval {
    width: 20%;
}

#ivtable th,
#ivtable td {
    text-align: center;
}

#ivtable thead tr th {
    border-bottom: 0;
    background-color: #F5F6F7;
}

#pstable .table-striped>tbody>tr:nth-of-type(even) {
    background-color: #fff;
}

#ivtable th.active,
#ivtable td.active {
    /*background-color: #CEE5F5;*/
    background-color: #ffffff;
}

.bold_title {
    /* font-family: "HelveticaNeueMedium"; */
}

.tip_box {
    background-color: #FECC0A;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.iv-slider-top {
    border: dashed 2px #FECC0A;
    border-bottom: none;
}

.iv-slider-bottom {
    border: dashed 2px #FECC0A;
    border-top: none;
}

.iv-slider-full {
    border: dashed 2px #FECC0A;
    margin-left: 4px;
    padding-left: 4px;
    padding-right: 4px;
    width: calc(33.3333% - 8px);
}

/*******************************************************************************
 How to get started
*******************************************************************************/
.page-header {
    display: flex;
    justify-content: space-between;
}

/*******************************************************************************
 #wcalbox
*******************************************************************************/
#wcalbox {
    margin-bottom: 30px;
}

#wcalbox .wcalbox-row-lbl {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

#wcalbox .wcalbox-col-lbl {
    text-align: center;
    width: 215px;
    padding-left: 10px;
}

#wcalbox .wcalbox-col-slider {
    padding-left: 10px;
    padding-right: 10px;
}

#wcalbox .wcalbox-col-input {
    padding-left: 10px;
}

#wcalbox .wcalbox-row-ctr {
    height: 62px;
    padding-bottom: 20px;
}

#wcalbox .wcalbox-input {
    height: 100%;
    text-align: center;
    width: 100%
}

#wcalbox .slider.slider-horizontal {
    width: 100%;
    margin-top: -6px;
}

#wcalbox .slider.slider-horizontal .slider-track {
    height: 14px;
}

#wcalbox .slider-handle {
    top: 1px;
}

#wcalbox .resetbtn {
    text-align: right;
}

#wcalbox .resetbtn a {
    min-width: 100px;
}

#pstable {
    margin-bottom: 30px;
    border-bottom: 1px #CCCCCC dotted;
}

#pstable>table {
    table-layout: fixed;
    margin-bottom: 0;
}

#pstable th {
    width: 45%;
}

#srtable {
    margin-bottom: 20px;
}

#srtable>table {
    table-layout: fixed;
    margin-bottom: 0;
}

#srtable>table th,
#srtable>table td {
    vertical-align: middle;
}

#srtable .srtable-leftlbl {
    width: 30%;
}

#srtable .srtable-leftval {
    width: 20%;
    border-right: 20px solid #FFFFFF;
}

#srtable .srtable-rightlbl {
    width: 30%;
    border-left: 20px solid #FFFFFF;
}

#srtable .srtable-rightval {
    width: 20%;
}

#wcalbox .tablist {
    position: relative;
    height: 60px;
    margin-top: 20px;
}
#wcalbox .tablist-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
#wcalbox .tablist-left table {
    table-layout: fixed;
}
#wcalbox .tablist-left table td {
    border-right: 10px solid #fff;
}
#wcalbox .tablist-item {
    width: 130px;
    height: 55px;
    display: table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5px solid #E0E0E0;
    background-color: #EEF0F2;
    cursor: pointer;
}
#wcalbox .tablist-item-txt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
}
#wcalbox .tablist .active .tablist-item {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}
#wcalbox .tablist .tablist-item:focus,
#wcalbox .tablist .tablist-item:hover {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}
#wcalbox #minidwcalc table tr>td:last-child {
    width: 50%;
}

#wcalbox #minidwcalc table,
#wcalbox #minidwcalc table input {
    width: 100% !important;
}

#wcalbox #minidwcalc .slider.slider-horizontal {
    width: 100% !important;
}

#wcalbox #minidwcalc .hidden-xs, #wcalbox #minidwcalc .hidden-sm {
    display: table !important;
}
#wcalbox_right_tb .wcalbox-col-slider {
    width: 100% !important;
}
.table-striped-inverse th,
.table-striped-inverse td{
    min-width: 125px;
}

.isShowMobile{
    display: none;
}
.isShowPC{
    display: revert;
}
@media screen and (max-width: 992px) {
    #wcalbox .wcalbox_table td{
        width:100%;
    }
    #wcalbox_right_tb .wcalbox_table td{
        width:100%;
    }
    #livematrix #minidwcalc #wcalbox_right_tb .hidden-xs, #livematrix #minidwcalc #wcalbox_right_tb .hidden-sm {
        display: none !important;
    }
    #dwterms #minidwcalc #wcalbox_right_tb .hidden-xs, #dwterms #minidwcalc #wcalbox_right_tb .hidden-sm {
        display: none !important;
    }
}
@media screen and (max-width: 767px) {
    .isShowPC{
        display: none !important;
    }
    .isShowMobile{
        display: revert !important;
    }
}