/*******************************************************************************
For indexdw page overall
*******************************************************************************/
#telegram {
    margin-top: 0;
}

#telegram img {
    max-width: 100%;
}

#telegram .col-xs-1 img {
    max-width: none;
}

.fb_iframe_widget {
    display: block !important;
}

#telegram .page-header {
    margin-bottom: 20px;
}

.welcometop {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: -40px;
}

.welcometop .welcomeimgtop img {
    width: 100%;
    margin-top: 70px;
}

.welcometop .welcomeimgtop2 img {
    width: 100%;
    margin-top: 50px;
}

.welcometop .welcomeimg img {
    width: 100%;
    margin-top: 25px;
}

.welcometop .welcomeimg .welcomeimg-achor {
    position: absolute;
    top: 17%;
    bottom: 58%;
    left: 5%;
    right: 53%;
}

.navbox {
    text-align: center;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.navbox.howjoin {
    text-align: left;
}

.video-wrapper {
    display: block;
    width: 100%;
    height: auto;
}

.video-wrapper video {
    width: 100%;
    height: auto;
}

.jumbotron {
    position: absolute;
    width: 100%;
    background: transparent;
    top: 70px;
    left: 0;
}

.jumbotron .container-fluid {
    text-align: center;
}

.jumbotron p {
    margin-bottom: 5px;
}

.sharebtn a,
.sharebtn button {
    margin: 0 0 5px 5px;
    height: 62px;
    width: 210px;
    display: block;
    border-radius: 8px;
}

/*******************************************************************************
 tabnav
*******************************************************************************/
.tabnav-row {
    margin-left: 0;
    margin-right: 0;
    margin-top: -50px;
    /*background: #ffffff;*/
    padding-bottom: 30px;
}

#telegram .tabnav-item {
    padding: 0px 5px 20px 5px;
    text-align: center;
    cursor: pointer;
    max-width: 255px;
    margin: auto;
    min-height: 150px;
}

#telegram .tabnav-item {
    color: #fff;
    position: absolute;
    top: 30%;
    width: 100%;
    padding-left: 60px;
    padding-right: 80px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.imgtab {
    cursor: pointer;
    margin-bottom: 0 !important;
}

.jumbotron h1 {
    font-size: 38px;
    margin-bottom: 0;
}
.jumbotron .broker_img{
    width: 90%;
    margin: 0 auto;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.jumbotron img {
    width: 42px;
    height: 42px;
    min-width: 42px;
}

.howToJoin,
.howToJoin .navbox {
    padding-left: 0px;
    padding-right: 0px;
}

.phoneScreen {
    position: absolute;
    top: 17%;
    left: calc(5% + 20px);
    right: calc(6% + 18px);
    bottom: 10%;
    overflow: hidden;
    overflow-y: scroll;
}

.joinLink {
    cursor: pointer;
    position: absolute;
    top: 90%;
    left: calc(5% + 20px);
    right: calc(6% + 18px);
    height: calc(4% + 5px);
}

.screenScroll {
    overflow-y: scroll;
}

/*******************************************************************************
 section
*******************************************************************************/
.section#tab1 {
    background: #ffffff;
    padding-bottom: 5px;
    margin-bottom: 0px;
}

#section1 {
    margin-top: -50px;
    position: absolute;
}

#telegram .page-header p {
    font-size: 24px;
}

.howJoinTitle {
    font-weight: 600;
    border-bottom: 2px solid #0066A5;
    padding-bottom: 5px;
    margin-top: 10px;
}

.howJoinSubTitle {
    font-weight: 600;
}

.pointer {
    cursor: pointer;
}

.alldone {
    background-color: #0066A5;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section.alldone {
    background-color: #0066A5;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.alldone p {
    color: #ffffff;
    font-weight: 600;
    padding-right: 10px;
    margin: 0;
}

.video_title {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}

.video_img {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin: auto;
}

#video_ep1,
#video_ep2,
#video_ep3,
#video_ep4,
#video_ep5 {
    margin: 5px;

}

.video_img img {
    width: 100%;
    max-width: 265px;
}

.section#tab3 {
    background-color: #0066A5;
    color: #ffffff;
}

.section#tab3 a {
    color: #ffffff;
}

.contact_title {
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}

#tab3 img {
    padding-left: 15px;
    padding-right: 10px;
}

.contact_img {
    max-width: 700px;
    margin: auto;
}

.contact_img td {
    padding-bottom: 10px;
}

/*******************************************************************************
tab
*******************************************************************************/
.ctab {
    width: 100%;
    margin-bottom: 20px;
}

.ctab-item {
    text-align: center;
    border: 2px solid #0066A5 !important;
    height: 33px;
    cursor: pointer;
}

.ctab-item.active {
    background-color: #0066A5;
    color: #ffffff;
}

.ctab-item-space {
    width: 11px;
}

#forMobile {
    width: 250px;
    min-width: 100px;
}

#forDesktop {
    width: 248px;
    min-width: 100px;
}

.bottom-pe {
    display: none
}

.for-img {
    width: 91%;
}

.forMDimg {
    margin-bottom: 30px;
}

/*******************************************************************************
r For responsive
*******************************************************************************/
/*小于*/
@media screen and (max-width: 1145px) {
    #telegram .tabnav-item {
        padding-left: 40px;
        padding-right: 60px;
    }

    .location-form-title {
        font-size: 18px;
    }
}

@media screen and (max-width: 992px) {
    #telegram .tabnav-item {
        /*padding: 0px 40px;*/
        padding-left: 20px;
        padding-right: 60px;
    }
}

@media screen and (max-width: 991px) {

    #ppboxHTML .overlay-inner,
    #ppboxEligibility .overlay-inner,
    #ppboxHTML2 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }

    #getstarted .broker-table .broker-td-name {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .phoneScreen {
        overflow-y: scroll;
    }
}

@media screen and (max-width: 680px) {
    #telegram .tabnav-item {
        top: 30%;
        padding-left: 5px;
        padding-right: 45px;
        font-size: 16px;
    }

    .jumbotron img {
        width: 30px;
        min-width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: 600px) {
    .col-xs-4 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .tabnav-row {
        margin-top: -30px;
    }

    #telegram .video_img img {
        max-width: 200px;
    }

    #tab3 img {
        padding-left: 0px;
    }

    #tab3 span {
        padding-right: 10px;
    }
}

@media screen and (max-width: 540px) {
    .tabnav-row {
        margin-top: -50px;
    }

    .contact_title {
        padding-bottom: 10px;
    }

    .contact_img td {
        padding-bottom: 20px;
    }

    .bottom-pc {
        display: none !important;
    }

    .bottom-pe {
        display: table !important;
    }
}

@media screen and (max-width: 450px) {
    .jumbotron p.lead {
        font-size: 14px;
    }

}

@media screen and (max-width: 523px) {
    #telegram .tabnav-item {
        top: 10%;
        /*        padding-left: 5px;
        padding-right: 25px;*/
    }
}

@media screen and (max-width: 500px) {
    .jumbotron h1 {
        margin-top: 0px;
        font-size: 30px;
    }

    .jumbotron {
        top: 30px;
    }
}

@media screen and (max-width: 465px) {
    .tabnav-row {
        margin-top: 0px;
    }
}

@media screen and (max-width: 475px) {
    #telegram .video_img img {
        max-width: 150px;
    }

    #telegram .androidDownload img,
    #telegram .appleDownload img {
        max-width: 49%;
    }

    #telegram .col-xs-1 img {
        max-width: 20px;
    }

    #tab3 img {
        max-width: 100%;
        max-height: 70%;
    }

    #tab3 .contact_facebook img {
        width: 21px;
    }

    #tab3 table span {
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    #tab3 .contact_facebook img {
        width: 17px;
    }
}

@media screen and (max-width: 374px) {
    #telegram .video_img img {
        max-width: 120px;
    }

    #tab3 img {
        padding-right: 5px;
    }

    #tab3 span {
        padding-right: 0px;
    }

    .ctab-item-space {
        width: 4px;
    }
}

/*大于*/
@media screen and (min-width: 992px) {

    /*    .welcometop {
        height: 425px;
    }*/
    .video-wrapper video {
        margin-top: -20px;
    }

    #telegram .tabnav-item {
        font-size: 25px;
    }

    .tabnav-row {
        margin-top: -100px;
    }
}

@media screen and (min-width: 1200px) {
    .welcometop {
        height: 600px;
    }

    .video-wrapper video {
        margin-top: -80px;
    }

    .tabnav-row {
        margin-top: -150px;
    }

    #mobile-3 {
        width: 91% !important;
    }
}

@media screen and (min-width: 1500px) {
    .video-wrapper video {
        margin-top: -160px;
    }
}

.col-xs-4 {
    padding-left: 5px;
    padding-right: 5px;
}
@media screen and (max-width: 750px) {
    .jumbotron .broker_img{
        font-size: 38px !important;
    }
}
@media screen and (max-width: 580px) {
    .jumbotron .broker_img{
        font-size: 30px !important;
    }
}
@media screen and (max-width: 490px) {
    .jumbotron .broker_img{
        font-size: 22px !important;
    }
}
@media screen and (max-width: 390px) {
    .jumbotron .broker_img{
        font-size: 20px !important;
    }
}