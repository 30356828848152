#toptradewarrant .tablist{
    position:relative;
    height:60px;
    margin-top:20px;
}
#toptradewarrant .tablist-left{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
}
#toptradewarrant .tablist-right{
    position: absolute;
    right:0;
    bottom:0;
    
}
#toptradewarrant .tablist-left table{
    table-layout:fixed;
}
#toptradewarrant .tablist-left table td{
    border-right:10px solid #fff;
}
#toptradewarrant .tablist-left table td:last-child{
    border-right:0;
}
#toptradewarrant .tablist-item{
    width:130px;
    height:55px;
    display:table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid #E0E0E0;
    background-color:#EEF0F2;
    cursor:pointer;
}
#toptradewarrant .tablist-item-txt{
    display:table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
}
#toptradewarrant .tablist .active .tablist-item{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}
#toptradewarrant .tablist .tablist-item:focus, #toptradewarrant .tablist .tablist-item:hover{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}

#toptradewarrant .lastupdatebox{
    /*display:inline-block;*/
    font-size: 12px;
    display: none;
}
#toptradewarrant .table td, #toptradewarrant .table th{
    font-size: 14px;
    text-align: center;
}
#toptradewarrant .table td{
    overflow-wrap: break-word; 
    overflow:hidden;
    text-overflow:ellipsis;
}
#toptradewarrant .table a, .table a:focus, .table a:hover, .table a:visited{
    color: #0675C2;
    text-decoration: none;
}
#toptradewarrant .table .type{
    text-transform: uppercase;
}
#toptradewarrant .checkbox-mqonly{
    text-align: right;
    font-size: 12px;
}
#toptradewarrant .checkbox-mqonly label{
    font-weight: normal;
    position: relative;
    top: -2px;
    margin-left: 5px;
}
#toptradewarrant .new28only{
    display:none;
}
#toptradewarrant .indicator{
    padding-left: 0px;
    padding-right: 0px;
}
#toptradewarrant .indicator-item-icon{
    display:inline-block;
    width:10px;
    height:14px;
    margin-right:6px;
}
#toptradewarrant .icon-hotdw{
    background:url('../../../../public/img/bullet/hot.fw.png') 0 0 no-repeat;
    width: 10px;
    height: 14px;
    margin-bottom:-1px;
}
#toptradewarrant .icon-liquidity{
    background:url('../../../../public/img/bullet/liquidity.fw.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    margin-bottom:-2px;
}
#toptradewarrant .icon-expiry{
    background:url('../../../../public/img/bullet/expiry.fw.png') 0 0 no-repeat;
    width: 12px;
    height: 13px;
    margin-bottom:-2px;
}
#toptradewarrant .icon-soldout{
    background:url('../../../../public/img/bullet/soldout.fw.png') 0 0 no-repeat;
    width: 12px;
    height: 12px;
    margin-bottom:-2px;
}
#toptradewarrant .icon-responsive{
    background:url('../../../../public/img/bullet/responsive.fw.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    margin-bottom:-2px;
}
#toptradewarrant .icon-hotlist{
    background:url('../../../../public/img/bullet/hotlist.fw.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    margin-bottom:-2px;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 991px){
    #toptradewarrant .indicator{
        text-align: center;
    }
}
@media screen and (max-width: 767px){
    #toptradewarrant .table td, #toptradewarrant .table th{
        font-size: 11px;
        padding-left:3px !important;
        padding-right:3px !important;
    }
    #toptradewarrant .tablist-left table{
        width:100%;
    }
    #toptradewarrant .tablist-item{
        width:100%;
    }
    #toptradewarrant .tablist-item-txt{
        font-size: 12px;
    }
    #toptradewarrant #topTabs ul li{
        width:32.5%;
    }
    #toptradewarrant .indicator{
        text-align: left;
    }
    #toptradewarrant .tablist-items{
        width: 50px!important;
    }
}
@media screen and (max-width: 330px){
    #toptradewarrant .table td, #toptradewarrant .table th{
        font-size: 10px;
        padding-left:2px !important;
        padding-right:2px !important;
    }
}

